import React from "react";

import dayjs from "dayjs";

export const getKey = (renduA) => {
  return parseInt(`${renduA.rendu_a_requete.requete_id}000${renduA.rendu_a_etape_id}`);
};

export const getNomLocataireRequete = (requete) => {
  return requete.requete_locataire === null
    ? `Requête - ${requete.requete_id}`
    : `${requete.requete_locataire.locataire_personne.personne_nom}
    ${
      requete.requete_locataire.locataire_personne.personne_prenom
        ? requete.requete_locataire.locataire_personne.personne_prenom
        : ""
    }
    (${requete.requete_id})`;
};

export const getAdresse = (adresse) => {
  return `${
    adresse.adresse_gmaps_adresse
      ? adresse.adresse_gmaps_adresse
      : adresse.adresse_numero + "" + adresse.adresse_voie + ", " + adresse.adresse_ville
  } 
  ${adresse.adresse_code_postal ? adresse.adresse_code_postal : ""}`;
};

export const getNomProprietaire = (proprietaire) => {
  return `${
    proprietaire.proprietaire_personne.personne_qualite
      ? proprietaire.proprietaire_personne.personne_qualite.qualite_libelle
      : ""
  } ${proprietaire.proprietaire_personne.personne_nom} ${
    proprietaire.proprietaire_personne.personne_prenom
      ? proprietaire.proprietaire_personne.personne_prenom
      : ""
  }`;
};

export const getInfoProprietaire = (proprietaire) => {
  return `${proprietaire.proprietaire_code} - ${
    proprietaire.proprietaire_personne.personne_qualite
      ? proprietaire.proprietaire_personne.personne_qualite.qualite_libelle
      : ""
  } ${proprietaire.proprietaire_personne.personne_nom} ${
    proprietaire.proprietaire_personne.personne_prenom
      ? proprietaire.proprietaire_personne.personne_prenom
      : ""
  } - ${proprietaire.proprietaire_personne.personne_adresse.adresse_gmaps_adresse}`;
};

export const getInfoImmeuble = (immeuble) => {
  return `${immeuble.immeuble_nom} 
        - ${immeuble.immeuble_adresse.adresse_gmaps_adresse}`;
};

export const getNomLocataire = (locataire) => {
  return `${locataire.locataire_personne.personne_nom} ${
    locataire.locataire_personne.personne_prenom ? locataire.locataire_personne.personne_prenom : ""
  }`;
};

export const getInfoLocataireSimple = (locataire) => {
  return `${locataire.locataire_code} - ${locataire.locataire_personne.personne_nom} 
  ${
    locataire.locataire_personne.personne_prenom ? locataire.locataire_personne.personne_prenom : ""
  } - ${locataire.locataire_personne.personne_email1} `;
};

export const getInfoLocataire = (locataire) => {
  return `${locataire.locataire_code} - ${
    locataire.locataire_personne.personne_qualite
      ? locataire.locataire_personne.personne_qualite.qualite_libelle
      : ""
  } ${locataire.locataire_personne.personne_nom} ${
    locataire.locataire_personne.personne_prenom ? locataire.locataire_personne.personne_prenom : ""
  } - ${locataire.locataire_personne.personne_email1}
  - ${locataire.locataire_personne.personne_adresse.adresse_gmaps_adresse}`;
};

export const getInfoAgent = (agent) => {
  return `${agent.agent_code} - ${
    agent.agent_personne.personne_qualite
      ? agent.agent_personne.personne_qualite.qualite_libelle
      : ""
  } ${agent.agent_personne.personne_nom} ${
    agent.agent_personne.personne_prenom ? agent.agent_personne.personne_prenom : ""
  } - ${agent.agent_personne.personne_email1}
  - ${agent.agent_personne.personne_adresse.adresse_gmaps_adresse}`;
};

export const getInfoAgentSimple = (agent) => {
  return `${agent.agent_personne.personne_nom} ${
    agent.agent_personne.personne_prenom ? agent.agent_personne.personne_prenom : ""
  }`;
};

export const getInfoLocation = (location) => {
  return `Location n°${location.location_id} du ${dayjs(location.location_date_debut).format(
    "DD/MM/YYYY"
  )} au ${dayjs(location.location_date_fin).format("DD/MM/YYYY")} (${
    location.location_requete?.requete_locataire?.locataire_personne.personne_nom
  })`;
};

export const getInfoRequete = (requete) => {
  return `Demande n°${requete.requete_id}${
    requete.requete_recherche
      ? ` - Du ${dayjs(requete.requete_recherche.recherche_date_debut).format("DD/MM/YYYY")} ${
          requete.requete_recherche.recherche_date_fin !== null
            ? `au ${dayjs(requete.requete_recherche.recherche_date_fin).format("DD/MM/YYYY")}`
            : ""
        }`
      : ""
  }`;
};

export const isLeaf = (etape) => {
  if (etape.level === 3) return true;
  return false;
};

export const generateCodeProprietaire = (proprietaires) => {
  let maxId = 0;
  proprietaires.forEach((item) => {
    if (item.proprietaire_id > maxId) maxId = item.proprietaire_id;
  });
  maxId = maxId + 1;
  let code = "";
  for (var i = 0; i < 6 - maxId.toString().length; i++) {
    code = code + "0";
  }
  return code + maxId;
};

export const sortTree = (etapes) => {
  etapes.sort((a, b) => {
    return a.etape_ordre - b.etape_ordre;
  });
};

export const sort = (etapes) => {
  etapes.sort((a, b) => {
    return a.etape_ordre - b.etape_ordre;
  });
  return etapes;
};

export const setChildren = (etapes, children, renduAs = null, bool = false, locations = null) => {
  etapes.forEach((item) => {
    if (children !== undefined)
      item.children = children.filter((i) => i.etape_etape_parente.etape_id === item.etape_id);

    item.key = item.etape_id;

    if (!renduAs) {
      item.title = etapeTitle(item);
      return;
    }

    const rendus = getRenduAs(item, renduAs, locations);
    item.children = item.children.concat(rendus);
    item.title = etapeTitleLength(item, rendus);
  });

  if (bool) return etapes.filter((item) => item.children.length > 0);
  return etapes;
};

export const getRenduAs = (etape, renduAs, locations = null) => {
  let rendus = [];
  renduAs.forEach((item) => {
    if (!item.rendu_a_actif) return;
    if (item.rendu_a_requete.requete_fin) return;
    if (item.rendu_a_etape_id !== etape.etape_id) return;

    item.key = getKey(item);
    item.title = getNomLocataireRequete(item.rendu_a_requete);

    rendus.push(item);
  });

  return rendus;
};

export const getTypeContrat = (lot) => {
  if (lot.lot_alur) return "AL - ";
  else if (lot.lot_long_terme) return "LT - ";
  else if (lot.lot_court_terme) return "CT - ";
};

export const etapeTitle = (item) => {
  return (
    <span style={{ color: item.etape_code_couleur.code_couleur_hex }}>{item.etape_libelle}</span>
  );
};

export const etapeTitleTree = (item) => {
  let libelle = "";
  let rules = null;
  let className = "";
  if ("children" in item) {
    libelle = item.libelle;
    rules = {
      backgroundColor: item.hex,
      padding: 4,
    };
    className = "tree-title";
  } else {
    libelle = item.libelle.length > 15 ? `${item.libelle.substring(0, 15)}...` : item.libelle;
    if (item.signal === true)
      rules = {
        color: "red",
      };
  }

  return (
    <span className={className} style={rules}>
      {libelle}
    </span>
  );
};

export const etapeTitleLength = (etape, renduAs) => {
  return (
    <span style={{ color: etape.etape_code_couleur.code_couleur_hex }}>
      {`${etape.titre} ( ${renduAs.length} ))))`}
    </span>
  );
};

export const formatDateToBDD = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const formatFrDateToBDD = (date) => {
  return dayjs(date, "DD/MM/YYYY").format("YYYY-MM-DD");
};

export const formatDateToAPP = (date) => {
  return dayjs(date, "DD/MM/YYYY").isValid() ? date : dayjs(date).format("DD/MM/YYYY");
};

export const formatDateToMidnight = (date) => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const filterRequetesArchivees = (requetes) => {
  return requetes ? requetes.filter((item) => item.requete_fin) : [];
};

export const formatArrayToStringDisplay = (array, equipement) => {
  if (!array || array === undefined) return "";
  let string = "";

  equipement
    ? array.forEach((i) => (string = string + ", " + i.equipement_libelle))
    : array.forEach((i) => (string = string + ", " + i.champ_perso_libelle));

  return string.replace(", ", "");
};

export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export const twoDigits = (number) => {
  return number.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
};

export const getTotalLocationLigne = (location_ligne) => {
  return location_ligne.reduce((total, ligne) => {
    // Si le signe de la rubrique est "+", on ajoute le montant,
    // Si c'est "-", on déduit le montant
    if (ligne.location_ligne_rubrique.rubrique_signe === "+") {
      return total + parseFloat(ligne.location_ligne_montant);
    } else if (ligne.location_ligne_rubrique.rubrique_signe === "-") {
      return total - parseFloat(ligne.location_ligne_montant);
    }
    return total;
  }, 0); // Le total commence à 0
};

export const getTotalQuittanceLigne = (quittance_ligne) => {
  return quittance_ligne.reduce((total, ligne) => {
    // Si le signe de la rubrique est "+", on ajoute le montant,
    // Si c'est "-", on déduit le montant
    if (ligne.quittance_ligne_rubrique.rubrique_signe === "+") {
      return total + parseFloat(ligne.quittance_ligne_montant);
    } else if (ligne.quittance_ligne_rubrique.rubrique_signe === "-") {
      return total - parseFloat(ligne.quittance_ligne_montant);
    }
    return total;
  }, 0); // Le total commence à 0
};

/* IBAN */
export const checkCleRib = (codeBanque, codeGuichet, numCompte) => {
  numCompte = numCompte.replace("a", "1");
  numCompte = numCompte.replace("j", "1");
  numCompte = numCompte.replace("b", "2");
  numCompte = numCompte.replace("k", "2");
  numCompte = numCompte.replace("s", "2");
  numCompte = numCompte.replace("c", "3");
  numCompte = numCompte.replace("l", "3");
  numCompte = numCompte.replace("t", "3");
  numCompte = numCompte.replace("d", "4");
  numCompte = numCompte.replace("m", "4");
  numCompte = numCompte.replace("u", "4");
  numCompte = numCompte.replace("e", "5");
  numCompte = numCompte.replace("n", "5");
  numCompte = numCompte.replace("v", "5");
  numCompte = numCompte.replace("f", "6");
  numCompte = numCompte.replace("o", "6");
  numCompte = numCompte.replace("w", "6");
  numCompte = numCompte.replace("g", "7");
  numCompte = numCompte.replace("p", "7");
  numCompte = numCompte.replace("x", "7");
  numCompte = numCompte.replace("h", "8");
  numCompte = numCompte.replace("q", "8");
  numCompte = numCompte.replace("y", "8");
  numCompte = numCompte.replace("i", "9");
  numCompte = numCompte.replace("r", "9");
  numCompte = numCompte.replace("z", "9");
  let cle =
    97 - ((parseInt(codeBanque) * 89 + parseInt(codeGuichet) * 15 + parseInt(numCompte) * 3) % 97);
  if (cle.length === 1) cle = "0" + cle;
  return cle;
};

/* Based on https://fr.wikipedia.org/wiki/Basic_Bank_Account_Number#Conversion_du_RIB_en_IBAN_en_JavaScript*/
export const checkIban = (codeBanque, codeGuichet, numCompte, cleRib) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let tmp = "";
  tmp = "" + String(codeBanque) + String(codeGuichet) + String(numCompte) + String(cleRib) + "FR00";
  tmp = tmp.toUpperCase();
  tmp = tmp.split("");

  for (let i = 0; i < tmp.length; i++) {
    if (alphabet.indexOf(tmp[i]) !== -1) {
      tmp[i] = String(alphabet.indexOf(tmp[i]) + 10);
    }
  }

  tmp = tmp.join("");
  const moduloRemainder = parseInt(tmp % 97, 10);
  let ibanKey = (98 - moduloRemainder).toString();
  if (ibanKey.length === 1) {
    ibanKey = "0" + ibanKey;
  }
  return "FR" + ibanKey + codeBanque + codeGuichet + numCompte + cleRib;
};

// validate IBAN number based on https://stackoverflow.com/questions/21928083/iban-validation-check
export const validateIBAN = (iban) => {
  var iban_regex =
    /^(?:((?:IT|SM)\d{2}[A-Z]{1}\d{22})|(NL\d{2}[A-Z]{4}\d{10})|(LV\d{2}[A-Z]{4}\d{13})|((?:BG|GB|IE)\d{2}[A-Z]{4}\d{14})|(GI\d{2}[A-Z]{4}\d{15})|(RO\d{2}[A-Z]{4}\d{16})|(MT\d{2}[A-Z]{4}\d{23})|(NO\d{13})|((?:DK|FI)\d{16})|((?:SI)\d{17})|((?:AT|EE|LU|LT)\d{18})|((?:HR|LI|CH)\d{19})|((?:DE|VA)\d{20})|((?:AD|CZ|ES|MD|SK|SE)\d{22})|(PT\d{23})|((?:IS)\d{24})|((?:BE)\d{14})|((?:FR|MC|GR)\d{25})|((?:PL|HU|CY)\d{26}))$/;
  if (iban !== null && iban !== undefined) iban = iban.split(" ").join("");
  return iban_regex.test(iban);
};

// /* global BigInt */
// const validate = (iban) => {
//   const [, tail, head] = iban.split(/(^\w{4})(\w+)$/);
//   const rearrange = `${tail}${head}`;
//   const replace = rearrange
//     .split("")
//     .map((c) => (/[a-z]/i.test(c) ? c.toLowerCase().charCodeAt(0) - 55 : parseInt(c, 10)))
//     .join("");
//   console.log(replace, "replace");
//   console.log(BigInt(replace) % 97n);
//   return BigInt(replace) % 97n === 1n;
// };
