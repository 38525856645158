import React, { Component } from "react";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { toast } from "react-toastify";

import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";

import { Row, Col, Form, Input, Checkbox, Select, Button, Upload, Modal, Divider } from "antd";

import { formatArrayToStringDisplay, formatDateToAPP } from "../../utils/tools";

import dayjs from "dayjs";
import { HttpMethod, initFetch } from "../../utils/fetcher";

const { Option, OptGroup } = Select;

class FormMailFields extends Component {
  state = {
    templates: [],
    faqs: [],
    piecesJointes: [],
    dest: "",
    copie: "",
    objet: "",
    destinataire: "",
    templateSelected: null,
    modalJoindreVisible: false,
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    if (props.withForm) {
      const templates =
        this.state.templates.length === 0
          ? props.parametre && props.parametre.parametre_parcours_client
            ? this.getTemplatesParcoursClient(props)
            : this.getTemplates(props)
          : this.state.templates;
      const faqs = this.getFaqs(props);
      this.setState({
        templates,
        faqs,
        piecesJointes: props.piecesJointesProps,
        editorState: props.editorState,
      });
    } else
      this.setState({
        dest: props.defaultEmail ? props.defaultEmail : "",
        piecesJointes: props.piecesJointesProps,
        editorState: props.editorState,
      });
  };

  getTemplatesParcoursClient = (props) => {
    const requeteId = props.requete && props.requete.requete_id;
    const renduAs = props.renduAs;
    const etapesTemplates = props.etapesTemplates;

    const renduA = renduAs.find(
      (item) => item.rendu_a_requete.requete_id === requeteId && item.rendu_a_actif
    );

    const templates = [];
    if (renduA === undefined) return templates;
    const eTemplates = etapesTemplates.filter(
      (item) => item.etape_template_etape.etape_id === renduA.rendu_a_etape_id
    );

    eTemplates.forEach((item) => {
      if (item.etape_template_template.template_type === 1)
        templates.push(item.etape_template_template);
    });

    return templates;
  };

  getTemplates = (props) => {
    const templates = props.templates.filter((item) => item.template_type === 1);
    return templates;
  };

  getFaqs = (props) => {
    const templates = props.templates;
    const faqs = templates.filter((item) => item.template_type === 2);
    return faqs;
  };

  create = () => {
    const arrayDestinataire = [];
    const arrayCopie = [];

    // conversion en HTML de ce qui est écrit dans l'éditeur
    const message = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

    // La méthode split() utilisée ensuite retourne une ligne vide si l'input se termine par un ";"
    // Vérification puis suppression de ce ";" si nécéssaire
    let email_destinataire = this.state.dest;
    if (email_destinataire.endsWith(";")) {
      email_destinataire = email_destinataire.substring(0, email_destinataire.lastIndexOf(";"));
    }

    // récupération de l'input et création d'un tableau avec les emails
    // le système de mail de Laravel aura beosin d'un tableau avec les attributs email/name
    const destinataire = email_destinataire.split(";");
    destinataire.forEach((element) => {
      arrayDestinataire.push({
        email: element,
        name: element,
      });
    });

    // La méthode split() utilisée ensuite retourne une ligne vide si l'input se termine par un ";"
    // Vérification puis suppression de ce ";" si nécéssaire
    let email_copie = this.state.copie;
    if (email_copie) {
      if (email_copie.endsWith(";")) {
        email_copie = email_copie.substring(0, email_copie.lastIndexOf(";"));
      }

      // récupération de l'input et création d'un tableau avec les emails
      // le système de mail de Laravel aura beosin d'un tableau avec les attributs email/name
      const copie = email_copie.split(";");
      copie.forEach((element) => {
        arrayCopie.push({
          email: element,
          name: element,
        });
      });
    }

    let email_pieces_jointes = [];
    this.state.piecesJointes.forEach((item) => {
      const jsonData = {
        nom: item.name,
        // url: `Lots/${this.props.lot.lot_id}/`,
        ref: item.ref,
        url: item.url,
      };
      email_pieces_jointes.push(jsonData);
    });

    // email_destinataire et email_copie seront insérés en base
    // email_destinataire_array et email_copie_array seront utilisés pour les envois
    const jsonData = {
      email_objet: this.state.objet,
      email_message: message,
      email_destinataire: email_destinataire,
      email_destinataire_array: arrayDestinataire,
      email_copie: email_copie,
      email_copie_array: arrayCopie,
      email_pieces_jointes: email_pieces_jointes,
      email_utilisateur_id: this.props.utilisateur.utilisateur_id,
      email_requete_id: null,
      email_lot_id: this.props.lot.lot_id,
      parametre_smtp: this.props.parametre.parametre_smtp,
      parametre_port: this.props.parametre.parametre_port,
      parametre_email: this.props.parametre.parametre_email,
      parametre_mdp: this.props.parametre.parametre_mdp,
      parametre_copie_agence: this.props.parametre.parametre_copie_agence,
      parametre_email_copie: this.props.parametre.parametre_email_copie,
    };

    toast.info("Envoi en cours...", {
      containerId: "A",
    });

    // Envoi du mail puis sauvegarde de celui-ci
    this.props
      .saveEmail(jsonData)
      .then((res) => {
        this.setState({ piecesJointes: [] });
        toast.success("Email envoyé !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de l'envoi !", {
          containerId: "A",
        });
      });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    if (this.props.withForm) this.props.onEditorStateChange(editorState);
  };

  openModalJoindre = () => {
    this.setState({ modalJoindreVisible: true });
  };

  closeModalJoindre = () => {
    this.setState({ modalJoindreVisible: false });
  };

  onKeyUpDestinataire = (e) => {
    this.setState({ dest: e.target.value });
  };

  onKeyUpCopie = (e) => {
    this.setState({ copie: e.target.value });
  };

  onKeyUpObjet = (e) => {
    this.setState({ objet: e.target.value });
  };

  compare = (a, comparator, b) => {
    switch (comparator) {
      case "<":
      case "< ":
        return a < b;
      case "<=":
        return a <= b;
      case ">":
      case "> ":
        return a > b;
      case ">=":
        return a >= b;
      default:
        break;
    }
  };

  getNuites = (lot, dateDebut, dateFin) => {
    const tarifLignes = [];

    const dates = this.getAllDates(dateDebut, dateFin);

    lot &&
      dates.forEach((date) => {
        const special = lot.lot_nuites?.filter((nuite) =>
          dayjs(date, "DD/MM/YYYY").isBetween(
            dayjs(nuite.nuite_date_debut, "YYYY-MM-DD"),
            dayjs(nuite.nuite_date_fin, "YYYY-MM-DD"),
            null,
            "[]"
          )
        );

        if (special?.length > 0) {
          special.forEach((nuite) => {
            const foundIndex = tarifLignes.findIndex(
              (x) => x.location_ligne_rubrique_id === nuite.nuite_rubrique.rubrique_id
            );

            if (foundIndex !== -1 && nuite.nuite_rubrique.rubrique_montant_fixe === false)
              tarifLignes[foundIndex].location_ligne_montant += parseInt(nuite.nuite_montant);
            else if (foundIndex === -1) {
              const tarifLigne = {
                // location_ligne_location_id: this.props.location.location_id,
                location_ligne_pourcentage_acompte: 0,
                location_ligne_montant: parseFloat(nuite.nuite_montant),
                location_ligne_rubrique_id: nuite.nuite_rubrique.rubrique_id,
                location_ligne_permanente: false,
              };
              tarifLignes.push(tarifLigne);
            }
          });
        } else {
          lot.lot_prix_base
            ?.filter((prixBase) => !prixBase.prix_base_proprietaire)
            .forEach((prixBase) => {
              const foundIndex = tarifLignes.findIndex(
                (x) => x.location_ligne_rubrique_id === prixBase.prix_base_rubrique.rubrique_id
              );
              if (foundIndex !== -1 && prixBase.prix_base_rubrique.rubrique_montant_fixe === false)
                tarifLignes[foundIndex].location_ligne_montant += parseInt(
                  prixBase.prix_base_montant
                );
              else if (foundIndex === -1) {
                const tarifLigne = {
                  // location_ligne_location_id: this.props.location.location_id,
                  location_ligne_pourcentage_acompte: 0,
                  location_ligne_montant: parseFloat(prixBase.prix_base_montant),
                  location_ligne_rubrique_id: prixBase.prix_base_rubrique.rubrique_id,
                  location_ligne_permanente: false,
                };
                tarifLignes.push(tarifLigne);
              }
            });
        }
      });
    return tarifLignes;
  };

  getAllDates = (debut, fin) => {
    const dates = [];
    let start = dayjs(debut, "YYYY-MM-DD");
    while (start.isBefore(dayjs(fin, "YYYY-MM-DD"))) {
      dates.push(start);
      start = start.add(1, "day");
    }
    return dates;
  };

  getMapObjLot = (lotSimple, langueId, dateDebut, dateFin) => {
    const lotFound = this.props.lots.find((lot) => lot.lot_id === lotSimple.lot_id);
    const lot = lotFound ? lotFound : lotSimple;

    const lignes = this.getNuites(lot, dateDebut, dateFin);
    const total = lignes.reduce((a, b) => a + parseFloat(b.location_ligne_montant), 0);

    const description = lot.lot_descriptions.find(
      (description) => description && description.description_langue.langue_id === langueId
    );

    let champ_perso_3415 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3415
    );

    let champ_perso_3416 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3416
    );

    let champ_perso_3417 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3417
    );

    let champ_perso_3418 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3418
    );

    let champ_perso_3434 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3434
    );

    let champ_perso_3438 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3438
    );

    let champ_perso_3719 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3719
    );

    let champ_perso_3720 = lot.lot_champs_persos?.find(
      (element) => element.lot_champ_perso_champ_perso.champ_perso_id === 3720
    );

    let blocMatrice = "";
    lot.lot_matrice?.matrice_ligne.forEach((ligne) => {
      blocMatrice += `${ligne.matrice_ligne_rubrique.rubrique_libelle} : ${parseFloat(
        ligne.matrice_ligne_montant_mois
      ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
    });

    return {
      "#lot_reference": lot.lot_reference,
      "#lot_code_porte": lot.lot_code_porte ? lot.lot_code_porte : "",
      // "#lot_type": lot.lot_type_lot.type_lot_libelle,
      "#lot_surface": lot.lot_surface ? lot.lot_surface : "",
      "#lot_etage": lot.lot_etage ? lot.lot_etage : "",
      "#lot_couchages": lot.lot_couchage ? lot.lot_couchage : "",
      "#lot_url": description ? description.description_lot_url : "",
      "#lot_adresse": lot.lot_adresse.adresse_gmaps_adresse,
      "#lot_numero": lot.lot_adresse.adresse_numero,
      "#lot_voie": lot.lot_adresse.adresse_voie,
      "#lot_cp": lot.lot_adresse.adresse_code_postal,
      "#lot_ville": lot.lot_adresse.adresse_ville,
      "#lot_departement": lot.lot_adresse.adresse_departement
        ? lot.lot_adresse.adresse_departement
        : "",
      "#lot_pays": lot.lot_adresse.adresse_pays ? lot.lot_adresse.adresse_pays : "",
      "#lot_situation": lot.lot_adresse.adresse_situation,
      "#lot_equipements": formatArrayToStringDisplay(lot.lot_equipements, true),
      "#lot_quartiers": formatArrayToStringDisplay(lot.lot_quartiers, false),
      "#lot_metros": formatArrayToStringDisplay(lot.lot_metros, false),
      "#lot_depot_garantie": parseFloat(lot.lot_depot_garantie),
      "#lot_total_prix": parseFloat(total).toFixed(2),
      "#champ_perso_3415": champ_perso_3415 ? champ_perso_3415.lot_champ_perso_valeur : "",
      "#champ_perso_3416": champ_perso_3416 ? champ_perso_3416.lot_champ_perso_valeur : "",
      "#champ_perso_3417": champ_perso_3417 ? champ_perso_3417.lot_champ_perso_valeur : "",
      "#champ_perso_3418": champ_perso_3418 ? champ_perso_3418.lot_champ_perso_valeur : "",
      "#champ_perso_3434": champ_perso_3434 ? champ_perso_3434.lot_champ_perso_valeur : "",
      "#champ_perso_3438": champ_perso_3438 ? champ_perso_3438.lot_champ_perso_valeur : "",
      "#champ_perso_3719": champ_perso_3719 ? champ_perso_3719.lot_champ_perso_valeur : "",
      "#champ_perso_3720": champ_perso_3720 ? champ_perso_3720.lot_champ_perso_valeur : "",
      "#lot_bloc_matrice": blocMatrice,
    };
  };

  getMapObjLoc = (locataire) => {
    return {
      "#locataire_qualite":
        locataire.locataire_personne.personne_qualite &&
        locataire.locataire_personne.personne_qualite.qualite_libelle_long,
      "#locataire_nom": locataire.locataire_personne.personne_nom,
      "#locataire_prenom": locataire.locataire_personne.personne_prenom
        ? locataire.locataire_personne.personne_prenom
        : "",
      "#locataire_adresse": locataire.locataire_personne.personne_adresse.adresse_ville,
      "#locataire_pays": locataire.locataire_personne.personne_adresse.adresse_pays,
    };
  };

  getMapObjPro = (proprietaire) => {
    return {
      "#proprietaire_qualite":
        proprietaire.proprietaire_personne.personne_qualite &&
        proprietaire.proprietaire_personne.personne_qualite.qualite_libelle_long,
      "#proprietaire_nom": proprietaire && proprietaire.proprietaire_personne.personne_nom,
      "#proprietaire_prenom": proprietaire.proprietaire_personne.personne_prenom
        ? proprietaire.proprietaire_personne.personne_prenom
        : "",
      "#proprietaire_adresse": proprietaire.proprietaire_personne.personne_adresse.adresse_ville,
    };
  };

  onSelectTemplate = (templateSelected) => {
    const {
      requete,
      templatesLangues,
      requetesLots,
      location,
      locationQuittances,
      locationReglements,
    } = this.props;

    const langueId =
      requete.requete_locataire && requete.requete_locataire.locataire_personne.personne_langue
        ? requete.requete_locataire.locataire_personne.personne_langue.langue_id
        : null;

    const templateLangue = langueId
      ? templatesLangues.find(
          (item) =>
            item.template_langue_template.template_id === templateSelected &&
            item.template_langue_langue.langue_id === langueId
        )
      : templatesLangues.find(
          (item) => item.template_langue_template.template_id === templateSelected
        );

    let text =
      templateLangue && templateLangue.template_langue_text
        ? templateLangue.template_langue_text
        : "";

    let objet =
      templateLangue && templateLangue.template_langue_titre
        ? templateLangue.template_langue_titre
        : "";

    this.props.setObjetMail(objet);

    // Boucler sur les lots liés à la requête si le texte contient une liste sur laquelle boucler
    if (requete && requete !== undefined) {
      let blocBoucleSelection = "";
      let blocOriginalSelection = "";

      let blocBouclePanier = "";
      let blocOriginalPanier = "";

      if (text.includes("#debut_selection") && text.includes("#fin_selection")) {
        let part = text.substring(
          text.lastIndexOf("#debut_selection") + 16,
          text.lastIndexOf("#fin_selection")
        );

        blocOriginalSelection = part;

        let requeteLotsSelection = requetesLots.filter(
          (item) =>
            item.requete_lot_requete.requete_id === requete.requete_id &&
            item.requete_lot_type === 1
        );

        requeteLotsSelection &&
          requeteLotsSelection.forEach((i) => {
            const lot = i.requete_lot_lot;
            // const recherche = requete.requete_recherche;

            part = this.fusionChamps(
              this.getMapObjLot(lot, langueId, i.requete_lot_date_debut, i.requete_lot_date_fin),
              part
            );
            const proprietaire = lot.lot_proprietaire;
            part = this.fusionChamps(this.getMapObjPro(proprietaire), part);

            blocBoucleSelection = blocBoucleSelection + part;
            part = blocOriginalSelection;
          });
      }

      if (text.includes("#debut_panier") && text.includes("#fin_panier")) {
        let part = text.substring(
          text.lastIndexOf("#debut_panier") + 13,
          text.lastIndexOf("#fin_panier")
        );

        blocOriginalPanier = part;

        let requeteLotsPanier = requetesLots.filter(
          (item) =>
            item.requete_lot_requete.requete_id === requete.requete_id &&
            item.requete_lot_type === 2
        );

        requeteLotsPanier &&
          requeteLotsPanier.forEach((i) => {
            const lot = i.requete_lot_lot;
            // const recherche = requete.requete_recherche;

            part = this.fusionChamps(
              this.getMapObjLot(lot, langueId, i.requete_lot_date_debut, i.requete_lot_date_fin),
              part
            );
            const proprietaire = lot.lot_proprietaire;
            part = this.fusionChamps(this.getMapObjPro(proprietaire), part);

            blocBouclePanier = blocBouclePanier + part;
            part = blocOriginalPanier;
          });
      }

      text = text.replace(blocOriginalSelection, blocBoucleSelection);
      text = text.replaceAll("#debut_selection", "").replaceAll("#fin_selection", "");

      text = text.replace(blocOriginalPanier, blocBouclePanier);
      text = text.replaceAll("#debut_panier", "").replaceAll("#fin_panier", "");
    }

    const locataire = requete.requete_locataire;
    text = this.fusionChamps(this.getMapObjLoc(locataire), text);

    const requeteLot = requetesLots.find(
      (rl) =>
        location &&
        rl.requete_lot_lot.lot_id === location.location_lot.lot_id &&
        rl.requete_lot_requete.requete_id === requete.requete_id
    );

    if (location && location !== undefined) {
      const lot = location.location_lot;
      text = this.fusionChamps(
        this.getMapObjLot(lot, langueId, location.location_date_debut, location.location_date_fin),
        text
      );

      const proprietaire = lot.lot_proprietaire;
      text = this.fusionChamps(this.getMapObjPro(proprietaire), text);

      let quittancesLignes = [];
      let datePaiementAttendu = null;
      if (locationQuittances && locationQuittances.length > 0) {
        const lastQuittance = locationQuittances.slice(-1);
        datePaiementAttendu = lastQuittance[0].quittance_date_paiement;
        lastQuittance[0].quittance_ligne.forEach((ligne) => {
          quittancesLignes.push(ligne);
        });
      }

      let reglementsLignes = [];
      if (locationReglements && locationReglements.length > 0) {
        locationReglements.forEach((reglement) => {
          reglement.reglement_ligne.forEach((ligne) => {
            reglementsLignes.push(ligne);
          });
        });
      }

      let tarif = 0;
      let total = 0;
      let regle = 0;
      let solde = 0;
      let fraisDossier = 0;
      let taxeSejour = 0;
      let totalHorsFraisDossier = 0;
      let totalHorsTaxeEtFraisDossier = 0;

      tarif = location.location_ligne.reduce((a, b) => a + parseFloat(b.location_ligne_montant), 0);

      let blocQuittance = "";
      quittancesLignes.forEach((ligne) => {
        total += parseFloat(ligne.quittance_ligne_montant);
        if (ligne.quittance_ligne_rubrique.rubrique_code === "07") {
          fraisDossier = parseFloat(ligne.quittance_ligne_montant);
        }
        if (ligne.quittance_ligne_rubrique.rubrique_taxe_de_sejour === 1) {
          taxeSejour = parseFloat(ligne.quittance_ligne_montant);
        }
        blocQuittance += `${ligne.quittance_ligne_rubrique.rubrique_libelle} : ${parseFloat(
          ligne.quittance_ligne_montant
        ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
      });

      let blocTarif = "";
      location.location_ligne.forEach((ligne) => {
        blocTarif += `${ligne.location_ligne_rubrique.rubrique_libelle} : ${parseFloat(
          ligne.location_ligne_montant
        ).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}<br />`;
      });

      const montantNet = quittancesLignes
        ? quittancesLignes
            .filter((ligne) => ligne.quittance_ligne_rubrique.rubrique_type === "P")
            .reduce((a, b) => {
              let sum = 0;
              b.quittance_ligne_rubrique.rubrique_signe === "-"
                ? (sum = a - parseFloat(b.quittance_ligne_montant))
                : (sum = a + parseFloat(b.quittance_ligne_montant));
              return sum;
            }, 0)
        : 0;

      reglementsLignes.forEach((ligne) => {
        regle += parseFloat(ligne.reglement_ligne_montant);
      });

      solde = total - regle;
      totalHorsFraisDossier = total - fraisDossier;
      totalHorsTaxeEtFraisDossier = total - taxeSejour - fraisDossier;

      let mapObjLaLoc = {
        "#location_id": location.location_id,
        "#location_lot_reference": location.location_lot.lot_reference,
        "#location_date_debut": formatDateToAPP(location.location_date_debut),
        "#location_date_fin": formatDateToAPP(location.location_date_fin),
        "#location_nombre_adultes": location.location_nb_adultes,
        "#location_nombre_enfants": location.location_nb_enfants,
        "#location_nombre_bebes": location.location_nb_bebes,
        "#location_nombre_nuits": dayjs(location.location_date_fin).diff(
          dayjs(location.location_date_debut),
          "days"
        ),
        "#location_desiderata": location.location_desiderata,
        "#location_frais_dossier": parseFloat(fraisDossier).toFixed(2),
        "#location_taxe_sejour": parseFloat(taxeSejour).toFixed(2),
        "#location_total_hors_frais_dossier": parseFloat(totalHorsFraisDossier).toFixed(2),
        "#location_total_hors_taxe_et_frais_dossier": parseFloat(
          totalHorsTaxeEtFraisDossier
        ).toFixed(2),
        "#location_total_prix": parseFloat(total).toFixed(2),
        "#location_deja_regle": parseFloat(regle).toFixed(2),
        "#location_solde": parseFloat(solde).toFixed(2),
        "#location_tarif": parseFloat(tarif).toFixed(2),
        "#location_montant_net": parseFloat(montantNet).toFixed(2),
        "#location_date_acompte": formatDateToAPP(
          dayjs(location.location_date_debut).subtract(6, "month")
        ),
        "#location_date_solde": formatDateToAPP(
          dayjs(location.location_date_debut).subtract(30, "days")
        ),
        "#location_bloc_quittance": blocQuittance,
        "#location_bloc_tarif": blocTarif,
        "#date_paiement_attendu": datePaiementAttendu
          ? dayjs(datePaiementAttendu).format("DD/MM/YYYY")
          : "",
        "#location_provenance": "",
        "#location_groupe": "",
        "#location_montant_plateforme": parseFloat(location.location_montant_plateforme).toFixed(2),
      };
      text = this.fusionChamps(mapObjLaLoc, text);

      const mapObjRub = {};
      const mapObjLocationRub = {};
      const mapObjQuittanceRub = {};
      this.props.rubriques.forEach((rubrique) => {
        mapObjRub[`#rubrique_libelle_${rubrique.rubrique_id}`] = rubrique.rubrique_libelle;
        mapObjLocationRub[`#location_rubrique_${rubrique.rubrique_id}`] =
          this.getLocationLigneMontant(location.location_ligne, rubrique.rubrique_id);
        mapObjQuittanceRub[`#quittance_rubrique_${rubrique.rubrique_id}`] =
          this.getQuittanceLigneMontant(quittancesLignes, rubrique.rubrique_id);
      });

      text = this.fusionChamps(mapObjRub, text);
      text = this.fusionChamps(mapObjLocationRub, text);
      text = this.fusionChamps(mapObjQuittanceRub, text);
    } else {
      if (requeteLot && requeteLot !== undefined) {
        const lot = requeteLot.requete_lot_lot;
        text = this.fusionChamps(
          this.getMapObjLot(
            lot,
            langueId,
            requeteLot.requete_lot_date_debut,
            requeteLot.requete_lot_date_fin
          ),
          text
        );

        const proprietaire = lot.lot_proprietaire;
        text = this.fusionChamps(this.getMapObjPro(proprietaire), text);
      }
    }

    const recherche = requete.requete_recherche;
    if (recherche && recherche !== undefined) {
      let mapObjRec = {
        "#recherche_date_debut": formatDateToAPP(recherche.recherche_date_debut),
        "#recherche_date_fin": formatDateToAPP(recherche.recherche_date_fin),
      };
      text = this.fusionChamps(mapObjRec, text);
    }

    text += this.props.parametre.parametre_signature
      ? this.props.parametre.parametre_signature
      : "";

    const content = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(text, (nodeName, node) => {
          if (nodeName === "img" && node instanceof HTMLImageElement) {
            if (node.parentElement instanceof HTMLDivElement) {
              // for image with div element as a parent
              const entityConfig = {};
              const parentElement = node.parentElement;
              if (parentElement.style.float) {
                // check float style property
                entityConfig.alignment = parentElement.style.float;
              } else if (parentElement.style.textAlign) {
                // check float style property
                entityConfig.alignment = parentElement.style.textAlign;
              }

              entityConfig.src = node.getAttribute
                ? node.getAttribute("src") || node.src
                : node.src;
              entityConfig.alt = node.alt;
              entityConfig.height = node.style.height;
              entityConfig.width = node.style.width;
              return {
                type: "IMAGE",
                mutability: "MUTABLE",
                data: entityConfig,
              };
            }
          }
        })
      )
    );

    this.setState({
      editorState: content,
      objet,
      templateSelected,
    });
    if (this.props.withForm) this.props.onEditorStateChange(content);
  };

  getQuittanceLigneMontant = (quittancesLignes, id) => {
    const ligne = quittancesLignes.find(
      (ligne) => ligne.quittance_ligne_rubrique.rubrique_id === id
    );
    return ligne ? parseFloat(ligne.quittance_ligne_montant).toFixed(2) : parseFloat(0).toFixed(2);
  };

  getLocationLigneMontant = (locationLignes, id) => {
    const ligne = locationLignes.find((ligne) => ligne.location_ligne_rubrique.rubrique_id === id);
    return ligne ? parseFloat(ligne.location_ligne_montant).toFixed(2) : parseFloat(0).toFixed(2);
  };

  onSelectFaq = (template) => {
    const { editorState } = this.state;
    const { requete, templatesLangues } = this.props;

    const langueId =
      requete.requete_locataire && requete.requete_locataire.locataire_personne.personne_langue
        ? requete.requete_locataire.locataire_personne.personne_langue.langue_id
        : 1;

    const tL = templatesLangues.find(
      (item) =>
        item.template_langue_langue.langue_id === langueId &&
        item.template_langue_template.template_id === template
    );

    const content = this.insertText(tL ? tL.template_langue_text : "", editorState);

    this.setState({
      editorState: content,
    });
    if (this.props.withForm) this.props.onEditorStateChange(content);
  };

  insertText = (text, editorState) => {
    let { contentBlocks, entityMap } = htmlToDraft(text);
    let contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );

    return EditorState.push(editorState, contentState, "insert-fragment");
  };

  fusionChamps = (obj, text) => {
    let request = new RegExp(Object.keys(obj).join("|"), "gi");
    return text.replace(request, function (matched) {
      return obj[matched.toLowerCase()];
    });
  };

  onChangeAll = (e) => {
    this.setState({
      templates: e.target.checked
        ? this.props.templates
        : this.props.parametre && this.props.parametre.parametre_parcours_client
        ? this.getTemplatesParcoursClient(this.props)
        : this.getTemplates(this.props),
    });
  };

  render() {
    const { templates, faqs, editorState, piecesJointes, modalJoindreVisible } = this.state;

    const { home, withForm, lot, requete, isArchive, defaultEmail } = this.props;

    const props = {
      accept: ".pdf",
      customRequest: (componentsData) => {
        let formData = new FormData();
        formData.append("file", componentsData.file);
        formData.append("nom", componentsData.file.name);
        withForm
          ? formData.append("url", `Requetes/${requete.requete_id}/`)
          : formData.append("url", `Lots/${lot.lot_id}/`);

        const { url, params } = initFetch(`uploadPieceJointe`, HttpMethod.POST, {
          Authorization: true,
          formData: true,
          pdf: false,
        });
        fetch(url, { ...params, body: formData })
          .then((res) => res.json())
          .then((res) => componentsData.onSuccess())
          .catch((error) => {
            console.log("Error : " + error);
            componentsData.onError("Error upload image");
          });
      },
      multiple: true,
      name: "file",
      onChange: (file) => {
        if (file.file.status === "done") {
          this.setState({
            piecesJointes: file.fileList,
          });
          if (this.props.withForm) this.props.syncPiecesJointes(file.fileList);
        }
      },
      defaultFileList: this.state.piecesJointes,
      showUploadList: {
        showRemoveIcon: true,
        removeIcon: <DeleteOutlined />,
      },
    };

    const envoyerButton = (
      <Button
        disabled={isArchive}
        type="primary"
        htmlType={withForm ? "submit" : "button"}
        onClick={!withForm ? this.create : null}
      >
        Envoyer
      </Button>
    );

    const joindreButton = (
      <Button disabled={isArchive} onClick={this.openModalJoindre}>
        <UploadOutlined /> Joindre fichier(s)
      </Button>
    );

    const nombrePiecesJointes = piecesJointes.filter((item) => {
      return item.status !== "removed";
    }).length;

    return (
      <div>
        <Modal
          open={modalJoindreVisible}
          onCancel={this.closeModalJoindre}
          title="Pièces jointes"
          footer={null}
        >
          <Row>
            <Col md={24}>
              <Upload {...props}>
                <Button>
                  <UploadOutlined /> Sélectionner fichier(s)
                </Button>
              </Upload>
            </Col>
          </Row>
        </Modal>

        <Col md={24}>
          <Row>
            <Col md={8}>
              {withForm && (
                <div>
                  <Form.Item>
                    <Row>
                      <Col md={20}>
                        <Form.Item name="locataire_email_1">
                          <Input
                            disabled={isArchive}
                            placeholder="E-mail 1"
                            onKeyUp={this.props.onKeyUpEmail1}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Form.Item name="locataire_checkbox_1" valuePropName="checked">
                          <Checkbox
                            disabled={isArchive}
                            onChange={this.props.onChangeEmail1}
                          ></Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row>
                      <Col md={20}>
                        <Form.Item name="locataire_email_2">
                          <Input
                            disabled={isArchive}
                            placeholder="E-mail 2"
                            onKeyUp={this.props.onKeyUpEmail2}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={4}>
                        <Checkbox
                          disabled={isArchive}
                          onChange={this.props.onChangeEmail2}
                        ></Checkbox>
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              )}
            </Col>
            <Col md={8}>
              {withForm && (
                <div>
                  <Row>
                    <Form.Item name="email_template">
                      <Select
                        autoComplete="new-password"
                        disabled={isArchive}
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        placeholder="Choix template"
                        style={{ width: 250, display: "block" }}
                        onSelect={this.onSelectTemplate}
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ padding: "8px", cursor: "pointer" }}>
                              <Checkbox onChange={this.onChangeAll}>Tous les templates</Checkbox>
                            </div>
                          </div>
                        )}
                      >
                        <OptGroup label="Template">
                          {templates &&
                            templates
                              .filter((item) => item.template_type === 1)
                              .sort((a, b) => a.template_libelle.localeCompare(b.template_libelle))
                              .map((item, i) => {
                                return (
                                  <Option
                                    key={i}
                                    value={item.template_id}
                                    label={`${item.template_libelle}`}
                                  >
                                    <Row>
                                      <Col sm={8}>{`${item.template_libelle}`}</Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  </Row>
                  {!home && (
                    <Row>
                      <Form.Item name="email_faq">
                        <Select
                          autoComplete="new-password"
                          disabled={isArchive}
                          showSearch
                          allowClear
                          optionFilterProp="label"
                          placeholder="F.A.Q."
                          style={{ width: 250, display: "block" }}
                          onSelect={this.onSelectFaq}
                        >
                          <OptGroup label="F.A.Q.">
                            {faqs &&
                              faqs
                                .sort((a, b) =>
                                  a.template_libelle.localeCompare(b.template_libelle)
                                )
                                .map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.template_id}
                                      label={`${item.template_libelle}`}
                                    >
                                      <Row>
                                        <Col sm={8}>{`${item.template_libelle}`}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    </Row>
                  )}
                </div>
              )}
            </Col>

            <Col md={4}>
              <Row className="alignCenter">{joindreButton}</Row>
              <Row className="alignCenter">{`${nombrePiecesJointes} pièces jointes`}</Row>
            </Col>
            <Col md={4}>
              <Form.Item style={{ float: "right" }}>{envoyerButton}</Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              {withForm ? (
                <Form.Item
                  name="email_destinataire"
                  onKeyUp={this.onKeyUpDestinataire}
                  rules={[
                    {
                      required: true,
                      message: "Destinataire obligatoire",
                    },
                  ]}
                  initialValue={
                    requete && requete.requete_locataire.locataire_personne.personne_email1
                  }
                >
                  <Input disabled={isArchive} autoComplete="off" placeholder="A" />
                </Form.Item>
              ) : (
                <Form.Item
                  name="email_destinataire"
                  onKeyUp={this.onKeyUpDestinataire}
                  rules={[
                    {
                      required: true,
                      message: "Destinataire obligatoire",
                    },
                  ]}
                  initialValue={defaultEmail}
                >
                  <Input disabled={isArchive} autoComplete="off" placeholder="A" />
                </Form.Item>
              )}
              <Form.Item name="email_copie" onKeyUp={this.onKeyUpCopie}>
                <Input disabled={isArchive} autoComplete="off" placeholder="CC" />
              </Form.Item>
              <Form.Item
                name="email_objet"
                onKeyUp={this.onKeyUpObjet}
                rules={[
                  {
                    required: true,
                    message: "Objet obligatoire",
                  },
                ]}
              >
                <Input disabled={isArchive} autoComplete="off" placeholder="Objet" />
              </Form.Item>
              <Form.Item name="email_message">
                <Editor
                  readOnly={isArchive}
                  editorState={editorState}
                  toolbarClassName="toolbar-absolute"
                  wrapperClassName="wrapper-class"
                  editorClassName={`editor-class ${home ? "editor-small" : "editor-large"}`}
                  onEditorStateChange={this.onEditorStateChange}
                  toolbarOnFocus
                  toolbar={{
                    options: ["inline", "blockType", "fontSize", "fontFamily"],
                    inline: {
                      options: ["bold", "italic", "underline", "strikethrough", "monospace"],
                      bold: { className: "bordered-option-classname" },
                      italic: { className: "bordered-option-classname" },
                      underline: { className: "bordered-option-classname" },
                      strikethrough: { className: "bordered-option-classname" },
                      code: { className: "bordered-option-classname" },
                    },
                    blockType: {
                      className: "bordered-option-classname",
                    },
                    fontSize: {
                      className: "bordered-option-classname",
                    },
                    fontFamily: {
                      className: "bordered-option-classname",
                    },
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

export default FormMailFields;
