import { adaptedToAdresse, adresseToAdapted } from "./adresseAdapter";
import { descriptionsToAdapted } from "./descriptionAdapter";
import { dossierToAdapted } from "./dossierAdapter";
import { emailsToAdapted } from "./emailAdapter";
import { equipementsToAdapted } from "./equipementAdapter";
import { immeubleToAdapted } from "./immeubleAdapter";
import { lotsChampsPersosToAdapted } from "./lotChampPersoAdapter";
import { matriceToAdapted } from "./matriceAdapter";
import { nuiteesToAdapted } from "./nuiteeAdapter";
import { photosToAdapted } from "./photoAdapter";
import { prixBasesToAdapted } from "./prixBaseAdapter";
import { proprietaireToAdapted, simpleProprietaireToAdapted } from "./proprietaireAdapter";
import { societeToAdapted } from "./societeAdapter";
import { typeLotToAdapted } from "./typeLotAdapter";

export function lotsToAdapted(items) {
  return items
    ? items.map((item) => {
        return lotToAdapted(item);
      })
    : null;
}

export function lotToAdapted(item) {
  return item
    ? {
        lot_id: item.id,
        lot_reference: item.reference,
        lot_code_porte: item.code_porte,
        lot_surface: item.surface,
        lot_etage: item.etage,
        lot_couchage: item.couchage,
        lot_alur: item.alur,
        lot_court_terme: item.court_terme,
        lot_long_terme: item.long_terme,
        lot_duree_minimum: item.duree_minimum,
        lot_unite_minimum: item.unite_minimum,
        lot_duree_maximum: item.duree_maximum,
        lot_unite_maximum: item.unite_maximum,
        lot_numero_mandat: item.numero_mandat,
        lot_date_signature_mandat: item.date_signature_mandat,
        lot_date_renouvellement_mandat: item.date_renouvellement_mandat,
        lot_date_echeance_mandat: item.date_echeance_mandat,
        lot_date_fin_mandat: item.date_fin_mandat,
        lot_mandat_exclusif: item.mandat_exclusif,
        lot_en_ligne: item.en_ligne,
        lot_calendrier_visible: item.calendrier_visible,
        lot_frais_gestion_alur: item.frais_gestion_alur,
        lot_frais_gestion_ct: item.frais_gestion_ct,
        lot_frais_gestion_lt: item.frais_gestion_lt,
        lot_nuite: item.nuite,
        lot_pourcentage_frais_agence: item.pourcentage_frais_agence,
        lot_fin: item.fin,
        lot_alerte: item.alerte,
        lot_dossier: dossierToAdapted(item.dossier),
        lot_adresse: adresseToAdapted(item.adresse),
        lot_type_lot: typeLotToAdapted(item.type_lot),
        lot_proprietaire: proprietaireToAdapted(item.proprietaire),
        lot_matrice: matriceToAdapted(item.matrice),
        lot_prix_base: prixBasesToAdapted(item.prix_base),
        lot_equipements: equipementsToAdapted(item.equipements),
        lot_champs_persos: lotsChampsPersosToAdapted(item.champs_persos),
        lot_photos: photosToAdapted(item.photos),
        lot_nuites: nuiteesToAdapted(item.nuites),
        lot_descriptions: descriptionsToAdapted(item.descriptions),
        lot_emails: emailsToAdapted(item.emails),
        lot_designation: item.designation,
        lot_confort: item.confort,
        lot_classement: item.classement,
        lot_code_wifi: item.code_wifi,
        lot_nb_pieces: item.nb_pieces,
        lot_commentaire: item.commentaire,
        lot_immeuble: immeubleToAdapted(item.immeuble),
        lot_societe: societeToAdapted(item.societe),
        lot_reseau_wifi: item.reseau_wifi,
        lot_reference_administrative: item.reference_administrative,
        lot_niveau: item.niveau,
        lot_remarques: item.remarques,
        lot_local_commercial: item.local_commercial,
        lot_nb_chambres: item.nb_chambres,
        lot_nb_sdb: item.nb_sdb,
        lot_nb_se: item.nb_se,
        lot_nb_toilettes: item.nb_toilettes,
        lot_nb_lits_doubles: item.nb_lits_doubles,
        lot_nb_lits_simples: item.nb_lits_simples,
        lot_nb_canapes_lits: item.nb_canapes_lits,
        lot_depot_garantie: item.depot_garantie,
        lot_id_beds24: item.id_beds24,
        notes: item.notes,
      }
    : null;
}

export function simpleLotToAdapted(item) {
  return item
    ? {
        lot_id: item.id,
        lot_reference: item.reference,
        lot_couchage: item.couchage,
        lot_designation: item.designation,
        lot_alur: item.alur,
        lot_court_terme: item.court_terme,
        lot_long_terme: item.long_terme,
        lot_proprietaire: simpleProprietaireToAdapted(item.proprietaire),
        lot_adresse: adresseToAdapted(item.adresse),
        lot_descriptions: descriptionsToAdapted(item.descriptions),
        lot_equipements: equipementsToAdapted(item.equipements),
        lot_photos: photosToAdapted(item.photos),
        lot_type_lot_id: item.type_lot_id,
        lot_immeuble_id: item.immeuble_id,
        lot_frais_gestion_alur: item.frais_gestion_alur,
        lot_frais_gestion_ct: item.frais_gestion_ct,
        lot_frais_gestion_lt: item.frais_gestion_lt,
        lot_societe_id: item.societe_id,
        notes: item.notes,
      }
    : null;
}

export function adaptedToLot(item) {
  const lot = item
    ? {
        id: item.lot_id,
        reference: item.lot_reference,
        code_porte: item.lot_code_porte,
        surface: item.lot_surface,
        etage: item.lot_etage,
        couchage: item.lot_couchage,
        alur: item.lot_alur,
        court_terme: item.lot_court_terme,
        long_terme: item.lot_long_terme,
        numero_mandat: item.lot_numero_mandat,
        date_signature_mandat: item.lot_date_signature_mandat,
        date_renouvellement_mandat: item.lot_date_renouvellement_mandat,
        date_echeance_mandat: item.lot_date_echeance_mandat,
        date_fin_mandat: item.lot_date_fin_mandat,
        mandat_exclusif: item.lot_mandat_exclusif,
        type_id: item.lot_type_lot_id,
        adresse_id: item.lot_adresse_id,
        proprietaire_id: item.lot_proprietaire_id,
        dossier_id: item.lot_dossier_id,
        fin: item.lot_fin,
        duree_minimum: item.lot_duree_minimum,
        unite_minimum: item.lot_unite_minimum,
        duree_maximum: item.lot_duree_maximum,
        unite_maximum: item.lot_unite_maximum,
        en_ligne: item.lot_en_ligne,
        calendrier_visible: item.lot_calendrier_visible,
        frais_gestion_alur: item.lot_frais_gestion_alur,
        frais_gestion_ct: item.lot_frais_gestion_ct,
        frais_gestion_lt: item.lot_frais_gestion_lt,
        nuite: item.lot_nuite,
        pourcentage_frais_agence: item.lot_pourcentage_frais_agence,
        designation: item.lot_designation,
        confort: item.lot_confort,
        classement: item.lot_classement,
        code_wifi: item.lot_code_wifi,
        nb_pieces: item.lot_nb_pieces,
        commentaire: item.lot_commentaire,
        immeuble_id: item.lot_immeuble_id,
        societe_id: item.lot_societe_id,
        reseau_wifi: item.lot_reseau_wifi,
        reference_administrative: item.lot_reference_administrative,
        niveau: item.lot_niveau,
        remarques: item.lot_remarques,
        local_commercial: item.lot_local_commercial,
        nb_chambres: item.lot_nb_chambres,
        nb_sdb: item.lot_nb_sdb,
        nb_se: item.lot_nb_se,
        nb_toilettes: item.lot_nb_toilettes,
        nb_lits_doubles: item.lot_nb_lits_doubles,
        nb_lits_simples: item.lot_nb_lits_simples,
        nb_canapes_lits: item.lot_nb_canapes_lits,
        depot_garantie: item.lot_depot_garantie,
        id_beds24: item.lot_id_beds24,
      }
    : null;

  lot.quartier = item ? item.lot_quartier : [];
  lot.metro = item ? item.lot_metro : [];

  const adresse = item ? adaptedToAdresse(item) : null;

  const alerte = item ? {} : null; // TODO

  return item ? { ...lot, ...adresse, ...alerte } : null;
}
