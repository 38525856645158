import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";

import { toast } from "react-toastify";

import {
  saveEcriture,
  updateEcriture,
  deleteEcriture,
} from "../../../store/actions/ecritureAction";
import { loadRapprochementsBancaires } from "../../../store/actions/rapprochementBancaireAction";
import { loadComptes } from "../../../store/actions/compteAction";
import { loadProprietaires } from "../../../store/actions/proprietaireAction";
import { loadLocataires } from "../../../store/actions/locataireAction";
import { loadLocations } from "../../../store/actions/locationAction";
import { loadLots } from "../../../store/actions/lotAction";

import FormEcriture from "../../../components/FormEcriture/index";

class Operation extends Component {
  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    const groupement_id = this.props.utilisateur.groupement_id;
    this.props.loadComptes(societe_id);
    this.props.loadRapprochementsBancaires(societe_id);
    this.props.loadProprietaires(groupement_id);
    this.props.loadLocataires(groupement_id);
    this.props.loadLocations(groupement_id);
    this.props.loadLots(societe_id);
  }

  saveOperation = (ecriture) => {
    this.props
      .saveEcriture(ecriture)
      .then((res) => toast.success("Écriture créée !", { containerId: "A" }))
      .catch((err) => toast.error("Échec lors de la création !", { containerId: "A" }));
  };

  updateOperation = (ecriture, id) => {
    this.props
      .updateEcriture(ecriture, id)
      .then((res) => toast.success("Écriture modifiée !", { containerId: "A" }))
      .catch((err) => toast.error("Échec lors de la modification !", { containerId: "A" }));
  };

  deleteOperation = (ecriture) => {
    this.props
      .deleteEcriture(ecriture)
      .then((res) => toast.success("Écriture supprimée !", { containerId: "A" }))
      .catch((err) => toast.error("Échec lors de la suppression !", { containerId: "A" }));
  };

  render() {
    const {
      societes,
      journals,
      comptes,
      rubriques,
      rapprochementsBancaires,
      proprietaires,
      locataires,
      locations,
      lots,
      ecritureProps,
      readOnly,
      utilisateur,
    } = this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={!comptes || !rapprochementsBancaires}
      >
        <div style={{ padding: 10 }}>
          {comptes && rapprochementsBancaires && (
            <FormEcriture
              utilisateur={utilisateur}
              ecritureProps={ecritureProps}
              readOnly={readOnly}
              societes={societes.filter((s) => s.societe_id === this.props.utilisateur.societe_id)}
              journals={journals.sort((a, b) => a.journal_code.localeCompare(b.journal_code))}
              comptes={comptes}
              rubriques={rubriques
                .filter((r) => r.rubrique_type === "P")
                .sort((a, b) => a.rubrique_code - b.rubrique_code)}
              rapprochementsBancaires={rapprochementsBancaires}
              proprietaires={proprietaires}
              locataires={locataires}
              locations={
                locations &&
                locations.filter(
                  (item) => item.location_lot.lot_societe_id === utilisateur.societe_id
                )
              }
              lots={lots}
              saveOperation={this.saveOperation}
              updateOperation={this.updateOperation}
              deleteOperation={this.deleteOperation}
            />
          )}
        </div>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadRapprochementsBancaires: (societe_id) => dispatch(loadRapprochementsBancaires(societe_id)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
    updateEcriture: (ecriture, id) => dispatch(updateEcriture(ecriture, id)),
    deleteEcriture: (id) => dispatch(deleteEcriture(id)),
    loadProprietaires: (groupement_id) => dispatch(loadProprietaires(groupement_id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    journals: state.journals.data,
    comptes: state.comptes.data,
    rubriques: state.rubriques.data,
    rapprochementsBancaires: state.rapprochementsBancaires.data,
    proprietaires: state.proprietaires.data,
    locataires: state.locataires.data,
    locations: state.locations.data,
    lots: state.lots.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Operation);
