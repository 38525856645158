import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Divider,
  Select,
  Button,
  Typography,
  Popconfirm,
  Switch,
} from "antd";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ControlOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Column from "antd/lib/table/Column";

import CustomSelect from "../CustomFormItem/Select";
import CustomInput from "../CustomFormItem/Input";
import CustomDatePicker from "../CustomFormItem/DatePicker";

import { toast } from "react-toastify";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;
const { Text } = Typography;

class FormEcriture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comptesGeneraux: [],
      compteGeneral: null,
      comptesAuxiliaires: [],
      idCompteAuxiliaire: null,
      locatairesFiltered: [],
      dateEcriture: null,
      libelle: "",
      letrrage: "",
      ecriture: null,
      ecritureLignes: [],
      cpt: 1,
      enReddition: false,
    };
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    if (this.props.ecritureProps) {
      this.initEcriture(this.props.ecritureProps);
    } else {
      this.formRef.current.setFieldsValue({
        ecriture_ligne_lettrage: "",
        ecriture_ligne_montant_debit: 0,
        ecriture_ligne_montant_credit: 0,
        ecriture_ligne_en_reddition: false,
        ecriture_societe_id: this.props.societes[0].societe_id,
        //ecriture_journal_id: this.props.journals[0].journal_id,
      });
    }
    this.setComptesGeneraux(this.props.comptes);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.ecritureProps !== props.ecritureProps) {
      if (props.ecritureProps) this.initEcriture(props.ecritureProps);
      else this.formRef.current.resetFields();
      this.formRef.current.setFieldsValue({
        ecriture_ligne_montant_debit: 0,
        ecriture_ligne_montant_credit: 0,
        ecriture_ligne_en_reddition: false,
        ecriture_societe_id: this.props.societes[0].societe_id,
        //ecriture_journal_id: this.props.journals[0].journal_id,
      });
      this.setComptesGeneraux(props.comptes);
    }
  }

  resetForm = () => {
    this.formRef.current.resetFields([
      "ecriture_libelle",
      "compte_general",
      "compte_auxiliaire_id",
      "ecriture_ligne_rubrique",
      "ecriture_ligne_libelle",
      "ecriture_ligne_lettrage",
      "ecriture_ligne_en_reddition",
    ]);
    this.formRef.current.setFieldsValue({
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_en_reddition: false,
      ecriture_societe_id: this.props.societes[0].societe_id,
      ecriture_journal_id: this.props.journals[0].journal_id,
    });
  };

  initEcriture = (ecriture) => {
    const ecritureLignes = [];
    this.formRef.current.setFieldsValue({
      ecriture_societe_id: ecriture.ecriture_journal.journal_societe_id,
      ecriture_journal_id: ecriture.ecriture_journal.journal_id,
      ecriture_date_ecriture: dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY"),
      ecriture_libelle: ecriture.ecriture_libelle,
      ecriture_ligne_libelle: ecriture.ecriture_libelle,
      ecriture_ligne_lettrage: "",
      ecriture_ligne_en_reddition: false,
    });
    let cpt = 0;
    ecriture.ecriture_ligne.forEach((ligne) => {
      ligne.cpt = cpt;
      ligne.compte_general = this.props.comptes.find(
        (item) => item.compte_id === ligne.ecriture_ligne_compte_id
      )?.compte_compte_general;
      ligne.compte_auxiliaire = this.props.comptes.find(
        (item) => item.compte_id === ligne.ecriture_ligne_compte_id
      )?.compte_compte_auxiliaire;
      ecritureLignes.push(ligne);
      cpt++;
    });
    this.setState({
      dateEcriture: dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY"),
      libelle: ecriture.ecriture_libelle,
      ecriture,
      ecritureLignes: ecriture.ecriture_ligne,
      cpt,
    });
  };

  setComptesGeneraux = (comptes) => {
    let comptesGenerauxMap = [];
    if (comptes !== undefined) {
      comptesGenerauxMap = comptes.filter(
        (compte) => compte.compte_type_compte === "G" || compte.compte_type_compte === "C"
      );
      comptesGenerauxMap.sort((a, b) => a.compte_id - b.compte_id);
    }
    this.setState({ comptesGeneraux: comptesGenerauxMap });
  };

  handleChangeCompteGeneral = (value, id) => {
    let comptesAuxiliairesMap = this.props.comptes.filter(
      (compte) =>
        compte.compte_compte_general === value &&
        (compte.compte_type_compte === "A" || compte.compte_type_compte === "G")
    );
    let idCompteAuxiliaire = null;
    if (comptesAuxiliairesMap !== undefined && comptesAuxiliairesMap.length > 0) {
      idCompteAuxiliaire = id ? id : comptesAuxiliairesMap[0].compte_id;
      this.formRef.current.setFieldsValue({
        compte_auxiliaire_id: id
          ? idCompteAuxiliaire
          : comptesAuxiliairesMap.length === 1
          ? idCompteAuxiliaire
          : null,
        ecriture_ligne_locataire_code: null,
      });
    }
    this.setState({
      compteGeneral: value,
      comptesAuxiliaires: comptesAuxiliairesMap,
      idCompteAuxiliaire: id
        ? idCompteAuxiliaire
        : comptesAuxiliairesMap.length === 1
        ? idCompteAuxiliaire
        : null,
    });
  };

  handleChangeCompteAuxiliaire = (value, ext = false) => {
    let locations = [];
    let locataires = [];
    const proprietaire = this.props.proprietaires.find((pro) =>
      pro.proprietaire_affectations.some(
        (affectation) => affectation.proprietaire_affectation_compte_id === parseInt(value)
      )
    );
    if (proprietaire) {
      locations = this.props.locations.filter(
        (location) =>
          location.location_lot.lot_proprietaire.proprietaire_id === proprietaire.proprietaire_id
      );
      ext &&
        this.formRef.current.setFieldsValue({
          compte_auxiliaire_id: value,
        });
    } else {
      ext &&
        this.formRef.current.setFieldsValue({
          compte_auxiliaire_id: null,
        });
    }
    locations.length > 0 &&
      locations.forEach((location) => {
        if (location.location_requete !== null) {
          locataires.push(location.location_requete.requete_locataire.locataire_id);
        }
      });
    this.setState({ idCompteAuxiliaire: value, locatairesFiltered: locataires });
  };

  onSelectLot = (id) => {
    const lot = this.props.lots.find((lot) => lot.lot_id === id);
    const idCompte =
      lot?.lot_proprietaire?.proprietaire_affectations[0]?.proprietaire_affectation_compte_id;
    this.handleChangeCompteAuxiliaire(idCompte, true);
  };

  onChangeDebit = (value) => {
    if (parseFloat(value) !== 0) {
      this.formRef.current.setFieldsValue({
        ecriture_ligne_montant_credit: 0,
      });
    }
  };

  onChangeCredit = (value) => {
    if (parseFloat(value) !== 0) {
      this.formRef.current.setFieldsValue({
        ecriture_ligne_montant_debit: 0,
      });
    }
  };

  onFinishFormEcritureLigne = (data) => {
    const debit = data.ecriture_ligne_montant_debit
      ? data.ecriture_ligne_montant_debit.replace(/,/g, ".")
      : data.ecriture_ligne_montant_debit;
    const credit = data.ecriture_ligne_montant_credit
      ? data.ecriture_ligne_montant_credit.replace(/,/g, ".")
      : data.ecriture_ligne_montant_credit;
    data.cpt = this.state.cpt;
    if ("ecriture_ligne_locataire_code" in data === false) {
      data.ecriture_ligne_locataire_code = "";
    }
    if (!data.ecriture_ligne_en_reddition) data.ecriture_ligne_en_reddition = false;
    data.ecriture_ligne_montant_debit = debit === "" ? 0 : debit;
    data.ecriture_ligne_montant_credit = credit === "" ? 0 : credit;
    data.ecriture_ligne_compte_id = this.state.idCompteAuxiliaire;
    data.compte_auxiliaire = this.state.comptesAuxiliaires.find(
      (item) => item.compte_id === data.compte_auxiliaire_id
    ).compte_compte_auxiliaire;
    if (debit === 0 && credit === 0) {
      toast.error("Veuillez saisir un montant au débit ou au crédit", { containerId: "A" });
    } else {
      this.formRef.current.resetFields([
        "compte_general",
        "compte_auxiliaire_id",
        "ecriture_ligne_lettrage",
        "ecriture_ligne_rubrique",
        "ecriture_ligne_en_reddition",
      ]);
      this.formRef.current.setFieldsValue({
        ecriture_ligne_montant_debit: 0,
        ecriture_ligne_montant_credit: 0,
        ecriture_ligne_en_reddition: false,
      });
      this.setState((prevState) => ({
        ecritureLignes: [...prevState.ecritureLignes, data],
        cpt: this.state.cpt + 1,
      }));
    }
  };

  editEcritureLigne = (record) => {
    this.formRef.current.setFieldsValue({
      compte_general: record.compte_general,
      //compte_auxiliaire_id: record.ecriture_ligne_compte_id,
      ecriture_ligne_rubrique: record.ecriture_ligne_rubrique,
      ecriture_ligne_libelle: record.ecriture_ligne_libelle,
      ecriture_ligne_lettrage: record.ecriture_ligne_lettrage,
      ecriture_ligne_montant_debit: record.ecriture_ligne_montant_debit,
      ecriture_ligne_montant_credit: record.ecriture_ligne_montant_credit,
      ecriture_ligne_locataire_code: record.ecriture_ligne_locataire_code,
      ecriture_ligne_en_reddition: record.ecriture_ligne_en_reddition,
    });

    this.handleChangeCompteGeneral(record.compte_general, record.ecriture_ligne_compte_id);
    this.handleChangeCompteAuxiliaire(record.ecriture_ligne_compte_id);
    this.deleteEcritureLigne(record.cpt);
  };

  deleteEcritureLigne = (id) => {
    this.setState({
      ecritureLignes: this.state.ecritureLignes.filter(function (ligne) {
        return ligne.cpt !== id;
      }),
    });
  };

  handleSubmitOperation = () => {
    const admin = this.props.utilisateur.utilisateur_admin;
    const data = {
      ecriture_journal_id: this.formRef.current.getFieldValue("ecriture_journal_id"),
      ecriture_date_ecriture: this.formRef.current.getFieldValue("ecriture_date_ecriture"),
      ecriture_libelle: this.formRef.current.getFieldValue("ecriture_libelle"),
      ecriture_ligne: this.state.ecritureLignes,
    };
    if (this.props.ecritureProps) {
      data.ecriture_date_ecriture = this.state.dateEcriture;
      data.ecriture_date_saisie = this.props.ecritureProps.ecriture_date_saisie;
      data.ecriture_lettrage = this.props.ecritureProps.ecriture_lettrage;
      data.ecriture_action = this.props.ecritureProps.ecriture_action;
      data.ecriture_validee_par = this.props.ecritureProps.ecriture_validee_par;
    }
    let totalDebit = 0;
    let totalCredit = 0;
    this.state.ecritureLignes.forEach((ligne) => {
      totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
      totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
    });

    const rapprochement =
      this.props.rapprochementsBancaires.length > 0 &&
      this.props.rapprochementsBancaires.reduce((p, v) =>
        dayjs(p.rapprochement_bancaire_date_releve, "YYYY-MM-DD").isAfter(
          dayjs(v.rapprochement_bancaire_date_releve, "YYYY-MM-DD")
        )
          ? p
          : v
      );
    const journal = this.props.journals.find(
      (journal) => journal.journal_id === data.ecriture_journal_id
    );

    if (
      journal &&
      journal.journal_de_banque &&
      rapprochement &&
      dayjs(rapprochement.rapprochement_bancaire_date_releve, "YYYY-MM-DD").isSameOrAfter(
        dayjs(data.ecriture_date_ecriture, "DD/MM/YYYY")
      ) &&
      !admin
    ) {
      toast.error(
        "Validation impossible, la date d'écriture est inférieure à la date du dernier rapprochement bancaire",
        { containerId: "A" }
      );
    } else {
      if (
        this.state.ecritureLignes.length > 0 &&
        parseFloat(totalDebit).toFixed(2) === parseFloat(totalCredit).toFixed(2)
      ) {
        this.props.ecritureProps
          ? this.props.updateOperation(data, this.props.ecritureProps.ecriture_id)
          : this.props.saveOperation(data);
        this.resetForm();
        this.setState({ ecritureLignes: [] });
      } else toast.error("Écriture déséquilibrée !", { containerId: "A" });
    }
  };

  handleDeleteOperation = () => {
    this.props.ecritureProps && this.props.deleteOperation(this.props.ecritureProps.ecriture_id);
    this.resetForm();
    this.setState({ ecritureLignes: [] });
  };

  setLigneEcriture = () => {};

  onDateEcritureChange = (e) => {
    this.setState({ dateEcriture: e });
  };

  onLibelleEcrChange = (event) => {
    this.formRef.current.setFieldsValue({
      ecriture_ligne_libelle: event.target.value,
    });
    this.setState({ libelle: event.target.value });
  };

  setContrepartie = () => {
    let totalDebit = 0;
    let totalCredit = 0;
    this.state.ecritureLignes.forEach((ligne) => {
      totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
      totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
    });
    if (totalDebit !== totalCredit) {
      const journal = this.props.journals.find(
        (journal) =>
          journal.journal_id === this.formRef.current.getFieldValue("ecriture_journal_id")
      );
      const compte =
        journal &&
        this.props.comptes.find((compte) => compte.compte_id === journal.journal_compte.compte_id);
      const bool =
        compte &&
        this.state.ecritureLignes.some((ligne) => {
          return ligne.ecriture_ligne_compte_id === compte.compte_id;
        });
      if (!bool) {
        const ecritureLigne = {
          cpt: this.state.cpt,
          compte_general: compte && compte.compte_compte_general,
          compte_auxiliaire: compte && compte.compte_compte_auxiliaire,
          ecriture_ligne_compte_id: compte && compte.compte_id,
          ecriture_ligne_libelle: this.formRef.current.getFieldValue("ecriture_libelle"),
          ecriture_ligne_locataire_code: "",
          ecriture_ligne_en_reddition: false,
        };
        if (totalCredit - totalDebit > 0) {
          ecritureLigne.ecriture_ligne_montant_debit = totalCredit - totalDebit;
          ecritureLigne.ecriture_ligne_montant_credit = 0;
        } else {
          ecritureLigne.ecriture_ligne_montant_debit = 0;
          ecritureLigne.ecriture_ligne_montant_credit = totalDebit - totalCredit;
        }
        this.setState((prevState) => ({
          ecritureLignes: [...prevState.ecritureLignes, ecritureLigne],
          cpt: this.state.cpt + 1,
        }));
      } else toast.error("Compte déjà présent !", { containerId: "A" });
    } else toast.error("Écriture équilibrée, contrepartie impossible !", { containerId: "A" });
  };

  onChangeEnReddition = (checked) => {
    this.setState({ enReddition: checked });
  };

  render() {
    const { readOnly, societes, journals, rubriques, comptes, locataires, lots } = this.props;
    const {
      comptesGeneraux,
      compteGeneral,
      comptesAuxiliaires,
      idCompteAuxiliaire,
      locatairesFiltered,
      libelle,
      lettrage,
      ecritureLignes,
    } = this.state;

    let comptesGenerauxTriC = [];
    comptesGenerauxTriC = comptesGeneraux
      .filter((c) => c.compte_type_compte === "C")
      .sort((a, b) => a.compte_compte_general - b.compte_compte_general);
    let comptesGenerauxTriG = [];
    comptesGenerauxTriG = comptesGeneraux
      .filter((c) => c.compte_type_compte === "G")
      .sort((a, b) => a.compte_compte_general - b.compte_compte_general);

    let comptesGenerauxTri = comptesGenerauxTriC.concat(comptesGenerauxTriG);

    return (
      <div>
        <Form
          ref={this.formRef}
          className="form-ecriture"
          style={{ paddingLeft: 50, paddingRight: 50 }}
          onFinish={this.onFinishFormEcritureLigne}
        >
          <Row>
            <Col span={20}>
              <Row gutter={10}>
                <Divider>Ecriture</Divider>
                <Col span={6}>
                  <CustomSelect
                    label="Société"
                    inputName="societe"
                    formItemName="ecriture_societe_id"
                    objectValue={societes && societes[0].societe_id}
                  >
                    <OptGroup label="Société">
                      {societes &&
                        societes.map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.societe_id}
                              label={item.societe_raison_sociale}
                            >
                              <Row>
                                <Col sm={8}>{item.societe_raison_sociale}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </CustomSelect>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={6}>
                  <CustomSelect
                    label="Journal"
                    inputName="journal"
                    formItemName="ecriture_journal_id"
                    objectValue={journals && journals[0].journal_id}
                    rules={{ required: true, message: "Journal obligatoire" }}
                  >
                    <OptGroup label="Journal">
                      {journals &&
                        journals.map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.journal_id}
                              label={item.journal_code + " - " + item.journal_libelle}
                            >
                              <Row>
                                <Col sm={8}>
                                  {item.journal_code} - {item.journal_libelle}
                                </Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </CustomSelect>
                </Col>
                <Col span={6}>
                  <CustomDatePicker
                    label="Date d'écriture"
                    inputName="dateEcriture"
                    formItemName="ecriture_date_ecriture"
                    objectValue={dayjs()}
                    onChange={(e) => this.onDateEcritureChange(e)}
                    today={true}
                  />
                </Col>
                <Col span={12}>
                  <CustomInput
                    label="Libellé"
                    inputName="libelle"
                    formItemName="ecriture_libelle"
                    objectValue={libelle}
                    onChange={this.onLibelleEcrChange}
                  />
                </Col>
              </Row>
              {!readOnly && (
                <>
                  <Divider>Ligne Ecriture</Divider>
                  <Row gutter={10}>
                    <Col span={6}>
                      <CustomSelect
                        label="Compte Général"
                        inputName="compteGeneral"
                        formItemName="compte_general"
                        objectValue={compteGeneral}
                        onChange={this.handleChangeCompteGeneral}
                      >
                        <OptGroup label="Comptes Généraux">
                          {comptesGenerauxTri &&
                            comptesGenerauxTri.map((item, i) => {
                              return (
                                <Option
                                  key={i}
                                  value={item.compte_compte_general}
                                  label={item.compte_compte_general + " - " + item.compte_libelle}
                                >
                                  <Row>
                                    <Col sm={8}>
                                      {item.compte_compte_general} - {item.compte_libelle}
                                    </Col>
                                  </Row>
                                </Option>
                              );
                            })}
                        </OptGroup>
                      </CustomSelect>
                    </Col>
                    {comptesAuxiliaires.length > 0 && (
                      <Col span={6}>
                        <CustomSelect
                          label="Compte Auxiliaire"
                          inputName="compteAuxiliaire"
                          formItemName="compte_auxiliaire_id"
                          objectValue={idCompteAuxiliaire}
                          onChange={this.handleChangeCompteAuxiliaire}
                        >
                          <OptGroup label="Comptes Auxiliaires">
                            {comptesAuxiliaires &&
                              comptesAuxiliaires.map((item) => {
                                return (
                                  <Option
                                    key={item.compte_id}
                                    value={item.compte_id}
                                    label={
                                      item.compte_compte_auxiliaire + " - " + item.compte_libelle
                                    }
                                  >
                                    <Row>
                                      <Col sm={8}>
                                        {item.compte_compte_auxiliaire} - {item.compte_libelle}
                                      </Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                          </OptGroup>
                        </CustomSelect>
                      </Col>
                    )}
                    {compteGeneral === "411200" && comptesAuxiliaires.length > 0 && (
                      <Col span={6}>
                        <CustomSelect
                          label="Lot"
                          inputName="lot"
                          formItemName="ecriture_ligne_lot_id"
                          onChange={this.onSelectLot}
                          objectValue={null}
                        >
                          <OptGroup label="Appartement">
                            {lots &&
                              lots
                                .sort((a, b) => a.lot_reference.localeCompare(b.lot_reference))
                                .map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.lot_id}
                                      label={`${item.lot_reference}`}
                                    >
                                      <Row>
                                        <Col sm={8}>{`${item.lot_reference}`}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                          </OptGroup>
                        </CustomSelect>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              {!readOnly &&
                comptesAuxiliaires &&
                idCompteAuxiliaire &&
                compteGeneral === "411200" && (
                  <Row gutter={10}>
                    <Col span={6}>
                      <CustomSelect
                        label="Locataire"
                        inputName="locataire"
                        formItemName="ecriture_ligne_locataire_code"
                        objectValue={null}
                      >
                        <OptGroup label="Locataires">
                          {locataires &&
                            locataires
                              .filter((locataire) =>
                                locatairesFiltered.includes(locataire.locataire_id)
                              )
                              .map((item) => {
                                return (
                                  <Option
                                    key={item.locataire_id}
                                    value={item.locataire_code}
                                    label={
                                      item.locataire_code +
                                      " - " +
                                      item.locataire_personne.personne_nom +
                                      " " +
                                      item.locataire_personne.personne_prenom
                                    }
                                  >
                                    <Row>
                                      <Col sm={8}>
                                        {item.locataire_code} -{" "}
                                        {item.locataire_personne.personne_nom}{" "}
                                        {item.locataire_personne.personne_prenom}
                                      </Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                        </OptGroup>
                      </CustomSelect>
                    </Col>
                    <Col span={6}>
                      <CustomSelect
                        label="Rubrique"
                        inputName="rubrique"
                        formItemName="ecriture_ligne_rubrique"
                        objectValue={null}
                      >
                        <OptGroup label="Rubriques">
                          {rubriques &&
                            rubriques.map((item, i) => {
                              return (
                                <Option
                                  key={i}
                                  value={`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                  label={`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                >
                                  <Row>
                                    <Col sm={8}>
                                      {item.rubrique_code} - ({item.rubrique_type}
                                      {item.rubrique_signe}) {item.rubrique_libelle}
                                    </Col>
                                  </Row>
                                </Option>
                              );
                            })}
                        </OptGroup>
                      </CustomSelect>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="ecriture_ligne_en_reddition"
                        initialValue={false}
                        valuePropName="checked"
                      >
                        <Switch
                          onChange={this.onChangeEnReddition}
                          checkedChildren="Reddition"
                          unCheckedChildren="Reddition"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              {!readOnly &&
                comptesAuxiliaires &&
                idCompteAuxiliaire &&
                compteGeneral === "467001" && (
                  <Row gutter={10}>
                    <Col span={6}>
                      <CustomSelect
                        label="Locataire"
                        inputName="locataire"
                        formItemName="ecriture_ligne_locataire_code"
                        objectValue={null}
                      >
                        <OptGroup label="Locataires">
                          {comptes &&
                            comptes
                              .filter(
                                (item) =>
                                  item.compte_compte_general === "418000" &&
                                  item.compte_type_compte === "A"
                              )
                              .map((item) => {
                                return (
                                  <Option
                                    key={item.compte_id}
                                    value={item.compte_compte_auxiliaire}
                                    label={
                                      item.compte_compte_auxiliaire + " - " + item.compte_libelle
                                    }
                                  >
                                    <Row>
                                      <Col sm={8}>
                                        {item.compte_compte_auxiliaire} - {item.compte_libelle}
                                      </Col>
                                    </Row>
                                  </Option>
                                );
                              })}
                        </OptGroup>
                      </CustomSelect>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
          {!readOnly && (
            <Row gutter={10}>
              <Col span={10}>
                <CustomInput
                  label="Libellé"
                  inputName="libelle"
                  formItemName="ecriture_ligne_libelle"
                  objectValue={libelle}
                />
              </Col>
              <Col span={0} style={{ display: "none" }}>
                <CustomInput
                  label="Lettrage"
                  inputName="lettrage"
                  formItemName="ecriture_ligne_lettrage"
                  objectValue={lettrage}
                />
              </Col>
              <Col span={5}>
                <CustomInput
                  label="Débit"
                  inputName="debit"
                  formItemName="ecriture_ligne_montant_debit"
                  objectValue="0"
                  type="text"
                  onChange={(e) => this.onChangeDebit(e.target.value)}
                  // suffix="€"
                />
              </Col>
              <Col span={5}>
                <CustomInput
                  label="Crédit"
                  inputName="credit"
                  formItemName="ecriture_ligne_montant_credit"
                  objectValue="0"
                  type="text"
                  onChange={(e) => this.onChangeCredit(e.target.value)}
                  // suffix="€"
                />
              </Col>
              <Col span={2}>
                <Button
                  icon={<ArrowDownOutlined />}
                  shape="circle"
                  htmlType="submit"
                  style={{ marginLeft: "20px" }}
                />
              </Col>
              <Col span={2}>
                <Button
                  icon={<ControlOutlined />}
                  shape="circle"
                  onClick={this.setContrepartie}
                  style={{ marginLeft: "20px" }}
                />
              </Col>
            </Row>
          )}
        </Form>

        <Table
          dataSource={ecritureLignes}
          className="table-ecritures"
          pagination={false}
          rowKey="cpt"
          loading={comptesGeneraux.length === 0}
          summary={(pageData) => {
            let totalDebit = 0;
            let totalCredit = 0;

            pageData.forEach(({ ecriture_ligne_montant_debit, ecriture_ligne_montant_credit }) => {
              totalDebit += parseFloat(ecriture_ligne_montant_debit);
              totalCredit += parseFloat(ecriture_ligne_montant_credit);
            });

            let background = "#ff7875";
            if (parseFloat(totalDebit).toFixed(2) === parseFloat(totalCredit).toFixed(2)) {
              background = "#b7eb8f";
            }

            return (
              <>
                <Table.Summary.Row style={{ background: background }}>
                  <Table.Summary.Cell>Total</Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text>
                      <strong>
                        {parseFloat(totalDebit).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text>
                      <strong>
                        {parseFloat(totalCredit).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  {!readOnly && <Table.Summary.Cell></Table.Summary.Cell>}
                  {!readOnly && <Table.Summary.Cell></Table.Summary.Cell>}
                </Table.Summary.Row>
                <Table.Summary.Row style={{ background: background }}>
                  <Table.Summary.Cell>Solde</Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text>
                      <strong>
                        {parseFloat(totalDebit - totalCredit).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  {!readOnly && <Table.Summary.Cell></Table.Summary.Cell>}
                  {!readOnly && <Table.Summary.Cell></Table.Summary.Cell>}
                </Table.Summary.Row>
              </>
            );
          }}
        >
          <Column
            title="Compte Général"
            dataIndex="compte_general"
            key="compte_general"
            width="10%"
          />
          <Column
            title="Compte Auxiliaire"
            dataIndex="compte_auxiliaire"
            key="compte_auxiliaire"
            width="10%"
          />
          <Column
            title="Rubrique"
            dataIndex="ecriture_ligne_rubrique"
            key="ecriture_ligne_rubrique"
            width="15%"
          />
          <Column
            title="Libellé"
            dataIndex="ecriture_ligne_libelle"
            key="ecriture_ligne_libelle"
            width="31%"
          />
          <Column
            title="Montant Débit"
            dataIndex="ecriture_ligne_montant_debit"
            key="ecriture_ligne_montant_debit"
            width="10%"
          />
          <Column
            title="Montant Crédit"
            dataIndex="ecriture_ligne_montant_credit"
            key="ecriture_ligne_montant_credit"
            width="10%"
          />
          {!readOnly && (
            <Column
              title="Supprimer"
              dataIndex="cpt"
              key="cpt"
              width="7%"
              render={(cpt) => (
                <>
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    shape="circle"
                    style={{ width: 20, height: 20 }}
                    onClick={() => this.deleteEcritureLigne(cpt)}
                  />
                </>
              )}
            />
          )}
          {!readOnly && (
            <Column
              title="Modifier"
              dataIndex="cpt"
              key="cpt"
              width="7%"
              render={(text, record, index) => (
                <>
                  <Button
                    size="small"
                    icon={<ArrowUpOutlined />}
                    shape="circle"
                    style={{ width: 20, height: 20 }}
                    onClick={() => this.editEcritureLigne(record)}
                  />
                </>
              )}
            />
          )}
        </Table>
        {!readOnly && (
          <Row gutter={12} style={{ marginTop: "10px" }}>
            <Col span={2} offset={20}>
              <Popconfirm
                title="Êtes-vous sûr de vouloir supprimer cette opération comptable ?"
                onConfirm={this.handleDeleteOperation}
                cancelText="Annuler"
              >
                <Button type="primary" danger style={{ width: "100%" }}>
                  Supprimer
                </Button>
              </Popconfirm>
            </Col>
            <Col span={2}>
              <Popconfirm
                title="Souhaitez-vous valider cette opération comptable ?"
                onConfirm={this.handleSubmitOperation}
                cancelText="Annuler"
              >
                <Button type="primary" style={{ width: "100%" }}>
                  Valider
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default FormEcriture;
