import React, { Component } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Space,
  Table,
  Modal,
  Typography,
  Checkbox,
  Popconfirm,
  Divider,
  Switch,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import { round, formatDateToBDD } from "../../utils/tools";
import { EditableRow, EditableCell } from "../EditableTable";

import { toast } from "react-toastify";

import dayjs from "dayjs";
import ViewMail from "../ViewMail";
import FormTarifLot from "../FormTarifLot";
import FormCalculPlateforme from "../FormCalculPlateforme";
const dateFormat = "DD-MM-YYYY";

const { Column } = Table;
const { Text } = Typography;

class FormTarif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      idLot: null,
      contratConfirme: false,
      contratAnnule: false,
      rubriques: [],
      modalRubrique: false,
      modalTarif: false,
      modalPlateforme: false,
      modalReglement: false,
      modalDepenses: false,
      modalProprietaire: false,
      depensesLignes: [],
      depenses: false,
      selectedRows: [],
      visible: false,
      typeContrat: null,
      notificationTxt: "",
      notificationObj: "",
      lignes: [],
      modification: true,
      dateOption: "",
      desiderata: true,
      compta: true,
    };
  }

  componentDidMount() {
    this.init(this.props.location);
    this.getRubriquesAvailable(this.props.location);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.init(nextProps.location);
    }
    this.getRubriquesAvailable(this.props.location);
  }

  init = (location) => {
    if (location !== undefined && location !== null) {
      if (location.location_ligne.length === 0) {
        this.getLignesTarif(location);
      }
    }
  };

  getRubriquesAvailable = (location, value) => {
    let rubriques = this.props.rubriques;
    rubriques = rubriques.sort((a, b) => a.rubrique_code - b.rubrique_code);
    location &&
      rubriques &&
      location.location_ligne.forEach((ligne) => {
        rubriques = rubriques.filter(
          (i) => i.rubrique_id !== ligne.location_ligne_rubrique.rubrique_id
        );
      });

    if (!value || (value && value.target.checked === false)) {
      rubriques = rubriques.filter((i) => i.rubrique_contrat === true);
    }

    this.setState({ rubriques });
  };

  getRubriques = (value) => {
    this.getRubriquesAvailable(this.props.location, value);
  };

  openModalRubrique = () => {
    this.setState({ modalRubrique: true });
  };

  closeModalRubrique = () => {
    this.setState({ modalRubrique: false });
  };

  openModalTarif = () => {
    this.setState({ modalTarif: true });
  };

  closeModalTarif = () => {
    this.setState({ modalTarif: false });
  };

  openModalPlateforme = () => {
    this.setState({ modalPlateforme: true });
  };

  closeModalPlateforme = () => {
    this.setState({ modalPlateforme: false });
  };

  openModalReglement = () => {
    this.setState({ modalReglement: true });
  };

  closeModalReglement = () => {
    this.setState({ modalReglement: false });
  };

  openModalDepenses = () => {
    this.setState({ modalDepenses: true });
  };

  closeModalDepenses = () => {
    this.setState({ modalDepenses: false });
  };

  openModalProprietaire = () => {
    this.setState({ modalProprietaire: true });
  };

  closeModalProprietaire = () => {
    this.setState({ modalProprietaire: false });
  };

  handleSubmitAddRubriques = () => {
    this.state.selectedRows.forEach((rubrique) => {
      let montant = 0;

      if (rubrique.rubrique_pourcentage !== null || rubrique.rubrique_pourcentage !== 0) {
        rubrique.rubrique_liees.forEach((item) => {
          let prix = this.props.location.location_ligne.find(
            (p) => p.location_ligne_rubrique.rubrique_id === item.rubrique_id
          );
          if (prix) {
            montant +=
              (parseFloat(prix.location_ligne_montant) * rubrique.rubrique_pourcentage) / 100;
          }
        });
      }
      if (rubrique.rubrique_montant_fixe === true) {
        montant = rubrique.rubrique_montant_defaut;
      }

      const ligne = {
        location_ligne_location_id: this.props.location.location_id,
        location_ligne_montant: montant,
        location_ligne_pourcentage_acompte: rubrique.rubrique_pourcentage_acompte,
        location_ligne_rubrique_id: rubrique.rubrique_id,
      };

      this.props.saveLocationLigne(ligne).catch((err) => console.log("err", err));

      this.closeModalRubrique();
    });
    this.setState({ modification: false });
  };

  /* update locations lignes */
  handleSave = (row) => {
    const ligne = this.props.location.location_ligne.find(
      (ligne) => ligne.location_ligne_id === row.location_ligne_id
    );
    this.props
      .updateLocationLigne(
        {
          location_ligne_libelle: row.location_ligne_libelle,
          location_ligne_montant: row.location_ligne_montant,
          location_ligne_pourcentage_acompte: row.location_ligne_pourcentage_acompte,
          location_ligne_permanente: row.location_ligne_permanente
            ? row.location_ligne_permanente
            : false,
          location_ligne_date_suppression: row.location_ligne_date_suppression,
          location_ligne_location_id: row.location_ligne_location_id,
          location_ligne_proprietaire: row.location_ligne_proprietaire,
        },
        row.location_ligne_id
      )
      .then((res) => {
        if (ligne.location_ligne_montant !== row.location_ligne_montant)
          this.setState({
            lignes: [...this.state.lignes, res.location_ligne_id],
            modification: false,
          });
      })
      .catch((err) => console.log("err", err));
  };

  onCheckPermanente = (e, locationLigne) => {
    locationLigne.location_ligne_permanente = e.target.checked;
    this.handleSave(locationLigne);
  };

  onChangeDateSuppression = (e, locationLigne) => {
    locationLigne.location_ligne_date_suppression = e ? e.format("DD/MM/YYYY") : null;
    this.handleSave(locationLigne);
  };

  deleteLocationLigne = (id) => {
    const quittances = this.props.locationQuittances;
    const reglements = this.props.reglements.filter((reg) => {
      return (
        quittances.find(
          (quittance) => quittance.quittance_id === reg.reglement_quittance.quittance_id
        ) !== undefined
      );
    });
    if (reglements.length > 0) {
      toast.error(
        "Attention un encaissement existe sur cette location, suppression de la rubrique impossible (Faire une annulation encaissement avant de procéder à la modification du contrat)",
        { containerId: "A" }
      );
    } else {
      this.props.deleteLocationLigne(id).catch((err) => console.log("err", err));
      this.setState({ modification: false });
    }
  };

  loadOccupant = (location) => {
    const occupants = location.location_occupant.map((o) => {
      o.occupant_date_naissance = o.occupant_date_naissance && dayjs(o.occupant_date_naissance);
      return o;
    });

    if (occupants.length === 0) {
      const occupant = {
        occupant_nom: location.location_requete.requete_locataire.locataire_personne.personne_nom,
        occupant_prenom:
          location.location_requete.requete_locataire.locataire_personne.personne_prenom,
        occupant_date_naissance: null,
        occupant_lieu_naissance: null,
      };
      occupants.push(occupant);
    }

    this.formRefOccupant.current.setFieldsValue({
      occupants: occupants,
    });
  };

  compare = (a, comparator, b) => {
    switch (comparator) {
      case "<":
      case "< ":
        return a < b;
      case "<=":
        return a <= b;
      case ">":
      case "> ":
        return a > b;
      case ">=":
        return a >= b;
      default:
        break;
    }
  };

  getLignesTarif = (location) => {
    let lignes = [];
    let nuite = true;
    if (this.props.parametre.parametre_quittancement) {
      if (location.location_type_contrat !== null) {
        lignes =
          location.location_type_contrat === 1 || location.location_type_contrat === 2
            ? this.getAnnee(location)
            : this.getNuites(location);
        nuite =
          location.location_type_contrat === 1 || location.location_type_contrat === 2
            ? false
            : true;
      }
    } else {
      lignes = this.getNuites(location);
    }
    lignes.forEach((ligne) => {
      if (ligne && this.props.parametre.parametre_calcul_arrondi && nuite)
        ligne.location_ligne_montant = Math.round(ligne.location_ligne_montant);
      ligne && this.props.saveLocationLigne(ligne).catch((err) => console.log("err", err));
    });
  };

  resetLignesTarif = () => {
    this.props.location.location_ligne.forEach((ligne) => {
      this.deleteLocationLigne(ligne.location_ligne_id);
    });
    this.setState({ modalTarif: false }, () => {
      this.getLignesTarif(this.props.location);
    });
  };

  getAnnee = (location) => {
    const locationLignes = [];

    const lot = this.props.lots.find((lot) => lot.lot_id === location.location_lot.lot_id);

    lot.lot_matrice.matrice_ligne.forEach((item) => {
      const locationLigne = {
        location_ligne_location_id: location.location_id,
        location_ligne_pourcentage_acompte: parseFloat(
          item.matrice_ligne_rubrique.rubrique_pourcentage_acompte
        ),
        location_ligne_montant: parseFloat(item.matrice_ligne_montant_mois),
        location_ligne_rubrique_id: item.matrice_ligne_rubrique.rubrique_id,
        location_ligne_permanente: item.matrice_ligne_rubrique.rubrique_permanente,
      };
      locationLignes.push(locationLigne);
    });

    this.props.parametre.parametre_parcours_client &&
      locationLignes.push(this.getLigneCaution(locationLignes, location.location_type_contrat));

    return locationLignes;
  };

  getNuites = (location) => {
    const locationLignes = [];

    const dates = this.getAllDates(location.location_date_debut, location.location_date_fin);

    const lot = this.props.lots.find((lot) => lot.lot_id === location.location_lot.lot_id);

    lot &&
      dates.forEach((date) => {
        const special = lot.lot_nuites.filter((nuite) =>
          dayjs(date, "DD/MM/YYYY").isBetween(
            dayjs(nuite.nuite_date_debut, "YYYY-MM-DD"),
            dayjs(nuite.nuite_date_fin, "YYYY-MM-DD"),
            null,
            "[]"
          )
        );

        if (special.length > 0) {
          special.forEach((nuite) => {
            const foundIndex = locationLignes.findIndex(
              (x) => x.location_ligne_rubrique_id === nuite.nuite_rubrique.rubrique_id
            );

            if (foundIndex !== -1 && nuite.nuite_rubrique.rubrique_montant_fixe === false)
              locationLignes[foundIndex].location_ligne_montant += parseFloat(nuite.nuite_montant);
            else if (foundIndex === -1) {
              const locationLigne = {
                location_ligne_location_id: location.location_id,
                location_ligne_pourcentage_acompte:
                  nuite.nuite_rubrique.rubrique_pourcentage_acompte,
                location_ligne_montant: parseFloat(nuite.nuite_montant),
                location_ligne_rubrique_id: nuite.nuite_rubrique.rubrique_id,
                location_ligne_permanente: nuite.nuite_rubrique.rubrique_permanente,
              };
              locationLignes.push(locationLigne);
            }
          });
        } else {
          lot.lot_prix_base
            .filter((ligne) => !ligne.prix_base_proprietaire)
            .forEach((prixBase) => {
              const foundIndex = locationLignes.findIndex(
                (x) => x.location_ligne_rubrique_id === prixBase.prix_base_rubrique.rubrique_id
              );
              if (foundIndex !== -1 && prixBase.prix_base_rubrique.rubrique_montant_fixe === false)
                locationLignes[foundIndex].location_ligne_montant += parseFloat(
                  prixBase.prix_base_montant
                );
              else if (foundIndex === -1) {
                const locationLigne = {
                  location_ligne_location_id: location.location_id,
                  location_ligne_pourcentage_acompte: parseFloat(
                    prixBase.prix_base_rubrique.rubrique_pourcentage_acompte
                  ),
                  location_ligne_montant: parseFloat(prixBase.prix_base_montant),
                  location_ligne_rubrique_id: prixBase.prix_base_rubrique.rubrique_id,
                  location_ligne_permanente: prixBase.prix_base_rubrique.rubrique_permanente,
                };
                locationLignes.push(locationLigne);
              }
            });
        }
      });

    this.props.parametre.parametre_parcours_client &&
      locationLignes.push(this.getLigneCaution(locationLignes, location.location_type_contrat));

    return locationLignes;
  };

  // CAUTION
  getLigneCaution = (locationLignes, typeContrat) => {
    let locationLigne = null;
    const caution = this.props.caution.find((item) => item.caution_type_contrat === typeContrat);
    const ligne = locationLignes.find(
      (ligne) => ligne.location_ligne_rubrique_id === caution?.caution_rubrique_liee_id
    );
    let montant = 0;
    if (caution && ligne) {
      montant = round((ligne.location_ligne_montant / 100) * caution.caution_pourcentage, 2);
    }
    if (montant > 0) {
      locationLigne = {
        location_ligne_location_id: this.props.location.location_id,
        location_ligne_pourcentage_acompte: 0,
        location_ligne_montant: montant,
        location_ligne_rubrique_id: caution.caution_rubrique_id,
        location_ligne_permanente: false,
        location_ligne_proprietaire: false,
        location_ligne_date_suppression: dayjs(this.props.location.location_date_debut).endOf(
          "month"
        ),
      };
    }
    return locationLigne;
  };

  getAllDates = (debut, fin) => {
    const dates = [];
    let start = dayjs(debut, "YYYY-MM-DD");
    while (start.isBefore(dayjs(fin, "YYYY-MM-DD"))) {
      dates.push(start);
      start = start.add(1, "day");
    }
    return dates;
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  prepareDepenses = (location) => {
    const depensesLignes = [];

    const lot = this.props.lots.find((lot) => lot.lot_id === location.location_lot.lot_id);

    lot.lot_prix_base
      .filter((ligne) => ligne.prix_base_proprietaire)
      .forEach((prixBase) => {
        const depensesLigne = {
          location_ligne_location_id: location.location_id,
          location_ligne_pourcentage_acompte: parseFloat(
            prixBase.prix_base_rubrique.rubrique_pourcentage_acompte
          ),
          location_ligne_montant: parseFloat(prixBase.prix_base_montant),
          location_ligne_rubrique: prixBase.prix_base_rubrique,
          location_ligne_rubrique_id: prixBase.prix_base_rubrique.rubrique_id,
          location_ligne_permanente: prixBase.prix_base_rubrique.rubrique_permanente,
        };
        depensesLignes.push(depensesLigne);
      });

    this.setState({ depensesLignes }, () => this.openModalDepenses());
  };

  setNotificationProprietaire = (texte, objet) => {
    this.setState({ notificationTxt: texte, notificationObj: objet });
  };

  envoiNotifProp = () => {
    const arrayDestinataire = [];
    arrayDestinataire.push({
      email:
        this.props.location.location_lot.lot_proprietaire.proprietaire_personne.personne_email1,
      name:
        this.props.location.location_lot.lot_proprietaire.proprietaire_personne.personne_nom +
        " " +
        this.props.location.location_lot.lot_proprietaire.proprietaire_personne.personne_prenom,
    });

    let message = this.state.notificationTxt;
    let objet = this.state.notificationObj;

    const jsonData = {
      email_objet: objet,
      email_message: message,
      email_destinataire:
        this.props.location.location_lot.lot_proprietaire.proprietaire_personne.personne_email1,
      email_destinataire_array: arrayDestinataire,
      email_copie: "",
      email_copie_array: [],
      email_pieces_jointes: [],
      email_utilisateur_id: this.props.utilisateur.utilisateur_id,
      email_requete_id: this.props.location.location_requete.requete_id,
      email_lot_id: this.props.location.location_lot.lot_id,
      parametre_smtp: this.props.parametre.parametre_smtp,
      parametre_port: this.props.parametre.parametre_port,
      parametre_email: this.props.parametre.parametre_email,
      parametre_mdp: this.props.parametre.parametre_mdp,
      parametre_copie_agence: this.props.parametre.parametre_copie_agence,
      parametre_email_copie: this.props.parametre.parametre_email_copie,
    };

    toast.info("Envoi en cours...", {
      containerId: "A",
    });

    // Envoi du mail puis sauvegarde de celui-ci
    this.props
      .saveEmail(jsonData)
      .then((res) => {
        toast.success("Email envoyé !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de l'envoi du mail !", {
          containerId: "A",
        });
      });
  };

  handleDepenses = (depenses = false) => {
    this.setState({ depenses, modalDepenses: false }, () => this.openModalProprietaire());
  };

  prepareContrat = (notification = false) => {
    notification && this.envoiNotifProp();
    this.createContrat();
  };

  createContrat = () => {
    const location = this.props.location;
    location.location_lot_id = location.location_lot.lot_id;
    location.location_requete_id = location.location_requete.requete_id;
    location.location_etat = "r";
    location.location_date_contrat = formatDateToBDD();
    location.location_desiderata = "";
    if (this.state.compta) {
      this.props.parametre.parametre_quittancement &&
      (location.location_type_contrat === 1 || location.location_type_contrat === 2)
        ? this.createQuittanceProrata()
        : this.createQuittanceDateToDate();
    }
    this.setState({ lignes: [], modification: true, modalProprietaire: false });
    this.props
      .updateLocation(location, location.location_id)
      .then(() => {
        this.props.saveMenage({
          menage_date: dayjs(location.location_date_debut).format("YYYY-MM-DD"),
          menage_etat: "n",
          menage_type: "classique",
          menage_lot_id: location.location_lot.lot_id,
          menage_location_id: location.location_id,
        });
        toast.success(`Le contrat a été créé`, {
          containerId: "A",
        });
      })
      .catch((err) => console.log("err", err));
  };

  deleteOption = () => {
    const location = this.props.location;
    location.location_lot_id = location.location_lot.lot_id;
    location.location_requete_id = location.location_requete.requete_id;
    location.location_etat = "a";
    // Annulation de la location
    this.props
      .updateLocation(location, location.location_id)
      .then(() => {
        this.props.resync("1");
        toast.success(`L'option' a été supprimée`, {
          containerId: "A",
        });
      })
      .catch((err) => console.log("err", err));
  };

  handleDeleteContrat = () => {
    const reglements = this.props.reglements;
    const quittances = this.props.locationQuittances;
    if (
      reglements.some((reg) => {
        if (quittances.find((qui) => qui.quittance_id === reg.reglement_quittance.quittance_id)) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      this.openModalReglement();
    } else {
      this.deleteContrat();
    }
  };

  deleteContrat = (reglement = false) => {
    const location = this.props.location;
    const quittances = this.props.locationQuittances;
    location.location_lot_id = location.location_lot.lot_id;
    location.location_requete_id = location.location_requete.requete_id;
    location.location_etat = "a";
    // Annulation de la location
    this.props
      .updateLocation(location, location.location_id)
      .then(() => {
        this.props.resync("1");
        toast.success(`Le contrat a été supprimé`, {
          containerId: "A",
        });
      })
      .catch((err) => console.log("err", err));
    // Suppression des quittances
    quittances.forEach((quittance) => {
      // this.props.deleteQuittance(quittance.quittance_id);
      this.annulationEcriture(quittance);
      reglement && this.prepareTPLocataire(quittance);
    });
  };

  annulationEcriture = (quittance) => {
    const ecrituresFiltered = this.props.ecritures
      .sort((a, b) => a.ecriture_id - b.ecriture_id)
      .filter((ecriture) =>
        ecriture.ecriture_libelle.includes(`Contrat n°${quittance.quittance_location_id}`)
      );
    const ecriture = ecrituresFiltered[ecrituresFiltered.length - 1];
    ecriture && this.createEcritureAnnulation(ecriture);
  };

  prepareTPLocataire = (quittance) => {
    const reglements = this.props.reglements.filter(
      (reg) => reg.reglement_quittance.quittance_id === quittance.quittance_id
    );
    reglements &&
      reglements.forEach((reg) => {
        const ecrituresAnnulation = this.props.ecritures.filter(
          (item) => item.ecriture_action === `REG${reg.reglement_id}`
        );
        if (ecrituresAnnulation.length > 0) {
          ecrituresAnnulation.forEach((ecriture, idx, ecritures) => {
            const idCompte = reg.reglement_journal.journal_compte.compte_id;
            this.createEcritureAnnulationTP(ecriture, idCompte);
            if (idx === ecritures.length - 1) {
              this.props.deleteReglement(reg.reglement_id);
            }
          });
        }
      });
  };

  getDateFin = (location) => {
    let dateFin = location.location_date_fin;
    if (location.location_type_contrat === 1 || location.location_type_contrat === 2) {
      dateFin = dayjs(location.location_date_fin).isAfter(
        dayjs(location.location_date_debut).endOf("month")
      )
        ? dayjs(location.location_date_debut).endOf("month").format("YYYY-MM-DD")
        : location.location_date_fin;
    }
    return dayjs(dateFin).format("DD/MM/YYYY");
  };

  createQuittanceProrata = (modification = false) => {
    const location = this.props.location;
    const dateDebut = dayjs(location.location_date_debut).format("DD/MM/YYYY");
    const dateFin = this.getDateFin(location);
    this.createQuittance(dateDebut, dateFin, modification, true);
  };

  createQuittanceDateToDate = (modification = false) => {
    const location = this.props.location;
    const dateDebut = dayjs(location.location_date_debut).format("DD/MM/YYYY");
    const dateFin =
      location.location_date_fin === "2100-01-01"
        ? dayjs(location.location_date_debut)
            .add(1, "month")
            .subtract(1, "days")
            .format("DD/MM/YYYY")
        : dayjs(location.location_date_fin).format("DD/MM/YYYY");
    this.createQuittance(dateDebut, dateFin, modification, false);
  };

  createQuittance = (dateDebut, dateFin, modification, prorata = false) => {
    const location = this.props.location;
    let montant = 0;
    const quittance = {
      quittance_libelle: "Solde de location",
      quittance_date_debut: dateDebut,
      quittance_date_fin: dateFin,
      quittance_location_id: location.location_id,
      quittance_ligne: [],
    };
    const nbJours = dayjs(dateFin, "DD/MM/YYYY").diff(dayjs(dateDebut, "DD/MM/YYYY"), "days") + 1;
    const nbJoursInMonth = dayjs(dateDebut, "DD/MM/YYYY").daysInMonth();
    location.location_ligne.forEach((item) => {
      const montantLigne =
        prorata && !item.location_ligne_rubrique.rubrique_montant_fixe
          ? nbJours !== nbJoursInMonth
            ? round((item.location_ligne_montant / nbJoursInMonth) * nbJours, 2)
            : round(item.location_ligne_montant, 2)
          : round(item.location_ligne_montant, 2);
      const quittanceLigne = {
        quittance_ligne_quantite: 1,
        quittance_ligne_libelle: item.location_ligne_libelle
          ? item.location_ligne_libelle
          : item.location_ligne_rubrique.rubrique_libelle,
        quittance_ligne_montant: montantLigne,
        quittance_ligne_rubrique_id: item.location_ligne_rubrique.rubrique_id,
      };
      montant += montantLigne;
      quittance.quittance_ligne.push(quittanceLigne);
    });
    // SAVE Quittance + QuittanceLignes
    this.props
      .saveQuittance(quittance)
      .then((res) => {
        // Affectation Proprietaire
        if (location.location_lot.lot_proprietaire.proprietaire_affectations.length === 0) {
          const affectationPro = {
            proprietaire_affectation_proprietaire_id:
              location.location_lot.lot_proprietaire.proprietaire_id,
            proprietaire_affectation_societe_id: this.props.utilisateur.societe_id,
            compte_compte_general: "411200",
            compte_compte_auxiliaire: location.location_lot.lot_proprietaire.proprietaire_code,
            compte_libelle: `${location.location_lot.lot_proprietaire.proprietaire_personne.personne_nom} 
            ${location.location_lot.lot_proprietaire.proprietaire_personne.personne_prenom}`,
            compte_type_compte: "A",
            compte_loc_obligatoire: true,
            compte_actif: true,
            compte_societe_id: this.props.utilisateur.societe_id,
          };
          this.props
            .saveProprietaireAffectation(
              affectationPro,
              location.location_lot.lot_proprietaire.proprietaire_id
            )
            .then((affectation) => {
              this.state.depenses &&
                this.createEcritureDepenses(affectation.proprietaire_affectation_compte_id);
            })
            .catch((err) => toast.error("Erreur affectation proprietaire !", { containerId: "A" }));
        } else {
          this.state.depenses &&
            this.createEcritureDepenses(
              location.location_lot.lot_proprietaire.proprietaire_affectations[0]
                .proprietaire_affectation_compte_id
            );
        }
        // Affectation Locataire
        if (location.location_requete.requete_locataire.locataire_affectations.length === 0) {
          const affectationLoc = {
            locataire_affectation_locataire_id:
              location.location_requete.requete_locataire.locataire_id,
            locataire_affectation_societe_id: this.props.utilisateur.societe_id,
            compte_compte_general: "418000",
            compte_compte_auxiliaire: location.location_requete.requete_locataire.locataire_code,
            compte_libelle: `${location.location_requete.requete_locataire.locataire_personne.personne_nom} 
            ${location.location_requete.requete_locataire.locataire_personne.personne_prenom}`,
            compte_type_compte: "A",
            compte_loc_obligatoire: true,
            compte_actif: true,
            compte_societe_id: this.props.utilisateur.societe_id,
          };
          this.props
            .saveLocataireAffectation(
              affectationLoc,
              location.location_requete.requete_locataire.locataire_id
            )
            .then((affectation) => {
              this.createEcriture(
                res,
                affectation.locataire_affectation_compte_id,
                round(montant, 2),
                modification
              );
            })
            .catch((err) => toast.error("Erreur affectation locataire !", { containerId: "A" }));
        }
        // SAVE Ecriture + EcritureLignes
        else
          this.createEcriture(
            res,
            location.location_requete.requete_locataire.locataire_affectations[0]
              .locataire_affectation_compte_id,
            round(montant, 2),
            modification
          );
      })
      .catch((err) => toast.error("Erreur création quittance !", { containerId: "A" }));
  };

  updateQuittance = (quittance, modification) => {
    let montant = 0;
    const location = this.props.location;
    location.location_ligne.forEach((item) => {
      const quittanceLigne = quittance.quittance_ligne.find(
        (ligne) =>
          ligne.quittance_ligne_rubrique.rubrique_id === item.location_ligne_rubrique.rubrique_id
      );
      if (!quittanceLigne) {
        // save new ligne quittance
        const newQuittanceLigne = {
          quittance_ligne_quantite: 1,
          quittance_ligne_libelle: item.location_ligne_libelle
            ? item.location_ligne_libelle
            : item.location_ligne_rubrique.rubrique_libelle,
          quittance_ligne_montant: round(item.location_ligne_montant, 2),
          quittance_ligne_quittance_id: quittance.quittance_id,
          quittance_ligne_rubrique_id: item.location_ligne_rubrique.rubrique_id,
        };
        this.props.saveQuittanceLigne(newQuittanceLigne);
      } else if (item.location_ligne_montant !== quittanceLigne.quittance_ligne_montant) {
        // update ligne quittance
        this.props.updateQuittanceLigne(
          {
            quittance_ligne_libelle: item.location_ligne_libelle
              ? item.location_ligne_libelle
              : item.location_ligne_rubrique.rubrique_libelle,
            quittance_ligne_montant: round(item.location_ligne_montant, 2),
            quittance_ligne_quantite: quittanceLigne.quittance_ligne_quantite,
            quittance_ligne_quittance_id: quittanceLigne.quittance_ligne_quittance_id,
          },
          quittanceLigne.quittance_ligne_id
        );
      }
      montant += round(item.location_ligne_montant, 2);
    });
    this.createEcriture(
      quittance,
      location.location_requete.requete_locataire.locataire_affectations[0]
        .locataire_affectation_compte_id,
      round(montant, 2),
      modification
    );
  };

  createEcritureDepenses = (idCompte) => {
    const ecritureLignes = [];
    const location = this.props.location;
    const proprietaire = location.location_lot.lot_proprietaire;
    this.state.depensesLignes.forEach((ligne) => {
      const ligneDebit = {
        ecriture_ligne_libelle: `${ligne.location_ligne_rubrique.rubrique_libelle} 
        (${location.location_requete.requete_locataire.locataire_personne.personne_nom}) 
        location du ${dayjs(location.location_date_debut).format("DD/MM/YYYY")} au ${dayjs(
          location.location_date_fin
        ).format("DD/MM/YYYY")}`,
        ecriture_ligne_montant_debit: round(ligne.location_ligne_montant, 2),
        ecriture_ligne_montant_credit: 0,
        ecriture_ligne_compte_id: idCompte,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      const ligneCredit = {
        ecriture_ligne_libelle: `${ligne.location_ligne_rubrique.rubrique_libelle}`,
        ecriture_ligne_montant_debit: 0,
        ecriture_ligne_montant_credit: round(ligne.location_ligne_montant, 2),
        ecriture_ligne_compte_id: ligne.location_ligne_rubrique.rubrique_compte?.compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
        ecriture_ligne_rubrique_id: ligne.location_ligne_rubrique.rubrique_id,
      };
      ecritureLignes.push(ligneDebit, ligneCredit);
    });
    const journal = this.props.journals.find((journal) => journal.journal_code === "30");
    const ecriture = {
      ecriture_journal_id: journal?.journal_id,
      ecriture_date_ecriture: dayjs(location.location_date_debut).format("DD/MM/YYYY"),
      ecriture_libelle: `Dépenses propriétaire (${proprietaire.proprietaire_personne.personne_nom} ${proprietaire.proprietaire_personne.personne_prenom})`,
      ecriture_action: `DP`,
      ecriture_ligne: ecritureLignes,
    };
    if (journal)
      this.props
        .saveEcriture(ecriture)
        .catch(() => toast.error("Erreur création écriture de dépenses !", { containerId: "A" }));
    else toast.error("Pas journal trouvé pour l'écriture de dépenses", { containerId: "A" });
  };

  createEcriture = (quittance, idCompte, montant, modification) => {
    const ecritureLignes = [];
    const location = this.props.location;
    const ligneDebit = {
      ecriture_ligne_libelle: `${modification ? "Modification " : ""}Contrat n°${
        location.location_id
      } du ${quittance.quittance_date_debut} au ${quittance.quittance_date_fin} (${
        location.location_requete.requete_locataire.locataire_personne.personne_nom
      })`,
      ecriture_ligne_montant_debit: montant,
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: idCompte,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    const ligneCredit = {
      ecriture_ligne_libelle: `${modification ? "Modification " : ""}Contrat n°${
        location.location_id
      } du ${quittance.quittance_date_debut} au ${quittance.quittance_date_fin} (${
        location.location_requete.requete_locataire.locataire_personne.personne_nom
      })`,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: montant,
      ecriture_ligne_compte_id: this.props.comptes.find(
        (compte) => compte.compte_compte_general === "480000"
      ).compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit, ligneCredit);
    const ecriture = {
      ecriture_journal_id: this.props.journals.find((journal) => journal.journal_code === "48")
        .journal_id,
      ecriture_date_ecriture: quittance.quittance_date_debut,
      ecriture_libelle: `${modification ? "Modification " : ""}Contrat n°${
        location.location_id
      } du ${quittance.quittance_date_debut} au ${quittance.quittance_date_fin} (${
        location.location_requete.requete_locataire.locataire_personne.personne_nom
      })`,
      ecriture_action: `QUI${quittance.quittance_id}`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecriture)
      .then(() => this.props.resync())
      .catch(() => toast.error("Erreur création écriture !", { containerId: "A" }));
  };

  createEcritureAnnulation = (ecriture) => {
    const ecritureLignes = [];
    ecriture.ecriture_ligne.forEach((ligne) => {
      const ligneAnnulation = {
        ecriture_ligne_libelle: "Annulation " + ligne.ecriture_ligne_libelle,
        ecriture_ligne_montant_debit: ligne.ecriture_ligne_montant_credit,
        ecriture_ligne_montant_credit: ligne.ecriture_ligne_montant_debit,
        ecriture_ligne_compte_id: ligne.ecriture_ligne_compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneAnnulation);
    });
    const ecritureAnnulation = {
      ecriture_journal_id: ecriture.ecriture_journal.journal_id,
      ecriture_date_ecriture: ecriture.ecriture_date_ecriture,
      ecriture_libelle: "Annulation " + ecriture.ecriture_libelle,
      ecriture_action: ecriture.ecriture_action,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecritureAnnulation)
      .catch(() => toast.error("Erreur création écriture d'annulation !", { containerId: "A" }));
  };

  createEcritureAnnulationTP = (ecriture, idCompte) => {
    const ecritureLignes = [];
    ecriture.ecriture_ligne.forEach((ligne) => {
      const ligneAnnulation = {
        ecriture_ligne_libelle: "Annulation " + ligne.ecriture_ligne_libelle,
        ecriture_ligne_montant_debit: ligne.ecriture_ligne_montant_credit,
        ecriture_ligne_montant_credit: ligne.ecriture_ligne_montant_debit,
        ecriture_ligne_compte_id:
          ligne.ecriture_ligne_compte_id === idCompte
            ? this.props.comptes.find((compte) => compte.compte_compte_general === "467001")
                .compte_id
            : ligne.ecriture_ligne_compte_id,
        ecriture_ligne_locataire_code: "",
        ecriture_ligne_en_reddition: false,
      };
      ecritureLignes.push(ligneAnnulation);
    });
    const ecritureAnnulation = {
      ecriture_journal_id: ecriture.ecriture_journal.journal_id,
      ecriture_date_ecriture: ecriture.ecriture_date_ecriture,
      ecriture_libelle: "Annulation " + ecriture.ecriture_libelle,
      ecriture_action: ecriture.ecriture_action,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecritureAnnulation)
      .catch(() => toast.error("Erreur création écriture d'annulation !", { containerId: "A" }));
  };

  createEcritureTPLocataire = (reglement) => {
    const ecritureLignes = [];
    const location = this.props.location;
    const ecritureDebit = {
      ecriture_ligne_libelle: "Trop perçu locataire",
      ecriture_ligne_montant_debit: parseFloat(reglement.reglement_montant),
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: reglement.reglement_journal.journal_compte.compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ecritureDebit);
    const ecritureCredit = {
      ecriture_ligne_libelle: "Trop perçu locataire",
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: parseFloat(reglement.reglement_montant),
      ecriture_ligne_compte_id: this.props.comptes.find(
        (compte) => compte.compte_compte_general === "467001"
      ).compte_id,
      ecriture_ligne_locataire_code: `${location.location_requete.requete_locataire.locataire_id}`,
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ecritureCredit);
    const ecritureTP = {
      ecriture_journal_id: reglement.reglement_journal.journal_id,
      ecriture_date_ecriture: dayjs().format("DD/MM/YYYY"),
      ecriture_libelle: "Trop perçu locataire",
      ecriture_action: "TP",
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecritureTP)
      .catch(() => toast.error("Erreur création écriture de trop perçu !", { containerId: "A" }));
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  setBackgroundColor = (item) => {
    if (this.state.lignes.includes(item.location_ligne_id)) {
      return "editable-row row-edited-bg";
    } else {
      return "editable-row";
    }
  };

  formatDate = (d) => {
    let date = null;
    if (d) date = dayjs(d, "YYYY-MM-DD");
    return date;
  };

  onChangeCompta = (checked) => {
    this.setState({ compta: checked });
  };

  render() {
    const { home, isArchive, parametre, location, templatesLangues, typesLots } = this.props;
    const {
      rubriques,
      modalRubrique,
      modalTarif,
      modalPlateforme,
      modalReglement,
      modalDepenses,
      modalProprietaire,
      depensesLignes,
    } = this.state;

    if (location.location_ligne.length > 0) {
      location.location_ligne.sort(
        (a, b) => a.location_ligne_rubrique.rubrique_id - b.location_ligne_rubrique.rubrique_id
      );
    }

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    let columnsA = [
      {
        title: "Code",
        dataIndex: "location_ligne_rubrique",
        key: "location_ligne_rubrique",
        width: "10%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          parseInt(a.location_ligne_rubrique.rubrique_code) -
          parseInt(b.location_ligne_rubrique.rubrique_code),
        defaultSortOrder: "ascend",
        render: (rubrique) => rubrique.rubrique_code,
        depenses: true,
      },
      {
        title: "Libellé",
        dataIndex: "location_ligne_libelle",
        key: "location_ligne_libelle",
        width: "30%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.location_ligne_rubrique.rubrique_libelle.localeCompare(
            b.location_ligne_rubrique.rubrique_libelle
          ),
        render: (libelle, location_ligne) =>
          libelle ? libelle : location_ligne.location_ligne_rubrique.rubrique_libelle,
        editable: !isArchive,
        depenses: true,
      },
      {
        title: "Type",
        dataIndex: "location_ligne_rubrique",
        key: "location_ligne_rubrique",
        width: "5%",
        render: (rubrique) => rubrique.rubrique_type,
        depenses: true,
      },
      {
        title: "Montant",
        dataIndex: "location_ligne_montant",
        key: "location_ligne_montant",
        width: "15%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => a.location_ligne_montant - b.location_ligne_montant,
        render: (location_ligne_montant) => round(location_ligne_montant, 2),
        editable: !isArchive,
        depenses: true,
      },
      {
        title: "% Acompte",
        dataIndex: "location_ligne_pourcentage_acompte",
        key: "location_ligne_pourcentage_acompte",
        width: "10%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) =>
          a.location_ligne_pourcentage_acompte - b.location_ligne_pourcentage_acompte,
        editable: !isArchive,
        depenses: false,
      },
      {
        title: "Solde",
        dataIndex: "location_ligne_montant",
        key: "location_ligne_montant",
        width: "15%",
        render: (location_ligne_montant, location_ligne) =>
          round(
            location_ligne_montant -
              (
                (location_ligne_montant / 100) *
                location_ligne.location_ligne_pourcentage_acompte
              ).toFixed(2),
            2
          ),
        depenses: false,
      },
      {
        title: "Permanente",
        dataIndex: "location_ligne_permanente",
        key: "location_ligne_permanente",
        width: "5%",
        render: (location_ligne_permanente, location_ligne) => (
          <Checkbox
            checked={location_ligne_permanente}
            onChange={(e) => this.onCheckPermanente(e, location_ligne)}
          ></Checkbox>
        ),
        depenses: false,
      },
      {
        title: "Date suppression",
        dataIndex: "location_ligne_date_suppression",
        key: "location_ligne_date_suppression",
        width: "10%",
        render: (location_ligne_date_suppression, location_ligne) =>
          location_ligne_date_suppression
            ? !location_ligne.location_ligne_permanente && (
                <DatePicker
                  format={dateFormat}
                  defaultValue={dayjs(location_ligne_date_suppression, "YYYY-MM-DD")}
                  onChange={(e) => this.onChangeDateSuppression(e, location_ligne)}
                />
              )
            : !location_ligne.location_ligne_permanente && (
                <DatePicker
                  format={dateFormat}
                  onChange={(e) => this.onChangeDateSuppression(e, location_ligne)}
                />
              ),
        depenses: false,
      },
      {
        title: "Supprimer",
        dataIndex: "location_ligne_id",
        key: "location_ligne_id",
        render: (location_ligne_id) => (
          <Button
            icon={<DeleteOutlined />}
            shape="circle"
            onClick={() => this.deleteLocationLigne(location_ligne_id)}
          />
        ),
        width: "5%",
        depenses: false,
      },
    ];

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columnDepenses = columnsA.filter((col) => col.depenses);

    const columns = columnsA.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    const footerTarif = (
      <Button type="primary" onClick={this.resetLignesTarif}>
        Valider
      </Button>
    );

    return (
      <>
        <Modal
          footer={null}
          width="600px"
          title="Rubriques"
          open={modalRubrique}
          onCancel={this.closeModalRubrique}
        >
          <div>
            <Row>
              <Col span={24}>
                <Checkbox onChange={(value) => this.getRubriques(value)} style={{ float: "right" }}>
                  Voir toutes
                </Checkbox>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  rowKey="rubrique_code"
                  dataSource={rubriques}
                >
                  <Column title="Code" dataIndex="rubrique_code" key="code" />
                  <Column
                    title="Libelle"
                    dataIndex="rubrique_libelle"
                    key="libelle"
                    {...this.getColumnSearchProps("rubrique_libelle")}
                  />
                  <Column title="Type" dataIndex="rubrique_type" key="type" />
                  <Column title="Signe" dataIndex="rubrique_signe" key="signe" />
                </Table>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <Button
                  type="primary"
                  onClick={this.handleSubmitAddRubriques}
                  style={{ float: "right" }}
                >
                  Valider
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          footer={footerTarif}
          width="1600px"
          title="Tarif"
          open={modalTarif}
          onCancel={this.closeModalTarif}
        >
          <FormTarifLot
            home={false}
            inModal={true}
            lot={this.props.lots.find(
              (lot) => lot.lot_id === this.props.location.location_lot.lot_id
            )}
            lots={this.props.lots}
            rubriques={this.props.rubriques}
            saveLigneMatrice={this.props.saveLigneMatrice}
            updateLigneMatrice={this.props.updateLigneMatrice}
            deleteLigneMatrice={this.props.deleteLigneMatrice}
            savePrixBase={this.props.savePrixBase}
            updatePrixBase={this.props.updatePrixBase}
            deletePrixBase={this.props.deletePrixBase}
            // saveNuite={this.props.saveNuite}
            // updateNuite={this.props.updateNuite}
            // deleteNuite={this.props.deleteNuite}
          />
        </Modal>
        <Modal
          footer={null}
          width="800px"
          title="Plateformes"
          open={modalPlateforme}
          onCancel={this.closeModalPlateforme}
        >
          <FormCalculPlateforme
            location={location}
            lignes={location.location_ligne}
            rubriques={this.props.rubriques}
            saveLocationLigne={this.props.saveLocationLigne}
            updateLocationLigne={this.props.updateLocationLigne}
            closeModalPlateforme={this.closeModalPlateforme}
          />
        </Modal>
        <Modal
          footer={null}
          width="600px"
          title="Avertissement"
          open={modalReglement}
          onCancel={this.closeModalReglement}
        >
          <div>
            <Row>
              <span>
                Attention un encaissement existe sur cette location, confirmez vous l'annulation du
                contrat ?
              </span>
            </Row>
            <Row style={{ marginTop: "10px" }}>
              <Button type="warning" onClick={this.closeModalReglement} style={{ float: "right" }}>
                Annuler
              </Button>
              <Button
                type="primary"
                onClick={() => this.deleteContrat(true)}
                style={{ marginLeft: 20, float: "right" }}
              >
                Valider
              </Button>
            </Row>
          </div>
        </Modal>
        <Modal
          footer={null}
          width="800px"
          title="Dépenses propriétaire"
          open={modalDepenses}
          onCancel={this.closeModalDepenses}
        >
          <Row style={{ marginBottom: 10 }}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[]}
              autoEscape
              textToHighlight={`Souhaitez vous générer la comptabilité liée à ces dépenses ?`}
            />
          </Row>
          <Table
            size={home ? "small" : "middle"}
            dataSource={depensesLignes}
            components={components}
            columns={columnDepenses}
            pagination={false}
            style={{ marginTop: 10 }}
            locale={{ emptyText: "Aucune ligne" }}
            rowKey="location_ligne_id"
            summary={(pageData) => {
              let total = 0;

              pageData.forEach(({ location_ligne_montant }) => {
                total += parseFloat(location_ligne_montant);
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{round(total, 2)} €</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
          <Row style={{ marginTop: "10px" }}>
            <Button
              type="warning"
              onClick={() => this.handleDepenses(false)}
              style={{ float: "right" }}
            >
              Non
            </Button>
            <Button
              type="primary"
              onClick={() => this.handleDepenses(true)}
              style={{ marginLeft: 20, float: "right" }}
            >
              Oui
            </Button>
          </Row>
        </Modal>
        <Modal
          footer={null}
          width="800px"
          title="Notification propiétaire"
          open={modalProprietaire}
          onCancel={this.closeModalProprietaire}
        >
          <>
            <Row style={{ marginBottom: 10 }}>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[
                  this.props.location.location_lot.lot_proprietaire.proprietaire_personne
                    .personne_email1,
                ]}
                autoEscape
                textToHighlight={`Voulez-vous envoyer une notification au propriétaire 
                ${this.props.location.location_lot.lot_proprietaire.proprietaire_personne.personne_nom} 
                ${this.props.location.location_lot.lot_proprietaire.proprietaire_personne.personne_prenom} 
                à l'adresse ${this.props.location.location_lot.lot_proprietaire.proprietaire_personne.personne_email1}?`}
              />
            </Row>
            <ViewMail
              location={location}
              templatesLangues={templatesLangues}
              typesLots={typesLots}
              setNotificationProprietaire={this.setNotificationProprietaire}
            />
            <Row style={{ marginTop: "10px" }}>
              <Button
                type="warning"
                onClick={() => this.prepareContrat(false)}
                style={{ float: "right" }}
              >
                Non
              </Button>
              <Button
                type="primary"
                onClick={() => this.prepareContrat(true)}
                style={{ marginLeft: 20, float: "right" }}
              >
                Oui
              </Button>
            </Row>
          </>
        </Modal>
        <Row>
          <Divider orientation="left">Tarif de la location</Divider>
          <Col span={24}>
            {location && (
              <Row>
                <Col span={12}>
                  <Button type="primary" disabled={isArchive} onClick={this.openModalRubrique}>
                    Ajout Rubrique
                  </Button>
                  <Button
                    type="primary"
                    disabled={isArchive}
                    style={{ marginLeft: 10 }}
                    onClick={this.openModalTarif}
                  >
                    Modifier tarif du lot
                  </Button>
                  <Button
                    type="primary"
                    disabled={isArchive}
                    style={{ marginLeft: 10 }}
                    onClick={this.openModalPlateforme}
                  >
                    Calcul plateformes
                  </Button>
                </Col>
                <Col span={4} offset={6}>
                  <span>Générer comptabilité: </span>
                  <Switch
                    defaultChecked
                    onChange={this.onChangeCompta}
                    style={{ float: "right" }}
                  />
                </Col>
              </Row>
            )}

            {location && (
              <Table
                size={home ? "small" : "middle"}
                dataSource={location.location_ligne}
                components={components}
                columns={columns}
                pagination={false}
                style={{ marginTop: 10 }}
                rowClassName={(record) => this.setBackgroundColor(record)}
                locale={{ emptyText: "Aucune ligne" }}
                rowKey="location_ligne_id"
                summary={(pageData) => {
                  let total = 0;
                  let totalPermanente = 0;
                  let totalAcompte = 0;
                  let totalSolde = 0;

                  pageData.forEach(
                    ({
                      location_ligne_montant,
                      location_ligne_permanente,
                      location_ligne_pourcentage_acompte,
                      location_ligne_rubrique,
                    }) => {
                      if (location_ligne_rubrique.rubrique_signe === "+")
                        total += parseFloat(location_ligne_montant);
                      else if (location_ligne_rubrique.rubrique_signe === "-")
                        total -= parseFloat(location_ligne_montant);
                      if (location_ligne_rubrique.rubrique_signe === "+")
                        totalAcompte += parseFloat(
                          location_ligne_montant * (location_ligne_pourcentage_acompte / 100)
                        );
                      if (location_ligne_rubrique.rubrique_signe === "-")
                        totalAcompte -= parseFloat(
                          location_ligne_montant * (location_ligne_pourcentage_acompte / 100)
                        );
                      if (location_ligne_permanente) {
                        if (location_ligne_rubrique.rubrique_signe === "+")
                          totalPermanente += parseFloat(location_ligne_montant);
                        if (location_ligne_rubrique.rubrique_signe === "-")
                          totalPermanente -= parseFloat(location_ligne_montant);
                      }
                    }
                  );

                  totalSolde = total - Math.round(totalAcompte, 0);

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text>{round(total, 2)} €</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text>{Math.round(totalAcompte, 0)} €</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text>{round(totalSolde, 2)} €</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>{round(totalPermanente, 2)} €</Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          {location && location.location_etat === "o" ? (
                            <>
                              <Popconfirm
                                title={`Confirmez vous la création du contrat ?`}
                                onConfirm={() =>
                                  parametre.parametre_depenses_proprietaire
                                    ? this.prepareDepenses(location)
                                    : templatesLangues.find(
                                        (item) =>
                                          item.template_langue_template.template_notification
                                      )
                                    ? this.openModalProprietaire()
                                    : this.prepareContrat(false)
                                }
                              >
                                <Button type="primary">Créer Contrat</Button>
                              </Popconfirm>
                              <br />
                              <Popconfirm
                                title="Confirmez vous la suppression de l'option ?"
                                onConfirm={() => this.deleteOption()}
                              >
                                <Button type="primary" style={{ marginTop: 10 }} danger>
                                  Enlever Option
                                </Button>
                              </Popconfirm>
                            </>
                          ) : (
                            <>
                              <Popconfirm
                                title="Confirmez vous la suppression du contrat ?"
                                onConfirm={() => this.handleDeleteContrat()}
                              >
                                <Button type="primary" style={{ marginTop: 10 }} danger>
                                  Supprimer Contrat
                                </Button>
                              </Popconfirm>
                            </>
                          )}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default FormTarif;
