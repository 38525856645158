import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { CSVLink } from "react-csv";
import { headers } from "./headers";
import VirtualTable from "../VirtualTable";

import {
  Row,
  Col,
  Form,
  Table,
  Button,
  Input,
  Space,
  Select,
  DatePicker,
  Spin,
  Radio,
  Typography,
  Modal,
  Checkbox,
} from "antd";

import { EyeOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import dayjs from "dayjs";

import FloatLabel from "../FloatLabel";
import GrandLivrePDF from "../Pdf/Comptabilite/GrandLivrePDF";
import Operation from "../../containers/Comptabilite/Operation";
import ComptePDF from "../Pdf/Comptabilite/ComptePDF";
import FormItem from "antd/lib/form/FormItem";

import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";

const dateFormat = "DD/MM/YYYY";

const { Text } = Typography;
const { Option, OptGroup } = Select;

class FormGrandLivre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      refresh: false,
      idFirstCompte: null,
      idLastCompte: null,
      ecritureState: null,
      lignesEcritures: [],
      modalEcriture: false,
      modalPdf: false,
      searchText: "",
      searchedColumn: "",
      societe: "",
      compteCentralisateur: null,
      compteDu: null,
      compteAu: null,
      dateDu: null,
      dateAu: dayjs(),
      sortedInfo: null,
      soldeEvolutif: false,
      soldeReport: false,
      ecrituresPointees: true,
      tri: 1,
    };
  }

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.props.grandLivre
      ? this.initComptesAuxiliaire(this.props.comptes.data, this.state.compteCentralisateur)
      : this.initSociete();
  }

  init = (value, debit, credit) => {
    let lignesEcritures = [];
    let lignesCentralisateurs = [];
    let ecrituresFiltered = this.props.ecritures;

    if (this.state.dateDu)
      ecrituresFiltered = ecrituresFiltered.filter((e) =>
        dayjs(e.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrAfter(this.state.dateDu)
      );
    if (this.state.dateAu)
      ecrituresFiltered = ecrituresFiltered.filter((e) =>
        dayjs(e.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(this.state.dateAu)
      );

    ecrituresFiltered &&
      ecrituresFiltered.forEach((ecriture) => {
        let lignes = [];
        if (this.state.compteCentralisateur) {
          lignes = ecriture.ecriture_ligne.filter(
            (e) =>
              e.ecriture_ligne_compte.compte_compte_auxiliaire >= this.state.compteDu &&
              e.ecriture_ligne_compte.compte_compte_auxiliaire <= this.state.compteAu &&
              e.ecriture_ligne_compte.compte_compte_general ===
                this.props.comptes.data.find(
                  (compte) => compte.compte_id === this.state.compteCentralisateur
                ).compte_compte_general
          );
        } else {
          lignes = ecriture.ecriture_ligne.filter(
            (e) =>
              e.ecriture_ligne_compte.compte_compte_general >= this.state.compteDu &&
              e.ecriture_ligne_compte.compte_compte_general <= this.state.compteAu &&
              e.ecriture_ligne_compte.compte_type_compte !== "A"
          );
        }

        lignes &&
          lignes.forEach((ligne) => {
            ligne.ecriture_id = ecriture.ecriture_id;
            ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
            ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
            if (this.isReglementReddition(ecriture)) ligne.ecriture_ligne_en_reddition = true;
            lignesEcritures.push(ligne);
          });
      });

    // Comptes centralisateurs
    if (this.props.grandLivre && !this.state.compteCentralisateur) {
      this.props.comptes.data
        .filter((item) => item.compte_type_compte === "C")
        .forEach((compte) => {
          let totalDebit = 0;
          let totalCredit = 0;

          ecrituresFiltered &&
            ecrituresFiltered.forEach((ecriture) => {
              let lignesCentralisateur = ecriture.ecriture_ligne.filter(
                (e) =>
                  e.ecriture_ligne_compte.compte_compte_general === compte.compte_compte_general
              );

              lignesCentralisateur.forEach((ligne) => {
                totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
                totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
              });
            });

          const ligneCentralisateur = {
            ecriture_ligne_id: compte.compte_compte_general,
            ecriture_id: "",
            ecriture_date_ecriture: "",
            ecriture_ligne_compte: compte,
            ecriture_ligne_libelle: compte.compte_libelle,
            ecriture_journal_code: "",
            ecriture_ligne_montant_debit: totalDebit,
            ecriture_ligne_montant_credit: totalCredit,
            solde: "",
          };
          lignesCentralisateurs.push(ligneCentralisateur);
        });
    }

    lignesEcritures.sort(
      (a, b) =>
        dayjs(a.ecriture_date_ecriture, "DD/MM/YYYY").unix() -
        dayjs(b.ecriture_date_ecriture, "DD/MM/YYYY").unix()
    );

    const comptesFiltered = this.filterByCompte(
      this.props.comptes.data,
      this.state.compteCentralisateur
    );
    const compteDu =
      comptesFiltered.length > 0
        ? this.state.compteCentralisateur
          ? comptesFiltered[0].compte_compte_auxiliaire
          : comptesFiltered[0].compte_compte_general
        : null;
    const compteAu =
      comptesFiltered.length > 0
        ? this.state.compteCentralisateur
          ? comptesFiltered[comptesFiltered.length - 1].compte_compte_auxiliaire
          : comptesFiltered[comptesFiltered.length - 1].compte_compte_general
        : null;
    if (compteDu === this.state.compteDu && compteAu === this.state.compteAu)
      lignesEcritures = lignesCentralisateurs.concat(lignesEcritures);

    let solde = 0;
    lignesEcritures = lignesEcritures
      // .sort((a, b) => a.ecriture_ligne_id - b.ecriture_ligne_id)
      .map((ligne) => {
        solde += parseFloat(
          ligne.ecriture_ligne_montant_debit - ligne.ecriture_ligne_montant_credit
        );
        ligne.solde = solde;
        return ligne;
      });

    if (this.state.soldeReport || (value && value.target.checked === true)) {
      const compteReport = this.props.comptes.data.find((compte) =>
        this.state.compteCentralisateur
          ? compte.compte_id === this.state.compteCentralisateur
          : compte.compte_compte_general === this.state.compteDu
      );
      let solde = parseFloat(debit) - parseFloat(credit);

      const ligneReport = {
        ecriture_ligne_id: 0,
        ecriture_ligne_libelle: "SOLDE REPORT",
        ecriture_ligne_montant_debit: debit,
        ecriture_ligne_montant_credit: credit,
        ecriture_ligne_compte: compteReport,
        solde: solde,
      };
      lignesEcritures.push(ligneReport);

      lignesEcritures = lignesEcritures.sort((a, b) => a.ecriture_ligne_id - b.ecriture_ligne_id);
    }

    if (!this.state.ecrituresPointees)
      lignesEcritures = lignesEcritures.filter(
        (ligne) =>
          ligne.ecriture_ligne_lettrage === "" ||
          ligne.ecriture_ligne_lettrage === undefined ||
          ligne.ecriture_ligne_lettrage === null ||
          ligne.ecriture_ligne_id === 0
      );

    this.setState({ lignesEcritures, refresh: false });
  };

  isReglementReddition = (ecriture) => {
    const match = ecriture.ecriture_action?.match(/^REG(\d+)$/);
    const result = match ? match[1] : null; // Si la correspondance est trouvée, renvoie le numéro, sinon renvoie null
    return this.props.reglements.some(
      (reg) => reg.reglement_id === parseInt(result) && reg.reglement_reddition
    );
  };

  initComptesAuxiliaire = (comptes, compteCentralisateur) => {
    const comptesFiltered = this.filterByCompte(comptes, compteCentralisateur);

    this.formRef.current.setFieldsValue({
      societe_id: this.props.societes[0].societe_raison_sociale,
      compteDu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[0].compte_compte_auxiliaire
            : comptesFiltered[0].compte_compte_general
          : null,
      compteAu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[comptesFiltered.length - 1].compte_compte_auxiliaire
            : comptesFiltered[comptesFiltered.length - 1].compte_compte_general
          : null,
      ecrituresPointees: true,
    });
    this.setState({
      societe: this.props.societes[0],
      compteDu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[0].compte_compte_auxiliaire
            : comptesFiltered[0].compte_compte_general
          : null,
      compteAu:
        comptesFiltered.length > 0
          ? compteCentralisateur
            ? comptesFiltered[comptesFiltered.length - 1].compte_compte_auxiliaire
            : comptesFiltered[comptesFiltered.length - 1].compte_compte_general
          : null,
    });
  };

  initSociete = () => {
    this.formRef.current.setFieldsValue({
      societe_id: this.props.societes[0].societe_raison_sociale,
      ecrituresPointees: true,
    });
    this.setState({ societe: this.props.societes[0] });
  };

  filterByCompte = (comptes, compteCentralisateur) => {
    if (comptes === undefined) return [];
    let comptesFiltered = [];
    compteCentralisateur
      ? (comptesFiltered = comptes.filter(
          (item) =>
            item.compte_compte_general ===
              comptes.find((item) => item.compte_id === compteCentralisateur)
                .compte_compte_general && item.compte_type_compte === "A"
        ))
      : (comptesFiltered = comptes.filter((item) => item.compte_type_compte === "G"));
    return compteCentralisateur
      ? comptesFiltered.sort((a, b) =>
          a.compte_compte_auxiliaire.localeCompare(b.compte_compte_auxiliaire)
        )
      : comptesFiltered.sort((a, b) =>
          a.compte_compte_general.localeCompare(b.compte_compte_general)
        );
  };

  onChangeCompteCentralisateur = (idCompte) => {
    this.formRef.current.setFieldsValue({
      compteDu: null,
      compteAu: null,
    });
    this.initComptesAuxiliaire(this.props.comptes.data, idCompte);
    this.setState({ compteCentralisateur: idCompte });
  };

  onSelectCompteDu = (idCompte) => {
    this.formRef.current.setFieldsValue({
      compteAu: idCompte,
    });
    this.setState({ compteDu: idCompte, compteAu: idCompte }, () => this.onRefresh());
  };

  onSelectCompteAu = (idCompte) => {
    this.setState({ compteAu: idCompte });
  };

  onChangeDateDu = (dateDu) => {
    if (dateDu) {
      this.formRef.current.setFieldsValue({
        soldeReport: this.props.grandLivre ? false : true,
      });
      this.setState({ dateDu, soldeReport: this.props.grandLivre ? false : true });
    } else {
      this.formRef.current.setFieldsValue({
        soldeReport: false,
      });
      this.setState({ dateDu, soldeReport: false });
    }
  };

  onChangeDateAu = (dateAu) => {
    this.setState({ dateAu });
  };

  onRefresh = () => {
    if (this.state.soldeReport === true) {
      this.onChangeSoldeReport();
    } else {
      this.setState({ refresh: true }, () => this.init());
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  createPdf = () => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <GrandLivrePDF
        lignesEcritures={this.state.lignesEcritures}
        dateDu={this.state.dateDu}
        dateAu={this.state.dateAu}
        compteCentralisateur={this.state.compteCentralisateur}
        compteDu={this.state.compteDu}
        compteAu={this.state.compteAu}
        comptes={this.props.comptes.data}
        soldeEvolutif={this.state.soldeEvolutif}
        tri={this.state.tri}
      />
    );
    const pdf = {
      titre: "Grand Livre",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalPdf: true });
        }.bind(this)
      );
  };

  createPdf2 = () => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <ComptePDF
        lignesEcritures={this.state.lignesEcritures}
        dateDu={this.state.dateDu}
        dateAu={this.state.dateAu}
        compteCentralisateur={this.state.compteCentralisateur}
        compteDu={this.state.compteDu}
        compteAu={this.state.compteAu}
        comptes={this.props.comptes.data}
        soldeEvolutif={this.state.soldeEvolutif}
      />
    );
    const pdf = {
      titre: "Compte",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalPdf: true });
        }.bind(this)
      );
  };

  closeModal = () => {
    this.setState({ modalPdf: false });
  };

  handleClickVisualisation = (id) => {
    const ecriture = this.props.ecritures.find((ecriture) => ecriture.ecriture_id === id);
    this.setState({ ecritureState: ecriture, modalEcriture: true });
  };

  closeModalEcriture = () => {
    this.setState({ modalEcriture: false });
  };

  onChangeSoldeEvolutif = () => {
    let sortedInfo = this.state.sortedInfo;
    if (!this.state.soldeEvolutif) {
      sortedInfo = {
        order: "ascend",
        columnKey: "ecriture_date_ecriture",
      };
    }
    this.setState({ soldeEvolutif: !this.state.soldeEvolutif, sortedInfo });
  };

  onChangeSoldeReport = (value) => {
    let totalDebit = 0;
    let totalCredit = 0;

    if (this.state.dateDu) {
      let ecrituresSolde = this.props.ecritures.filter((e) =>
        dayjs(e.ecriture_date_ecriture, "DD/MM/YYYY").isBefore(this.state.dateDu)
      );

      ecrituresSolde &&
        ecrituresSolde.forEach((ecriture) => {
          let lignes = [];

          lignes = ecriture.ecriture_ligne.filter(
            (e) =>
              e.ecriture_ligne_compte.compte_compte_general >= this.state.compteDu &&
              e.ecriture_ligne_compte.compte_compte_general <= this.state.compteAu &&
              e.ecriture_ligne_compte.compte_type_compte !== "A"
          );

          lignes &&
            lignes.forEach((ligne) => {
              totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
              totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
            });
        });
    }
    this.init(value, totalDebit, totalCredit);
    if (!value || value.target.checked === false) {
      this.setState({ soldeReport: false });
    }
  };

  onChangeEcrituresPointees = () => {
    this.setState({ ecrituresPointees: !this.state.ecrituresPointees }, () => this.init());
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  onChangeTri = (e) => {
    this.setState({ tri: e.target.value });
  };

  render() {
    const {
      loading,
      refresh,
      ecritureState,
      lignesEcritures,
      modalEcriture,
      modalPdf,
      idPdf,
      societe,
      compteCentralisateur,
      compteDu,
      compteAu,
      dateDu,
      dateAu,
      sortedInfo,
      soldeEvolutif,
      tri,
    } = this.state;
    const { societes, comptes, grandLivre } = this.props;

    let columns = [
      {
        title: "Compte Général",
        dataIndex: "ecriture_ligne_compte",
        key: "ecriture_ligne_compte",
        render: (compte) => (compte ? compte.compte_compte_general : ""),
        align: "right",
        width: 150,
      },
      {
        title: "Compte Auxliaire",
        dataIndex: "ecriture_ligne_compte",
        key: "ecriture_ligne_compte",
        render: (compte) => (compte ? compte.compte_compte_auxiliaire : ""),
        align: "right",
        width: 150,
      },
      {
        title: "N°Ecriture",
        dataIndex: "ecriture_id",
        key: "ecriture_id",
        align: "right",
        width: 100,
      },
      {
        title: "Date",
        dataIndex: "ecriture_date_ecriture",
        key: "ecriture_date_ecriture",
        sorter: (a, b) =>
          dayjs(a.ecriture_date_ecriture, "DD/MM/YYYY").unix() -
          dayjs(b.ecriture_date_ecriture, "DD/MM/YYYY").unix(),
        sortOrder:
          sortedInfo &&
          !soldeEvolutif &&
          sortedInfo.columnKey === "ecriture_date_ecriture" &&
          sortedInfo.order,
        width: 100,
      },
      {
        title: "Libellé",
        dataIndex: "ecriture_ligne_libelle",
        key: "ecriture_ligne_libelle",
        ...this.getColumnSearchProps("ecriture_ligne_libelle"),
      },
      {
        title: "Jrn",
        dataIndex: "ecriture_journal_code",
        key: "ecriture_journal_code",
        align: "right",
        width: 100,
      },
      {
        title: "Montant Débit",
        dataIndex: "ecriture_ligne_montant_debit",
        key: "ecriture_ligne_montant_debit",
        ...this.getColumnSearchProps("ecriture_ligne_montant_debit"),
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        ...(!soldeEvolutif && {
          sorter: (a, b) => a.ecriture_ligne_montant_debit - b.ecriture_ligne_montant_debit,
        }),
        sortOrder:
          sortedInfo && sortedInfo.columnKey === "ecriture_ligne_montant_debit" && sortedInfo.order,
        width: 150,
      },
      {
        title: "Montant Crédit",
        dataIndex: "ecriture_ligne_montant_credit",
        key: "ecriture_ligne_montant_credit",
        ...this.getColumnSearchProps("ecriture_ligne_montant_credit"),
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        ...(!soldeEvolutif && {
          sorter: (a, b) => a.ecriture_ligne_montant_debit - b.ecriture_ligne_montant_debit,
        }),
        sortOrder:
          sortedInfo &&
          sortedInfo.columnKey === "ecriture_ligne_montant_credit" &&
          sortedInfo.order,
        width: 150,
      },
      {
        title: "Solde évolutif",
        dataIndex: "solde",
        key: "solde",
        render: (solde) =>
          soldeEvolutif ? (
            parseFloat(solde).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })
          ) : (
            <></>
          ),
        align: "right",
        width: 120,
      },
      {
        title: "Pointage",
        dataIndex: "ecriture_ligne_lettrage",
        key: "ecriture_ligne_lettrage",
        ...this.getColumnSearchProps("ecriture_ligne_lettrage"),
        ...(!soldeEvolutif && {
          sorter: (a, b) =>
            a.ecriture_ligne_lettrage &&
            a.ecriture_ligne_lettrage.localeCompare(b.ecriture_ligne_lettrage),
        }),
        sortOrder:
          sortedInfo && sortedInfo.columnKey === "ecriture_ligne_lettrage" && sortedInfo.order,
        align: "right",
        width: 120,
      },
      {
        title: "Reddition",
        dataIndex: "ecriture_ligne_en_reddition",
        key: "ecriture_ligne_en_reddition",
        render: (reddition) => (reddition ? "*" : ""),
        align: "right",
        width: 120,
      },
      {
        title: "Visualisation",
        dataIndex: "ecriture_id",
        key: "ecriture_id",
        render: (ecriture_id) => (
          <Button onClick={() => this.handleClickVisualisation(ecriture_id)}>
            <EyeOutlined />
          </Button>
        ),
        width: 150,
      },
    ];

    return (
      <Spin spinning={loading} size="large" tip="Chargement...">
        <Modal footer={null} title="Edition" open={modalPdf} onCancel={this.closeModal}>
          {idPdf && (
            <Button type="text" onClick={() => openPdf(idPdf)}>
              Document PDF
            </Button>
          )}
        </Modal>

        <Modal
          footer={null}
          title="Visualisation"
          width={1000}
          open={modalEcriture}
          onCancel={this.closeModalEcriture}
        >
          {ecritureState && (
            <Operation edit={false} readOnly={true} ecritureProps={ecritureState} />
          )}
        </Modal>

        <Form ref={this.formRef} size="large" style={{ padding: 20 }}>
          <Row gutter={12}>
            <Col span={12}>
              <FloatLabel label="Société" name="societe" value={societe}>
                <Form.Item name="societe_id">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    optionLabelProp="label"
                    onChange={(id) => this.setState({ societe: id })}
                  >
                    <OptGroup label="Société">
                      {societes &&
                        societes.map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.societe_id}
                              label={item.societe_raison_sociale}
                            >
                              <Row>
                                <Col sm={8}>{item.societe_raison_sociale}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={12}>
              {grandLivre ? (
                <>
                  <Button
                    type="primary"
                    style={{ float: "right", marginLeft: 10 }}
                    onClick={() => this.createPdf()}
                  >
                    Impression comptes
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="primary"
                    style={{ float: "right", marginLeft: 10 }}
                    onClick={() => this.createPdf2()}
                  >
                    Impression comptes
                  </Button>
                </>
              )}
              <Button style={{ float: "right" }}>
                <CSVLink
                  filename={"export.csv"}
                  data={lignesEcritures}
                  headers={headers()}
                  separator={";"}
                  // style={{ float: "right" }}
                >
                  Export CSV
                </CSVLink>
              </Button>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={12}>
              {comptes && grandLivre && (
                <FloatLabel
                  label="Compte centralisateur"
                  name="compteCentralisateur"
                  value={compteCentralisateur}
                >
                  <Form.Item name="compte_centralisateur">
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      optionLabelProp="label"
                      onChange={(value) => this.onChangeCompteCentralisateur(value)}
                    >
                      <OptGroup label="Compte">
                        {comptes.data &&
                          comptes.data
                            .filter((item) => item.compte_type_compte === "C")
                            .map((item, i) => (
                              <Option
                                key={i}
                                value={item.compte_id}
                                label={`${item.compte_compte_general} - ${item.compte_libelle}`}
                              >
                                <Row>
                                  <Col
                                    sm={8}
                                  >{`${item.compte_compte_general} - ${item.compte_libelle}`}</Col>
                                </Row>
                              </Option>
                            ))}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                </FloatLabel>
              )}

              <Row gutter={12}>
                <Col span={12}>
                  {comptes && (
                    <FloatLabel label="Compte" name="compteDu" value={compteDu}>
                      <Form.Item name="compteDu">
                        <Select
                          showSearch
                          optionFilterProp="label"
                          optionLabelProp="label"
                          onSelect={(value) => this.onSelectCompteDu(value)}
                        >
                          <OptGroup label="Compte">
                            {this.filterByCompte(comptes.data, compteCentralisateur).map(
                              (item, i) => (
                                <Option
                                  key={i}
                                  value={
                                    compteCentralisateur
                                      ? item.compte_compte_auxiliaire
                                      : item.compte_compte_general
                                  }
                                  label={`${
                                    compteCentralisateur
                                      ? item.compte_compte_auxiliaire
                                      : item.compte_compte_general
                                  } - ${item.compte_libelle}`}
                                >
                                  <Row>
                                    <Col sm={8}>{`${
                                      compteCentralisateur
                                        ? item.compte_compte_auxiliaire
                                        : item.compte_compte_general
                                    } - ${item.compte_libelle}`}</Col>
                                  </Row>
                                </Option>
                              )
                            )}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    </FloatLabel>
                  )}
                </Col>

                <Col span={12}>
                  {comptes && grandLivre && (
                    <FloatLabel label="Au compte" name="compteAu" value={compteAu}>
                      <Form.Item name="compteAu">
                        <Select
                          showSearch
                          optionFilterProp="label"
                          optionLabelProp="label"
                          onSelect={(value) => this.onSelectCompteAu(value)}
                        >
                          <OptGroup label="Compte">
                            {this.filterByCompte(comptes.data, compteCentralisateur).map(
                              (item, i) => (
                                <Option
                                  key={i}
                                  value={
                                    compteCentralisateur
                                      ? item.compte_compte_auxiliaire
                                      : item.compte_compte_general
                                  }
                                  label={`${
                                    compteCentralisateur
                                      ? item.compte_compte_auxiliaire
                                      : item.compte_compte_general
                                  } - ${item.compte_libelle}`}
                                >
                                  <Row>
                                    <Col sm={8}>{`${
                                      compteCentralisateur
                                        ? item.compte_compte_auxiliaire
                                        : item.compte_compte_general
                                    } - ${item.compte_libelle}`}</Col>
                                  </Row>
                                </Option>
                              )
                            )}
                          </OptGroup>
                        </Select>
                      </Form.Item>
                    </FloatLabel>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              {grandLivre && (
                <>
                  <Radio.Group
                    style={{ float: "right" }}
                    onChange={(e) => this.onChangeTri(e)}
                    value={tri}
                  >
                    <Radio value={1}>tri alphanumérique</Radio>
                    <Radio value={2}>tri alphabétique</Radio>
                  </Radio.Group>
                </>
              )}
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={6}>
              <FloatLabel label="Du" name="dateDu" value={dateDu}>
                <Form.Item name="date_du">
                  <DatePicker
                    format={dateFormat}
                    placeholder=""
                    onChange={(e) => this.onChangeDateDu(e)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={6}>
              <FloatLabel label="Au" name="dateAu" value={dateAu}>
                <Form.Item name="date_au">
                  <DatePicker
                    defaultValue={dayjs()}
                    format={dateFormat}
                    placeholder=""
                    onChange={(e) => this.onChangeDateAu(e)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={2}>
              <RedoOutlined onClick={this.onRefresh} spin={refresh} style={{ fontSize: "32px" }} />
            </Col>
            <Col offset={grandLivre ? 4 : 2} span={2}>
              {/* TODO */}
            </Col>
            {!grandLivre && (
              <Col span={2}>
                <FormItem name="soldeReport" valuePropName="checked">
                  <Checkbox onChange={(value) => this.onChangeSoldeReport(value)}>
                    <span style={{ fontSize: "18px" }}>Solde de report</span>
                  </Checkbox>
                </FormItem>
              </Col>
            )}
            <Col span={2}>
              <FormItem name="soldeEvolutif" valuePropName="checked">
                <Checkbox onChange={this.onChangeSoldeEvolutif}>
                  <span style={{ fontSize: "18px" }}>Solde évolutif</span>
                </Checkbox>
              </FormItem>
            </Col>
            <Col span={2}>
              <FormItem name="ecrituresPointees" valuePropName="checked">
                <Checkbox onChange={this.onChangeEcrituresPointees}>
                  <span style={{ fontSize: "18px" }}>Écritures pointées</span>
                </Checkbox>
              </FormItem>
            </Col>
          </Row>
        </Form>

        <VirtualTable
          className="table-ecritures"
          columns={columns}
          dataSource={lignesEcritures}
          onChange={this.handleChange}
          rowKey="ecriture_ligne_id"
          scroll={{
            y: 600,
            x: "100vw",
          }}
          summary={(pageData) => {
            let totalDebit = 0;
            let totalCredit = 0;
            let solde = 0;

            pageData.forEach(({ ecriture_ligne_montant_debit, ecriture_ligne_montant_credit }) => {
              totalDebit += parseFloat(ecriture_ligne_montant_debit);
              totalCredit += parseFloat(ecriture_ligne_montant_credit);
            });

            solde = parseFloat(totalDebit - totalCredit);

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <strong>TOTAL :</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {parseFloat(totalDebit).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {parseFloat(totalCredit).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <strong>SOLDE :</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {solde >= 0 ? (
                          parseFloat(Math.abs(solde)).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text style={{ float: "right" }}>
                      <strong>
                        {solde <= 0 ? (
                          parseFloat(Math.abs(solde)).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </strong>
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Spin>
    );
  }
}

export default FormGrandLivre;
