import Constants from "../../../utils/constants";
import React, { Component } from "react";
import { round } from "../../../utils/tools";
import dayjs from "dayjs";

export default class RelevePDF extends Component {
  emptyStr = (str) => {
    return str ? str : "";
  };

  render() {
    const { dateButoir, detaillee, tva, ligneReddition, utilisateur } = this.props;

    let lots = [];
    let locsPrecedentes = [];
    let totalDebit = 0;
    let totalCredit = 0;
    let totalSolde = 0;
    let totalSoldeDebit = 0;
    let totalSoldeCredit = 0;
    let totalHonoraires = 0;
    let totalLocations = 0;
    let ssTotalDebit = 0;
    let ssTotalCredit = 0;
    let honorairesHT = 0;
    let honorairesTVA = 0;
    let total = 0;
    let totalCompta = 0;

    totalHonoraires = ligneReddition.montantFrais;

    ligneReddition.locations.forEach((ligne) => {
      totalLocations += ligne.montant;
      totalCredit += ligne.montant;
    });

    honorairesHT = round(totalHonoraires / 1.2, 2);
    honorairesTVA = totalHonoraires - honorairesHT;

    ligneReddition.lignesEcritures
      .filter((ligne) => ligne.compta)
      .forEach((ligne, i) => {
        totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
        totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
        ssTotalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
        ssTotalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
      });
    totalCompta = ssTotalDebit - ssTotalCredit;
    totalDebit = totalDebit + totalHonoraires;

    totalSolde = totalCredit - totalDebit;
    if (totalSolde < 0) {
      totalSoldeDebit = totalSolde;
    } else if (totalSolde > 0) {
      totalSoldeCredit = totalSolde;
    }

    const societe = utilisateur.utilisateur_etablissement.etablissement_societe;

    const chemin = `${Constants.mode}://${Constants.domain}:${Constants.port}/api/societe/display/${societe.societe_id}`;

    let width = 200;
    let height = 30;

    if (societe.societe_logo_width) width = societe.societe_logo_width;
    if (societe.societe_logo_height) height = societe.societe_logo_height;

    return (
      <div>
        {/* ADRESSE CABINET */}
        <div align="center">
          <img src={chemin} width={width} height={height} alt="" />
        </div>

        {/* ADRESSE PROPRIETAIRE */}
        <div>
          <table className="table-signature">
            <thead>
              <tr>
                <th width="55%"></th>
                <th width="45%"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="55%"></td>
                <td width="45%">
                  <p>
                    <strong>
                      {ligneReddition.proprietaire.personne_qualite.qualite_libelle}{" "}
                      {ligneReddition.proprietaire.personne_nom}{" "}
                      {ligneReddition.proprietaire.personne_prenom}
                    </strong>
                  </p>
                  <p>
                    {ligneReddition.proprietaire.personne_adresse.adresse_numero}{" "}
                    {ligneReddition.proprietaire.personne_adresse.adresse_voie}{" "}
                  </p>
                  <p>{ligneReddition.proprietaire.personne_adresse.adresse_secteur} </p>
                  <p>
                    {ligneReddition.proprietaire.personne_adresse.adresse_code_postal}{" "}
                    {ligneReddition.proprietaire.personne_adresse.adresse_ville}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <div align="center" style={{ fontSize: 16 }}>
          <strong>
            {"RELEVE DE GESTION au "}
            {dateButoir
              ? dayjs(dateButoir, "DD/MM/YYYY").format("DD/MM/YYYY")
              : dayjs().format("DD/MM/YYYY")}
          </strong>
        </div>
        <br />
        <br />
        <div align="left">
          {
            ligneReddition.proprietaire_affectations[0].proprietaire_affectation_societe
              .societe_adresse.adresse_ville
          }
          {", le "}
          {dayjs().format("DD/MM/YYYY")}
        </div>
        <br />
        <div align="left">
          <i>
            {"Propriétaire n° "}
            {
              ligneReddition.proprietaire_affectations[0].proprietaire_affectation_compte
                .compte_compte_auxiliaire
            }
          </i>
        </div>
        {ligneReddition.locations.length > 0 &&
          ligneReddition.locations.map((location, i) => {
            if (!lots.some((lot) => lot.lot_id === location.location_lot.lot_id)) {
              lots.push(location.location_lot);
              return (
                <div key={i} align="left">
                  <i>
                    {`Lot n°${location.location_lot.lot_reference} 
              ${
                location.location_lot.lot_designation
                  ? "(" + location.location_lot.lot_designation + ")"
                  : ""
              }: 
              ${location.location_lot.lot_adresse.adresse_gmaps_adresse}`}
                  </i>
                </div>
              );
            }
            return null;
          })}
        <br />
        {/* ENTETES */}
        <div>
          <table className="table-signature">
            <thead>
              <tr>
                <th width="100%" align="center" bgcolor="#8eaadb">
                  <strong>LOCATIONS</strong>
                </th>
              </tr>
              <tr>
                <th width="15%" align="center">
                  {/* <strong>DATE</strong> */}
                </th>
                <th width="70%">
                  <strong>LIBELLE</strong>
                </th>
                <th width="15%" align="center">
                  <strong>CREDIT</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {ligneReddition.locations
                .sort(
                  (a, b) =>
                    dayjs(a.location_date_debut, "YYYY-MM-DD").unix() -
                    dayjs(b.location_date_debut, "YYYY-MM-DD").unix()
                )
                .map((location, i) => {
                  if (!locsPrecedentes.some((loc) => loc.location_id === location.location_id)) {
                    total = 0;
                    const nbNuits = dayjs(location.location_date_fin).diff(
                      dayjs(location.location_date_debut),
                      "days"
                    );
                    ligneReddition.locations.forEach((item) => {
                      if (item.location_id === location.location_id) {
                        total += item.montant;
                      }
                    });
                    // Traitement des reglements
                    const regroupedReglements = ligneReddition.reglements
                      .filter(
                        (reg) =>
                          reg.reglement_quittance.quittance_location_id === location.location_id
                      )
                      .reduce((acc, reglement) => {
                        const { quittance_id, quittance_date_debut, quittance_date_fin } =
                          reglement.reglement_quittance;
                        const key = quittance_id;

                        if (!acc[key]) {
                          acc[key] = {
                            quittance_id,
                            quittance_date_debut,
                            quittance_date_fin,
                            reglement_ligne: [],
                          };
                        }

                        reglement.reglement_ligne.forEach((ligne) => {
                          const existingLigne = acc[key].reglement_ligne.find(
                            (item) =>
                              item.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique
                                .rubrique_id ===
                              ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique
                                .rubrique_id
                          );

                          if (existingLigne) {
                            existingLigne.reglement_ligne_montant =
                              parseFloat(existingLigne.reglement_ligne_montant) +
                              parseFloat(ligne.reglement_ligne_montant);
                          } else {
                            acc[key].reglement_ligne.push({ ...ligne });
                          }
                        });

                        return acc;
                      }, {});

                    const result = Object.values(regroupedReglements);

                    locsPrecedentes.push(location);
                    return (
                      <div key={i}>
                        <tr>
                          <td width="15%" align="center">
                            {/* {dayjs(location.location_date_debut).format("DD/MM/YYYY")} */}
                          </td>
                          <td width="70%">
                            {`${
                              location.location_requete &&
                              location.location_requete.requete_locataire.locataire_personne
                                .personne_nom
                            } ${
                              location.location_requete &&
                              this.emptyStr(
                                location.location_requete.requete_locataire.locataire_personne
                                  .personne_prenom
                              )
                            } (${location.location_lot.lot_reference})${
                              location.location_type_contrat === 1 ||
                              location.location_type_contrat === 2
                                ? ""
                                : `, du ${dayjs(location.location_date_debut).format(
                                    "DD/MM/YYYY"
                                  )} au ${dayjs(location.location_date_fin).format(
                                    "DD/MM/YYYY"
                                  )} (${nbNuits} nuits)`
                            }`}
                          </td>
                          <td width="15%" align="right">
                            {detaillee
                              ? ""
                              : parseFloat(total).toLocaleString("fr-FR", {
                                  style: "currency",
                                  currency: "EUR",
                                })}
                          </td>
                        </tr>
                        {detaillee &&
                          result.map((reglement, j) => {
                            return (
                              <tr key={j}>
                                <td colSpan="3">
                                  <table className="table-signature">
                                    <tr>
                                      <th width="25%" align="center"></th>
                                      <th width="60%">
                                        <strong>
                                          {`PÉRIODE DU ${dayjs(
                                            reglement.quittance_date_debut
                                          ).format("DD/MM/YYYY")} AU ${dayjs(
                                            reglement.quittance_date_fin
                                          ).format("DD/MM/YYYY")}`}
                                        </strong>
                                      </th>
                                      <th width="15%" align="center"></th>
                                    </tr>
                                    {reglement.reglement_ligne
                                      .filter(
                                        (ligne) =>
                                          ligne.reglement_ligne_quittance_ligne_id
                                            .quittance_ligne_rubrique.rubrique_type === "P"
                                      )
                                      .sort(
                                        (a, b) =>
                                          a.reglement_ligne_quittance_ligne_id
                                            .quittance_ligne_rubrique.rubrique_id -
                                          b.reglement_ligne_quittance_ligne_id
                                            .quittance_ligne_rubrique.rubrique_id
                                      )
                                      .map((ligne, k) => {
                                        return (
                                          <tr key={k}>
                                            <td width="25%"></td>
                                            <td width="60%">
                                              {ligne.reglement_ligne_quittance_ligne_id
                                                .quittance_ligne_libelle
                                                ? ligne.reglement_ligne_quittance_ligne_id
                                                    .quittance_ligne_libelle
                                                : ligne.reglement_ligne_quittance_ligne_id
                                                    .quittance_ligne_rubrique.rubrique_libelle}
                                            </td>
                                            <td width="15%" align="right" style={{ fontSize: 9 }}>
                                              {parseFloat(
                                                ligne.reglement_ligne_montant
                                              ).toLocaleString("fr-FR", {
                                                style: "currency",
                                                currency: "EUR",
                                              })}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </table>
                                </td>
                              </tr>
                            );
                          })}
                      </div>
                    );
                  }
                  return null;
                })}
              <tr bgcolor="#d9e2f3">
                <td width="70%" align="right">
                  <strong>TOTAL DES VERSEMENTS : </strong>
                </td>
                <td width="15%"></td>
                <td width="15%" align="right">
                  {parseFloat(totalLocations).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />

          {ligneReddition.lignesEcritures.length > 0 && (
            <table className="table-signature">
              <tr>
                <th width="100%" align="center" bgcolor="#8eaadb">
                  <strong>OPÉRATIONS</strong>
                </th>
              </tr>
              <tr>
                <th width="15%" align="center">
                  <strong>DATE</strong>
                </th>
                <th width="55%">
                  <strong>LIBELLE</strong>
                </th>
                <th width="15%" align="center">
                  <strong>DEBIT</strong>
                </th>
                <th width="15%" align="center">
                  <strong>CREDIT</strong>
                </th>
              </tr>
              {ligneReddition.lignesEcritures
                .filter((ligne) => ligne.visible)
                .map((ligne, i) => {
                  return (
                    <tr key={i}>
                      <td width="15%">{ligne.ecriture_date_ecriture}</td>
                      <td width="55%">{ligne.ecriture_ligne_libelle}</td>
                      <td width="15%" align="right">
                        {parseFloat(ligne.ecriture_ligne_montant_debit) !== 0 ? (
                          parseFloat(ligne.ecriture_ligne_montant_debit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                      <td width="15%" align="right">
                        {parseFloat(ligne.ecriture_ligne_montant_credit) !== 0 ? (
                          parseFloat(ligne.ecriture_ligne_montant_credit).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              <tr bgcolor="#d9e2f3">
                <td width="70%" align="right">
                  <strong>TOTAL : </strong>
                </td>
                <td width="15%" align="right">
                  {parseFloat(ssTotalDebit) !== 0 ? (
                    parseFloat(ssTotalDebit).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
                <td width="15%" align="right">
                  {parseFloat(ssTotalCredit) !== 0 ? (
                    parseFloat(ssTotalCredit).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </table>
          )}

          <br />
          <br />
          <br />

          <table className="table-signature">
            <tr>
              <th width="100%" align="center" bgcolor="#8eaadb">
                <strong>RECAPITULATIF</strong>
              </th>
            </tr>
            <tr>
              <th width="15%" align="center">
                <strong>DATE</strong>
              </th>
              <th width="55%">
                <strong>LIBELLE</strong>
              </th>
              <th width="15%" align="center">
                <strong>DEBIT</strong>
              </th>
              <th width="15%" align="center">
                <strong>CREDIT</strong>
              </th>
            </tr>
            <tr>
              <td width="15%">
                {dateButoir
                  ? dayjs(dateButoir, "DD/MM/YYYY").format("DD/MM/YYYY")
                  : dayjs().format("DD/MM/YYYY")}
              </td>
              <td width="55%">TOTAL LOCATIONS</td>
              <td width="15%" align="right"></td>
              <td width="15%" align="right">
                {" "}
                {parseFloat(totalLocations) !== 0 ? (
                  parseFloat(totalLocations).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })
                ) : (
                  <></>
                )}
              </td>
            </tr>
            {ligneReddition.lignesEcritures.length > 0 && (
              <tr>
                <td width="15%">
                  {dateButoir
                    ? dayjs(dateButoir, "DD/MM/YYYY").format("DD/MM/YYYY")
                    : dayjs().format("DD/MM/YYYY")}
                </td>
                <td width="55%">TOTAL OPÉRATIONS</td>
                <td width="15%" align="right">
                  {parseFloat(totalCompta) > 0 ? (
                    parseFloat(totalCompta).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
                <td width="15%" align="right">
                  {parseFloat(totalCompta) < 0 ? (
                    Math.abs(parseFloat(totalCompta)).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            )}
            {tva ? (
              <>
                {parseFloat(totalHonoraires) !== 0 && (
                  <tr>
                    <td width="15%">
                      {dateButoir
                        ? dayjs(dateButoir, "DD/MM/YYYY").format("DD/MM/YYYY")
                        : dayjs().format("DD/MM/YYYY")}
                    </td>
                    <td width="55%">HONORAIRES HT</td>
                    <td width="15%" align="right">
                      {parseFloat(honorairesHT) !== 0 ? (
                        parseFloat(honorairesHT).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })
                      ) : (
                        <></>
                      )}
                    </td>
                    <td width="15%" align="right"></td>
                  </tr>
                )}
                {parseFloat(totalHonoraires) !== 0 && (
                  <tr>
                    <td width="15%">
                      {dateButoir
                        ? dayjs(dateButoir, "DD/MM/YYYY").format("DD/MM/YYYY")
                        : dayjs().format("DD/MM/YYYY")}
                    </td>
                    <td width="55%">TVA SUR HONORAIRES</td>
                    <td width="15%" align="right">
                      {parseFloat(honorairesTVA) !== 0 ? (
                        parseFloat(honorairesTVA).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })
                      ) : (
                        <></>
                      )}
                    </td>
                    <td width="15%" align="right"></td>
                  </tr>
                )}
              </>
            ) : (
              <>
                {parseFloat(totalHonoraires) !== 0 && (
                  <tr>
                    <td width="15%">
                      {dateButoir
                        ? dayjs(dateButoir, "DD/MM/YYYY").format("DD/MM/YYYY")
                        : dayjs().format("DD/MM/YYYY")}
                    </td>
                    <td width="55%">HONORAIRES DE GESTION</td>
                    <td width="15%" align="right">
                      {parseFloat(totalHonoraires) !== 0 ? (
                        parseFloat(totalHonoraires).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })
                      ) : (
                        <></>
                      )}
                    </td>
                    <td width="15%" align="right"></td>
                  </tr>
                )}
              </>
            )}
            <tr bgcolor="#d9e2f3">
              <td width="70%" align="right">
                <strong>SOLDE A {totalSoldeDebit < 0 ? "NOUS" : "VOUS"} DEVOIR :</strong>
              </td>
              <td width="15%" align="right">
                {" "}
                {parseFloat(totalSoldeDebit) !== 0 ? (
                  parseFloat(Math.abs(totalSoldeDebit)).toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  })
                ) : (
                  <></>
                )}
              </td>
              <td width="15%" align="right">
                <strong>
                  {" "}
                  {parseFloat(totalSoldeCredit) !== 0 ? (
                    parseFloat(totalSoldeCredit).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  ) : (
                    <></>
                  )}
                </strong>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}
