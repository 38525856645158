import React, { Component } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Divider } from "antd";
import { CheckOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import CustomDatePicker from "../CustomFormItem/DatePicker";
import CustomSelect from "../CustomFormItem/Select";

import dayjs from "dayjs";

import { toast } from "react-toastify";
import CustomInput from "../CustomFormItem/Input";

const dateFormat = "DD/MM/YYYY";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

class FormLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idLot: null,
      dateOption: null,
      modification: false,
    };
  }

  formRefContrat = React.createRef();
  formRefOccupant = React.createRef();

  componentDidMount() {
    this.init(this.props.location);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.init(nextProps.location);
    }
  }

  init = (location) => {
    if (location !== undefined && location !== null) {
      const dates = [dayjs(location.location_date_debut), dayjs(location.location_date_fin)];

      let dateOption = "";
      if (location.location_date_fin_option === null) {
        dateOption = dayjs().add(7, "days");
      } else {
        dateOption = this.formatDate(location.location_date_fin_option);
      }

      this.formRefContrat.current.setFieldsValue({
        location_dates: dates,
        location_lot: location.location_lot.lot_id,
        location_nb_adultes: location.location_nb_adultes,
        location_nb_bebes: location.location_nb_bebes,
        location_nb_enfants: location.location_nb_enfants,
        location_date_fin_option: dateOption,
        location_type_contrat: location.location_type_contrat,
        location_montant_plateforme: location.location_montant_plateforme,
        location_desiderata: location.location_desiderata,
        location_remarques: location.location_remarques,
      });

      this.setState({ dateOption: dateOption });
      this.loadOccupant(location);
    }
  };

  loadOccupant = (location) => {
    const occupants = location.location_occupant.map((o) => {
      o.occupant_date_naissance = o.occupant_date_naissance && dayjs(o.occupant_date_naissance);
      return o;
    });

    if (occupants.length === 0) {
      const occupant = {
        occupant_nom: location.location_requete.requete_locataire.locataire_personne.personne_nom,
        occupant_prenom:
          location.location_requete.requete_locataire.locataire_personne.personne_prenom,
        occupant_date_naissance: null,
        occupant_lieu_naissance: null,
      };
      occupants.push(occupant);
    }

    this.formRefOccupant.current.setFieldsValue({
      occupants: occupants,
    });
  };

  compare = (a, comparator, b) => {
    switch (comparator) {
      case "<":
      case "< ":
        return a < b;
      case "<=":
        return a <= b;
      case ">":
      case "> ":
        return a > b;
      case ">=":
        return a >= b;
      default:
        break;
    }
  };

  onAbort = () => {
    this.init();
  };

  onSelectLot = (id) => {
    this.setState({ idLot: id });
  };

  onFinishLocation = (values) => {
    const location = { ...this.props.location };
    const etats = this.props.parametre.parametre_blocage_option
      ? ["r", "o", "b", "n"]
      : ["r", "b", "n"];
    if (
      !this.props.locations.some(
        (item) =>
          item.location_lot.lot_id === location.location_lot.lot_id &&
          (values.location_dates[0].isBetween(
            dayjs(item.location_date_debut, "YYYY-MM-DD"),
            dayjs(item.location_date_fin, "YYYY-MM-DD"),
            undefined,
            "[)"
          ) ||
            values.location_dates[1].isBetween(
              dayjs(item.location_date_debut, "YYYY-MM-DD"),
              dayjs(item.location_date_fin, "YYYY-MM-DD"),
              undefined,
              "(]"
            )) &&
          etats.includes(item.location_etat) &&
          item.location_id !== location.location_id
      )
    ) {
      location.location_lot_id = location.location_lot.lot_id;
      location.location_requete_id = location.location_requete.requete_id;
      location.location_nb_adultes = values.location_nb_adultes;
      location.location_nb_enfants = values.location_nb_enfants;
      location.location_nb_bebes = values.location_nb_bebes;
      location.location_montant_plateforme = values.location_montant_plateforme;
      location.location_date_fin_option = values.location_date_fin_option;
      location.location_type_contrat = values.location_type_contrat;
      location.location_desiderata = values.location_desiderata;
      location.location_remarques = values.location_remarques;
      location.location_date_debut = dayjs(values.location_dates[0]).format("YYYY-MM-DD");
      location.location_date_fin = dayjs(values.location_dates[1]).format("YYYY-MM-DD");
      this.props.updateLocation(location, location.location_id).then(() => {
        this.setState({ modification: false });
        toast.success(`Location mise à jour !`, { containerId: "A" });
      });
    } else {
      toast.error("Les dates sélectionnées ne sont pas disponibles pour ce lot", {
        containerId: "A",
      });
    }
  };

  onFinishOccupants = (values) => {
    const occupants = values.occupants.map((occupant) => {
      occupant.occupant_location_id = this.props.location.id;
      occupant.occupant_date_naissance =
        occupant.occupant_date_naissance && occupant.occupant_date_naissance.hour(12);
      return occupant;
    });

    const updateOccupant = occupants.filter((item) => item.occupant_id);
    const addOccupant = occupants.filter((item) => !item.occupant_id);
    const deleteOccupant = this.props.location.location_occupant.filter((item) => {
      return !occupants.some((element) => {
        return element.occupant_id === item.occupant_id;
      });
    });

    if (deleteOccupant.length || updateOccupant.length) {
      this.props
        .updateOccupants(
          {
            occupants: updateOccupant,
            occupants_to_delete: deleteOccupant,
          },
          this.props.location.id
        )
        .catch((err) =>
          toast.error("Occupants : Échec de la modification !", {
            containerId: "A",
          })
        );
    }

    if (addOccupant.length) {
      this.props.saveOccupants({ occupants: addOccupant }, this.props.location.id).catch((err) =>
        toast.error("Occupants : Échec de l'ajout' !", {
          containerId: "A",
        })
      );
    }

    this.props.onChangeCurrent(1);
  };

  formatDate = (d) => {
    let date = null;
    if (d) date = dayjs(d, "YYYY-MM-DD");
    return date;
  };

  onChangeModification = () => {
    this.setState({ modification: true });
  };

  onChangeTypeContrat = (value) => {
    this.setDateFin(value === 1 ? true : false);
    this.setState({ modification: true });
  };

  setDateFin = (alur = false) => {
    const dates = [
      dayjs(this.props.location.location_date_debut),
      alur ? dayjs("2100-01-01") : dayjs(this.props.location.location_date_fin),
    ];
    this.formRefContrat.current.setFieldsValue({
      location_dates: dates,
    });
  };

  render() {
    const { home, isArchive, location, lots, requete } = this.props;
    const { dateOption, modification } = this.state;

    if (location.location_ligne.length > 0) {
      location.location_ligne.sort(
        (a, b) => a.location_ligne_rubrique.rubrique_id - b.location_ligne_rubrique.rubrique_id
      );
    }

    let lotsAjoutables = lots && lots.filter((item) => item.lot_fin === false);
    lotsAjoutables =
      lotsAjoutables &&
      lotsAjoutables.sort((a, b) => a.lot_reference.localeCompare(b.lot_reference));

    return (
      <>
        <Row gutter={12}>
          <Col span={14}>
            <Divider orientation="left">Détails de la location</Divider>
            <Form
              autoComplete="off"
              name="form-contrat"
              onFinish={this.onFinishLocation}
              ref={this.formRefContrat}
              size={home ? "small" : "large"}
            >
              <Row gutter={12}>
                <Col span={6}>
                  <span>
                    Groupe:
                    {requete.requete_groupe && (
                      <strong> {requete.requete_groupe.groupe_libelle}</strong>
                    )}
                  </span>
                  <br />
                  <span>
                    Provenance:
                    {requete.requete_provenance && (
                      <strong> {requete.requete_provenance.provenance_libelle}</strong>
                    )}
                  </span>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="location_lot"
                    rules={[
                      {
                        required: true,
                        message: "Appartement obligatoire",
                      },
                    ]}
                  >
                    <Select
                      disabled={isArchive}
                      showSearch
                      optionLabelProp="label"
                      placeholder="APPARTEMENT"
                      onSelect={this.onSelectLot}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <OptGroup label="Appartement">
                        {lotsAjoutables &&
                          lotsAjoutables.map((item, i) => {
                            return (
                              <Option key={i} value={item.lot_id} label={`${item.lot_reference}`}>
                                <Row>
                                  <Col sm={8}>{`${item.lot_reference}`}</Col>
                                </Row>
                              </Option>
                            );
                          })}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <CustomDatePicker
                    label="Date fin option"
                    inputName="location_date_fin_option"
                    formItemName="location_date_fin_option"
                    onChange={() => this.onChangeModification()}
                    objectValue={dateOption}
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="location_dates"
                    rules={[
                      {
                        required: true,
                        message: "Dates obligatoires",
                      },
                    ]}
                  >
                    <RangePicker
                      disabled={isArchive}
                      format={dateFormat}
                      onCalendarChange={() => this.onChangeModification()}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <CustomSelect
                    label="Type de contrat"
                    inputName="location_type_contrat"
                    formItemName="location_type_contrat"
                    onChange={(value) => this.onChangeTypeContrat(value)}
                    objectValue={location ? location.location_type_contrat : null}
                  >
                    <OptGroup label="Type de contrat">
                      <Option key={1} value={1} label={"Alur"}>
                        Alur
                      </Option>
                      <Option key={2} value={2} label={"Long terme"}>
                        Long terme
                      </Option>
                      <Option key={3} value={3} label={"Court terme"}>
                        Court terme
                      </Option>
                    </OptGroup>
                  </CustomSelect>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={6}>
                  <Form.Item name="location_nb_adultes">
                    <Input
                      disabled={isArchive}
                      addonAfter="adulte(s)"
                      placeholder="NOMBRE D'ADULTES"
                      type="number"
                      onChange={() => this.onChangeModification()}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="location_nb_enfants">
                    <Input
                      disabled={isArchive}
                      addonAfter="enfant(s)"
                      placeholder="NOMBRE D'ENFANTS"
                      type="number"
                      onChange={() => this.onChangeModification()}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="location_nb_bebes">
                    <Input
                      disabled={isArchive}
                      addonAfter="bebe(s)"
                      placeholder="NOMBRE DE BEBES"
                      type="number"
                      onChange={() => this.onChangeModification()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={6}>
                  <CustomInput
                    label="Montant Plateforme"
                    inputName="location_montant_plateforme"
                    formItemName="location_montant_plateforme"
                    type="number"
                    onChange={() => this.onChangeModification()}
                    objectValue={location && location.location_montant_plateforme}
                  />
                </Col>
                <Col offset={12} span={1}>
                  <Button htmlType="submit" disabled={!modification} type="primary">
                    <CheckOutlined /> MAJ
                  </Button>
                </Col>
              </Row>
              <Divider orientation="left">Désidérata</Divider>
              <Row>
                <Col span={24}>
                  <Form.Item name="location_desiderata">
                    <TextArea
                      disabled={isArchive}
                      autoSize={{ minRows: 5 }}
                      placeholder="DESIDERATA"
                      onChange={() => this.onChangeModification()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left">Remarques</Divider>
              <Row>
                <Col span={24}>
                  <Form.Item name="location_remarques">
                    <TextArea
                      disabled={isArchive}
                      autoSize={{ minRows: 5 }}
                      placeholder="REMARQUES"
                      onChange={() => this.onChangeModification()}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={10}>
            <Divider orientation="left">Liste des occupants</Divider>
            <Form
              autoComplete="off"
              name="dynamic_form_nest_item"
              onFinish={this.onFinishOccupants}
              ref={this.formRefOccupant}
              size={home ? "small" : "large"}
              style={{ marginTop: 10 }}
            >
              <Form.List name="occupants">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space key={field.key} style={{ display: "flex" }}>
                        <Form.Item name="occupant_id" hidden>
                          <Input readOnly>{field.id}</Input>
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "occupant_nom"]}
                          fieldKey={[field.fieldKey, "occupant_nom"]}
                          rules={[
                            {
                              required: true,
                              message: "Nom obligatoire",
                              max: 256,
                            },
                          ]}
                        >
                          <Input disabled={isArchive} placeholder="NOM" />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "occupant_prenom"]}
                          fieldKey={[field.fieldKey, "occupant_prenom"]}
                          rules={[
                            {
                              required: true,
                              message: "Prénom obligatoire",
                              max: 256,
                            },
                          ]}
                        >
                          <Input disabled={isArchive} placeholder="PRENOM" />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "occupant_date_naissance"]}
                          fieldKey={[field.fieldKey, "occupant_date_naissance"]}
                        >
                          <DatePicker disabled={isArchive} format={dateFormat} />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "occupant_lieu_naissance"]}
                          fieldKey={[field.fieldKey, "occupant_lieu_naissance"]}
                          rules={[
                            {
                              message: "Lieu de naissance ne peut dépaser 256 charactères",
                              max: 256,
                            },
                          ]}
                        >
                          <Input disabled={isArchive} placeholder="LIEU NAISSANCE" />
                        </Form.Item>
                        <Form.Item>
                          <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Form.Item>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        disabled={isArchive}
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Ajouter occupant
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  position: "absolute",
                  top: "600px",
                  left: "400px",
                }}
              >
                <Button
                  type="primary"
                  danger
                  style={{ width: 100, marginBottom: 10 }}
                  onClick={this.onAbort}
                >
                  Annuler
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ width: 100, marginLeft: 10, marginBottom: 10 }}
                >
                  Valider
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

export default FormLocation;
