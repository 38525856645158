import React, { useRef, useState } from "react";
import { Button, Col, DatePicker, Row, Table, Tabs } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

import { useReactToPrint } from "react-to-print";

import { CSVLink } from "react-csv";

import { round } from "../../utils/tools";

import dayjs from "dayjs";

const dateFormat = "DD/MM/YYYY";

const { RangePicker } = DatePicker;

const TablePointe = (props) => {
  const [dates, setDates] = useState([dayjs().startOf("year"), dayjs()]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pointes, setPointes] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const { comptes, ecritures, parametre } = props;

  const componentRef = useRef();

  const onChangeRange = (e) => {
    setDates([e[0], e[1]]);
  };

  const preparePointe = () => {
    const lignesEcritures = [];
    ecritures?.forEach((ecriture) => {
      const lignes = ecriture.ecriture_ligne.filter((ligne) =>
        selectedRowKeys.includes(ligne.ecriture_ligne_compte.compte_compte_general)
      );
      lignes?.forEach((ligne) => {
        ligne.key = ligne.ecriture_ligne_id;
        ligne.ecriture_id = ecriture.ecriture_id;
        ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
        ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
        lignesEcritures.push(ligne);
      });
    });
    calculPointe(lignesEcritures);
  };

  const calculPointe = (lignesEcritures) => {
    const lignesPointe = [];
    const datas = [];
    let solde = 0;
    lignesEcritures.sort((a, b) =>
      dayjs(a.ecriture_date_ecriture, "DD/MM/YYYY").diff(
        dayjs(b.ecriture_date_ecriture, "DD/MM/YYYY")
      )
    );
    lignesEcritures.forEach((ligne) => {
      if (dayjs(ligne.ecriture_date_ecriture, "DD/MM/YYYY").isBefore(dayjs(dates[0]))) {
        const soldeAnterieur = round(
          ligne.ecriture_ligne_montant_credit - ligne.ecriture_ligne_montant_debit,
          2
        );
        solde = round(solde + soldeAnterieur, 2);
      }
    });
    lignesEcritures.forEach((ligne) => {
      if (
        dayjs(ligne.ecriture_date_ecriture, "DD/MM/YYYY").isBefore(dayjs(dates[1])) &&
        dayjs(ligne.ecriture_date_ecriture, "DD/MM/YYYY").isAfter(dayjs(dates[0]))
      ) {
        const startIndex = lignesPointe.findIndex(
          (x) => x.ecriture_date_ecriture === ligne.ecriture_date_ecriture
        );
        if (startIndex !== -1) {
          let mSolde = 0;
          mSolde = ligne.ecriture_ligne_montant_credit - ligne.ecriture_ligne_montant_debit;
          mSolde = Number(mSolde.toFixed(2));
          solde += mSolde;
          lignesPointe[startIndex].solde = round(lignesPointe[startIndex].solde + mSolde, 2);
        } else {
          const lignePointe = {};
          lignePointe.ecriture_date_ecriture = ligne.ecriture_date_ecriture;
          const soldeLigne = round(
            ligne.ecriture_ligne_montant_credit - ligne.ecriture_ligne_montant_debit,
            2
          );
          // lignes pointes
          solde += soldeLigne;
          lignePointe.solde = Number(solde.toFixed(2));
          lignePointe.key = ligne.key;
          lignesPointe.push(lignePointe);

          // datas
          const data = {
            name: lignePointe.ecriture_date_ecriture,
            pv: lignePointe.solde,
          };
          datas.push(data);
        }
      }
    });
    lignesPointe.sort((a, b) =>
      dayjs(a.ecriture_date_ecriture).diff(dayjs(b.ecriture_date_ecriture))
    );
    datas.sort((a, b) => dayjs(a.name).diff(dayjs(b.name)));
    setPointes(lignesPointe);
    setDataChart(datas);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    selectedRows: selectedRows,
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const isMax = (row) => {
  //   const pointeMax = Math.max(...pointes.map((o) => o.solde));
  //   return row.solde === pointeMax;
  // };

  const pointeMax = pointes.find(
    (pointe) => pointe.solde === Math.max(...pointes.map((o) => o.solde))
  );

  const getColor = (row) => {
    if (row.solde > parametre.parametre_pointe_max) return "pointe-bg-red";
    else return "";
  };

  const comptesColumns = [
    {
      title: "Code",
      key: "compte_compte_general",
      render: (record) => record.compte_compte_general,
      sorter: (a, b) => a.compte_compte_general - b.compte_compte_general,
      defaultSortOrder: "ascend",
    },
    {
      title: "Libelle",
      key: "compte_libelle",
      render: (record) => record.compte_libelle,
    },
  ];

  const pointeColumns = [
    {
      title: "Date",
      key: "ecriture_date_ecriture",
      // defaultSortOrder: "ascend",
      render: (record) => dayjs(record.ecriture_date_ecriture, "DD/MM/YYYY").format("DD/MM/YYYY"),
      // sorter: (a, b) =>
      //   dayjs(a.ecriture_date_ecriture).unix() - dayjs(b.ecriture_date_ecriture).unix(),
    },
    {
      title: "Montant",
      key: "solde",
      render: (record) =>
        parseFloat(record.solde).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        }),
    },
  ];

  return (
    <>
      <RangePicker
        defaultValue={[dayjs().startOf("year"), dayjs()]}
        format={dateFormat}
        onCalendarChange={(e) => onChangeRange(e)}
      />
      <Table
        columns={comptesColumns}
        dataSource={comptes}
        pagination={false}
        rowKey="compte_compte_general"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        scroll={{ y: 252 }}
        size="small"
        showSorterTooltip={false}
        title={() => "Sélection des comptes"}
      />
      <Row gutter={12} style={{ marginTop: 10 }}>
        <Col span={22}>
          <Tabs
            defaultActiveKey="1"
            tabPosition="left"
            items={[
              {
                label: "Table",
                key: "1",
                children: (
                  <Table
                    columns={pointeColumns}
                    dataSource={pointes}
                    pagination={false}
                    rowKey="key"
                    scroll={{ y: 252 }}
                    size="small"
                    rowClassName={(row) => getColor(row)}
                    style={{ marginRight: 20 }}
                    showSorterTooltip={false}
                  />
                ),
              },
              {
                label: "Graph",
                key: "2",
                style: { width: "100%", height: "100%" },
                children: (
                  <div ref={componentRef}>
                    <LineChart
                      width={600}
                      height={400}
                      data={dataChart}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <ReferenceLine
                        y={parametre.parametre_pointe_max}
                        label="Pointe max"
                        stroke="red"
                        strokeDasharray="3 3"
                      />
                      <Line
                        type="monotone"
                        dataKey="pv"
                        name="pointe"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                    <br />
                    {pointeMax && (
                      <span style={{ marginLeft: 15, fontStyle: "italic" }}>
                        * Pointe max atteinte le {pointeMax.ecriture_date_ecriture} pour un montant
                        de{" "}
                        {parseFloat(pointeMax.solde).toLocaleString("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        })}
                      </span>
                    )}
                  </div>
                ),
              },
            ]}
            style={{ marginTop: 20 }}
          />
        </Col>
        <Col span={2}>
          <Row style={{ marginBottom: 10 }}>
            <Button type="primary" onClick={() => preparePointe()}>
              Calcul pointe
            </Button>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Button>
              <CSVLink
                filename={"export.csv"}
                data={pointes}
                // headers={headers()}
                separator={";"}
              >
                Export CSV
              </CSVLink>
            </Button>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Button type="primary" onClick={() => handlePrint()}>
              Imprimer
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TablePointe;
