import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";

import {
  Row,
  Col,
  Form,
  Table,
  Button,
  Input,
  Space,
  Select,
  DatePicker,
  Spin,
  Typography,
  Modal,
  Checkbox,
  Popconfirm,
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderViewOutlined,
  RedoOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import { toast } from "react-toastify";
import Constants from "../../utils/constants";
import { formatDateToBDD, round } from "../../utils/tools";

import dayjs from "dayjs";

import FloatLabel from "../FloatLabel";
import RapprochementPDF from "../Pdf/RapprochementPDF/index";
import Operation from "../../containers/Comptabilite/Operation";
import { EditableCell, EditableRow } from "../EditableTable";
import VirtualTable from "../VirtualTable";
import { CSVLink } from "react-csv";
import { headers } from "./headers";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";

const dateFormat = "DD/MM/YYYY";

const { Text } = Typography;
const { Option, OptGroup } = Select;
const { Column } = Table;
const { Search } = Input;

class FormRapprochementBancaire extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      refresh: false,
      ecritureState: null,
      lignesEcritures: [],
      lignesCalcul: [],
      lignesTraitees: [],
      modalEcriture: false,
      modalPdf: false,
      searchText: "",
      searchedColumn: "",
      societe: "",
      compteGeneral: null,
      dateReleve: null,
      montantReleve: 0,
      dateDu: null,
      dateAu: null,
      filtres: ["2"],
      strDebit: "",
      strCredit: "",
      lettrage: "",
    };
  }

  // Référence du formulaire
  formRef = React.createRef();
  toastId = React.createRef(null);

  componentDidMount() {
    this.props.parametre && this.props.comptes && this.initReleve();
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init();
    (props.comptes !== this.props.comptes || props.ecritures !== this.props.ecritures) &&
      this.initReleve();
  }

  initReleve = () => {
    this.formRef.current.setFieldsValue({
      societe_id:
        this.props.parametre.parametre_utilisateur.utilisateur_etablissement
          .etablissement_societe_id &&
        this.props.parametre.parametre_utilisateur.utilisateur_etablissement
          .etablissement_societe_id,
      compte_general: this.props.pointage
        ? this.state.compteGeneral
          ? this.state.compteGeneral
          : null
        : this.props.parametre.parametre_rapprochement_compte_id
        ? this.props.parametre.parametre_rapprochement_compte_id
        : this.props.comptes?.filter((item) => item.compte_compte_general.charAt(0) === "5")[0]
            ?.compte_id,
      date_releve:
        this.props.parametre.parametre_date_releve &&
        dayjs(this.props.parametre.parametre_date_releve, "YYYY-MM-DD"),
      date_au:
        this.props.parametre.parametre_date_releve &&
        dayjs(this.props.parametre.parametre_date_releve, "YYYY-MM-DD"),
      montant_releve:
        this.props.parametre.parametre_montant_releve &&
        this.props.parametre.parametre_montant_releve,
    });
    this.setState({
      societe:
        this.props.parametre.parametre_utilisateur.utilisateur_etablissement
          .etablissement_societe_id &&
        this.props.parametre.parametre_utilisateur.utilisateur_etablissement
          .etablissement_societe_id,
      compteGeneral: this.props.pointage
        ? this.state.compteGeneral
          ? this.state.compteGeneral
          : null
        : this.props.parametre.parametre_rapprochement_compte_id
        ? this.props.parametre.parametre_rapprochement_compte_id
        : this.props.comptes?.filter((item) => item.compte_compte_general.charAt(0) === "5")[0]
            ?.compte_id,
      dateReleve:
        this.props.parametre.parametre_date_releve &&
        dayjs(this.props.parametre.parametre_date_releve, "YYYY-MM-DD"),
      dateAu:
        this.props.parametre.parametre_date_releve &&
        dayjs(this.props.parametre.parametre_date_releve, "YYYY-MM-DD"),
      montantReleve:
        this.props.parametre.parametre_montant_releve &&
        this.props.parametre.parametre_montant_releve,
    });
  };

  init = () => {
    let lignesEcritures = [];
    let lignesCalcul = [];
    let ecrituresFiltered = this.props.ecritures;

    if (this.state.dateDu)
      ecrituresFiltered =
        ecrituresFiltered &&
        ecrituresFiltered.filter((e) =>
          dayjs(e.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrAfter(this.state.dateDu)
        );
    if (this.state.dateAu)
      ecrituresFiltered =
        ecrituresFiltered &&
        ecrituresFiltered.filter((e) =>
          dayjs(e.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(this.state.dateAu)
        );

    ecrituresFiltered &&
      ecrituresFiltered.forEach((ecriture) => {
        const lignes = ecriture.ecriture_ligne.filter(
          (e) => e.ecriture_ligne_compte_id === this.state.compteGeneral
        );
        lignes &&
          lignes.forEach((ligne) => {
            ligne.ecriture_id = ecriture.ecriture_id;
            ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
            ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
            if (this.state.dateReleve) {
              dayjs(ligne.ecriture_ligne_lettrage, "MM/YY", true).isValid()
                ? dayjs(ligne.ecriture_ligne_lettrage, "MM/YY").isBefore(
                    dayjs(this.state.dateReleve)
                  ) && lignesEcritures.push(ligne)
                : lignesEcritures.push(ligne);
            } else {
              lignesEcritures.push(ligne);
            }
          });
        lignes &&
          lignes.forEach((ligne) => {
            ligne.ecriture_id = ecriture.ecriture_id;
            ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
            ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
            lignesCalcul.push(ligne);
            // if (this.state.dateReleve) {
            //   dayjs(ligne.ecriture_ligne_lettrage, "MM/YY").isValid()
            //     ? dayjs(ligne.ecriture_ligne_lettrage, "MM/YY").isBefore(
            //         dayjs(this.state.dateReleve)
            //       ) && lignesCalcul.push(ligne)
            //     : lignesCalcul.push(ligne);
            // } else {
            //   lignesCalcul.push(ligne);
            // }
          });
      });
    this.setState({ lignesEcritures, refresh: false });
    this.setState({ lignesCalcul, refresh: false });
  };

  filterByCompte = (comptes, compteCentralisateur) => {
    let comptesFiltered = [];
    compteCentralisateur
      ? (comptesFiltered = comptes.filter(
          (item) =>
            item.compte_compte_general ===
              comptes.find((item) => item.compte_id === compteCentralisateur)
                .compte_compte_general && item.compte_type_compte === "A"
        ))
      : (comptesFiltered = comptes.filter((item) => item.compte_type_compte !== "A"));
    return comptesFiltered;
  };

  notify = () => {
    this.toastId.current = toast("Dépointage en cours...", {
      containerId: "A",
      autoClose: false,
      closeButton: false, // Remove the closeButton
    });
  };

  onChangeCompteGeneral = (idCompte) => {
    this.saveRapprochementCompte(idCompte);
    this.setState({ compteGeneral: idCompte });
  };

  onChangeDateReleve = (dateReleve) => {
    this.formRef.current.setFieldsValue({
      date_au: dateReleve,
    });
    this.saveDateReleve(dateReleve);
    this.setState({ dateReleve, dateAu: dateReleve });
  };

  onChangeMontantReleve = (e) => {
    this.setState({ montantReleve: e.target.value });
  };

  onChangeDateDu = (dateDu) => {
    this.setState({ dateDu });
  };

  onChangeDateAu = (dateAu) => {
    this.setState({ dateAu });
  };

  onRefresh = () => {
    this.setState({ refresh: true }, () => this.init());
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  getColumnDecimalSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Rechercher
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      value = value.replace(/,/g, ".");
      return record[dataIndex] ? parseFloat(record[dataIndex]) === parseFloat(value) : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleClickLettrage = (e, row) => {
    e.stopPropagation();
    if (row.ecriture_ligne_lettrage === "" || row.ecriture_ligne_lettrage === null) {
      row.ecriture_ligne_lettrage =
        this.props.pointage || this.state.lettrage !== ""
          ? this.state.lettrage
          : this.state.dateReleve && dayjs(this.state.dateReleve).format("MM/YY");
      this.props.updateLigneEcriture(row, row.ecriture_ligne_id).then(() => {
        this.setState((prevState) => ({
          lignesTraitees: [...prevState.lignesTraitees, row.ecriture_ligne_id],
        }));
      });
    }
  };

  handleClickDelettrage = (e, row) => {
    e.stopPropagation();
    if (
      (this.state.dateReleve &&
        dayjs(this.state.dateReleve).format("MM/YY") === row.ecriture_ligne_lettrage) ||
      !dayjs(row.ecriture_ligne_lettrage, "DD/MM").isValid() ||
      row.ecriture_ligne_lettrage.length > 5 ||
      this.props.pointage
    ) {
      row.ecriture_ligne_lettrage = "";
      this.props.updateLigneEcriture(row, row.ecriture_ligne_id);
    } else {
      toast.error("Date du relevé de banque incorrecte", { containerId: "A" });
    }
  };

  /* update lettrage ecriture_ligne */
  handleSave = (row) => {
    this.props.updateLigneEcriture(row, row.ecriture_ligne_id);
  };

  createPdf = (save = false, id = null) => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <RapprochementPDF
        allLignesEcritures={this.state.lignesCalcul}
        lignesEcritures={this.state.lignesEcritures}
        dateDu={this.state.dateDu}
        dateAu={this.state.dateAu}
        compteGeneral={this.state.compteGeneral}
        soldeTheorique={this.state.montantReleve}
        comptes={this.props.comptes}
        societes={this.props.societes}
      />
    );
    const pdf = {
      titre: "Rapprochement",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          save
            ? this.getFileFromUrl(result.pdf.id, id)
            : this.setState({ idPdf: result.pdf.id, modalPdf: true });
        }.bind(this)
      );
  };

  getFileFromUrl = (pdf_id, rapprochement_bancaire_id) => {
    const { url, params } = initFetch(
      `pdf/document_b.php?num=${pdf_id}&name=document`,
      HttpMethod.GET,
      { Authorization: true, formData: false, pdf: true }
    );
    fetch(url, params)
      .then((e) => {
        return e.blob();
      })
      .then((blob) => {
        let b = blob;
        b.lastModifiedDate = new Date();
        b.name = `${rapprochement_bancaire_id}.pdf`;

        this.props.uploadRapprochement({ file: b }, rapprochement_bancaire_id);
      });
  };

  closeModal = () => {
    this.setState({ modalPdf: false });
  };

  handleClickVisualisation = (e, id) => {
    e.stopPropagation();
    const ecriture = this.props.ecritures.find((ecriture) => ecriture.ecriture_id === id);
    this.setState({ ecritureState: ecriture, modalEcriture: true });
  };

  closeModalEcriture = () => {
    this.setState({ modalEcriture: false });
  };

  onChangeFilter = (checkedValues) => {
    this.setState({ filtres: checkedValues }, () => this.init());
  };

  handleSubmitRapprochement = (solde) => {
    if (solde === 0) this.formRef.current.submit();
    else
      toast.error("Le solde du rapprochement doit être égal à 0", {
        containerId: "A",
      });
  };

  onFinish = (data) => {
    const jsonRapprochement = {
      rapprochement_bancaire_date_releve: formatDateToBDD(data.date_releve),
      rapprochement_bancaire_montant_releve: parseFloat(data.montant_releve),
      rapprochement_bancaire_date_validation: formatDateToBDD(dayjs()),
      rapprochement_bancaire_commentaire: "",
      rapprochement_bancaire_compte_id: this.state.compteGeneral,
    };
    this.props
      .saveRapprochementBancaire(jsonRapprochement)
      .then((res) => {
        this.createPdf(true, res.rapprochement_bancaire_id);
        toast.success("Rapprochement validé", { containerId: "A" });
      })
      .catch((err) =>
        toast.error("Échec de la validation", {
          containerId: "A",
        })
      );
  };

  saveRapprochementCompte = (idCompte) => {
    const parametre = this.props.parametre;
    const jsonParametre = {
      parametre_utilisateur: parametre.parametre_util,
      parametre_email: parametre.parametre_email,
      parametre_mdp: parametre.parametre_mdp,
      parametre_smtp: parametre.parametre_smtp,
      parametre_port: parametre.parametre_port,
      parametre_ssl: parametre.parametre_ssl,
      parametre_verifier_identite: parametre.parametre_verifier_identite,
      parametre_tls: parametre.parametre_tls,
      parametre_authentification: parametre.parametre_authentification,
      parametre_copie_agence: parametre.parametre_copie_agence,
      parametre_quittancement: parametre.parametre_quittancement,
      parametre_parcours_client: parametre.parametre_parcours_client,
      parametre_cacher_titre: parametre.parametre_cacher_titre,
      parametre_cacher_inactive: parametre.parametre_cacher_inactive,
      parametre_nombre_etapes: parametre.parametre_nombre_etapes,
      parametre_pointage_auto: parametre.parametre_pointage_auto,
      parametre_rapprochement_compte_id: idCompte,
      parametre_date_releve: parametre.parametre_date_releve,
      parametre_montant_releve: parametre.parametre_montant_releve,
      parametre_pointe_max: parametre.parametre_pointe_max,
      parametre_signature: parametre.parametre_signature,
      parametre_scheduler_row_height: parametre.parametre_scheduler_row_height,
      parametre_scheduler_row_margin: parametre.parametre_scheduler_row_margin,
      parametre_scheduler_day_before: parametre.parametre_scheduler_day_before,
      parametre_scheduler_day_after: parametre.parametre_scheduler_day_after,
      parametre_scheduler_afficher_reference: parametre.parametre_scheduler_afficher_reference,
      parametre_calcul_arrondi: parametre.parametre_calcul_arrondi,
      parametre_reddition_detaillee: parametre.parametre_reddition_detaillee,
      parametre_depenses_proprietaire: parametre.parametre_depenses_proprietaire,
      parametre_blocage_option: parametre.parametre_blocage_option,
      parametre_langue_id: parametre.parametre_langue_id,
      parametre_email_copie: parametre.parametre_email_copie,
      parametre_reddition_tva: parametre.parametre_reddition_tva,
    };

    this.props.updateParametre(jsonParametre, parametre.parametre_id);
  };

  saveDateReleve = (dateReleve) => {
    if (dateReleve) {
      const parametre = this.props.parametre;
      const jsonParametre = {
        parametre_utilisateur: parametre.parametre_util,
        parametre_email: parametre.parametre_email,
        parametre_mdp: parametre.parametre_mdp,
        parametre_smtp: parametre.parametre_smtp,
        parametre_port: parametre.parametre_port,
        parametre_ssl: parametre.parametre_ssl,
        parametre_verifier_identite: parametre.parametre_verifier_identite,
        parametre_tls: parametre.parametre_tls,
        parametre_authentification: parametre.parametre_authentification,
        parametre_copie_agence: parametre.parametre_copie_agence,
        parametre_quittancement: parametre.parametre_quittancement,
        parametre_parcours_client: parametre.parametre_parcours_client,
        parametre_cacher_titre: parametre.parametre_cacher_titre,
        parametre_cacher_inactive: parametre.parametre_cacher_inactive,
        parametre_nombre_etapes: parametre.parametre_nombre_etapes,
        parametre_pointage_auto: parametre.parametre_pointage_auto,
        parametre_rapprochement_compte_id: parametre.parametre_rapprochement_compte_id,
        parametre_date_releve: formatDateToBDD(dateReleve),
        parametre_montant_releve: parametre.parametre_montant_releve,
        parametre_pointe_max: parametre.parametre_pointe_max,
        parametre_signature: parametre.parametre_signature,
        parametre_scheduler_row_height: parametre.parametre_scheduler_row_height,
        parametre_scheduler_row_margin: parametre.parametre_scheduler_row_margin,
        parametre_scheduler_day_before: parametre.parametre_scheduler_day_before,
        parametre_scheduler_day_after: parametre.parametre_scheduler_day_after,
        parametre_scheduler_afficher_reference: parametre.parametre_scheduler_afficher_reference,
        parametre_calcul_arrondi: parametre.parametre_calcul_arrondi,
        parametre_reddition_detaillee: parametre.parametre_reddition_detaillee,
        parametre_depenses_proprietaire: parametre.parametre_depenses_proprietaire,
        parametre_blocage_option: parametre.parametre_blocage_option,
        parametre_langue_id: parametre.parametre_langue_id,
        parametre_email_copie: parametre.parametre_email_copie,
        parametre_reddition_tva: parametre.parametre_reddition_tva,
      };

      this.props.updateParametre(jsonParametre, parametre.parametre_id);
    }
  };

  saveMontantReleve = () => {
    const parametre = this.props.parametre;
    const jsonParametre = {
      parametre_utilisateur: parametre.parametre_util,
      parametre_email: parametre.parametre_email,
      parametre_mdp: parametre.parametre_mdp,
      parametre_smtp: parametre.parametre_smtp,
      parametre_port: parametre.parametre_port,
      parametre_ssl: parametre.parametre_ssl,
      parametre_verifier_identite: parametre.parametre_verifier_identite,
      parametre_tls: parametre.parametre_tls,
      parametre_authentification: parametre.parametre_authentification,
      parametre_copie_agence: parametre.parametre_copie_agence,
      parametre_quittancement: parametre.parametre_quittancement,
      parametre_parcours_client: parametre.parametre_parcours_client,
      parametre_cacher_titre: parametre.parametre_cacher_titre,
      parametre_cacher_inactive: parametre.parametre_cacher_inactive,
      parametre_nombre_etapes: parametre.parametre_nombre_etapes,
      parametre_pointage_auto: parametre.parametre_pointage_auto,
      parametre_rapprochement_compte_id: parametre.parametre_rapprochement_compte_id,
      parametre_date_releve: parametre.parametre_date_releve,
      parametre_montant_releve: parseFloat(this.state.montantReleve),
      parametre_pointe_max: parametre.parametre_pointe_max,
      parametre_signature: parametre.parametre_signature,
      parametre_scheduler_row_height: parametre.parametre_scheduler_row_height,
      parametre_scheduler_row_margin: parametre.parametre_scheduler_row_margin,
      parametre_scheduler_day_before: parametre.parametre_scheduler_day_before,
      parametre_scheduler_day_after: parametre.parametre_scheduler_day_after,
      parametre_scheduler_afficher_reference: parametre.parametre_scheduler_afficher_reference,
      parametre_calcul_arrondi: parametre.parametre_calcul_arrondi,
      parametre_reddition_detaillee: parametre.parametre_reddition_detaillee,
      parametre_depenses_proprietaire: parametre.parametre_depenses_proprietaire,
      parametre_blocage_option: parametre.parametre_blocage_option,
      parametre_langue_id: parametre.parametre_langue_id,
      parametre_email_copie: parametre.parametre_email_copie,
      parametre_reddition_tva: parametre.parametre_reddition_tva,
    };

    this.props.updateParametre(jsonParametre, parametre.parametre_id);
  };

  afficherHistorique = () => {
    this.setState({
      modalHistoriqueVisible: true,
    });
  };

  closeModalHistorique = () => {
    this.setState({ modalHistoriqueVisible: false });
  };

  handleDelete = (id) => {
    this.props.deleteRapprochementBancaire(id);
  };

  onChangeDebit = (strDebit) => {
    this.setState({ strDebit });
  };

  onChangeCredit = (strCredit) => {
    this.setState({ strCredit });
  };

  onSearchDebit = (value) => {
    value
      ? this.setState((state) => {
          let lignesEcritures = state.lignesEcritures.filter(
            (ligne) =>
              parseFloat(ligne.ecriture_ligne_montant_debit) === parseFloat(value) &&
              (ligne.ecriture_ligne_lettrage === "" || ligne.ecriture_ligne_lettrage === null)
          );

          if (lignesEcritures.length === 0) {
            toast.error("Montant non trouvé", { containerId: "A" });
            lignesEcritures = state.lignesEcritures;
          }

          if (lignesEcritures.length === 1) {
            if (this.props.parametre.parametre_pointage_auto) {
              lignesEcritures[0].ecriture_ligne_lettrage =
                this.state.lettrage !== ""
                  ? this.state.lettrage
                  : this.state.dateReleve && dayjs(this.state.dateReleve).format("MM/YY");
              this.props
                .updateLigneEcriture(lignesEcritures[0], lignesEcritures[0].ecriture_ligne_id)
                .then(() => {
                  this.setState({ strDebit: "" });
                  toast.success("Écriture pointée", { containerId: "A" });
                });
            }
          }

          return {
            lignesEcritures,
          };
        })
      : this.init();
  };

  onSearchCredit = (value) => {
    value
      ? this.setState((state) => {
          let lignesEcritures = state.lignesEcritures.filter(
            (ligne) =>
              parseFloat(ligne.ecriture_ligne_montant_credit) === parseFloat(value) &&
              (ligne.ecriture_ligne_lettrage === "" || ligne.ecriture_ligne_lettrage === null)
          );

          if (lignesEcritures.length === 0) {
            toast.error("Montant non trouvé", { containerId: "A" });
            lignesEcritures = state.lignesEcritures;
          }

          if (lignesEcritures.length === 1) {
            if (this.props.parametre.parametre_pointage_auto) {
              lignesEcritures[0].ecriture_ligne_lettrage =
                this.state.lettrage !== ""
                  ? this.state.lettrage
                  : this.state.dateReleve && dayjs(this.state.dateReleve).format("MM/YY");
              this.props
                .updateLigneEcriture(lignesEcritures[0], lignesEcritures[0].ecriture_ligne_id)
                .then(() => {
                  this.setState({ strCredit: "" });
                  toast.success("Écriture pointée", { containerId: "A" });
                });
            }
          }

          return {
            lignesEcritures,
          };
        })
      : this.init();
  };

  onAnnulationLettrage = (value) => {
    if (value !== "") {
      if (
        !this.props.rapprochementsBancaires.some(
          (rappro) => dayjs(rappro.rapprochement_bancaire_date_releve).format("MM/YY") === value
        )
      ) {
        this.notify();
        this.prepareDepointage(0, this.state.lignesEcritures.length, value);
      } else {
        toast.error("Rapprochement validé pour cette date", { containerId: "A" });
      }
    }
  };

  onChangeLettrage = (value) => {
    this.setState({ lettrage: value });
  };

  prepareDepointage = (i, len, value) => {
    if (i < len) {
      if (this.state.lignesEcritures[i].ecriture_ligne_lettrage === value) {
        const ligne = this.state.lignesEcritures[i];
        ligne.ecriture_ligne_lettrage = null;
        this.props.updateLigneEcriture(ligne, ligne.ecriture_ligne_id).then(() => {
          this.prepareDepointage(i + 1, len, value);
        });
      } else {
        this.prepareDepointage(i + 1, len, value);
      }
    } else {
      toast.update(this.toastId.current, {
        render: `Dépointage (${value}) terminé !`,
        type: toast.TYPE.SUCCESS,
        closeButton: null, // The closeButton defined on ToastContainer will be used
      });
    }
  };

  setBackgroundColor = (item) => {
    if (item.ecriture_ligne_lettrage !== "" && item.ecriture_ligne_lettrage !== null) {
      return "editable-row row-rb-edited-bg";
    } else {
      return "editable-row";
    }
  };

  render() {
    const {
      loading,
      refresh,
      ecritureState,
      lignesEcritures,
      lignesCalcul,
      modalEcriture,
      modalPdf,
      idPdf,
      societe,
      compteGeneral,
      dateReleve,
      montantReleve,
      dateDu,
      dateAu,
      filtres,
      modalHistoriqueVisible,
      strDebit,
      strCredit,
    } = this.state;
    const { utilisateur, pointage, parametre, societes, comptes, rapprochementsBancaires } =
      this.props;

    const admin = utilisateur.utilisateur_admin;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows });
      },
    };

    let lignesEcrituresFiltered = [];
    // Filtres pointées / non pointées
    if (filtres.length === 0) {
      lignesEcrituresFiltered = [];
    } else if (filtres.length === 1) {
      filtres[0] === "1"
        ? (lignesEcrituresFiltered = lignesEcritures.filter(
            (ligne) =>
              ligne.ecriture_ligne_lettrage !== "" && ligne.ecriture_ligne_lettrage !== null
          ))
        : (lignesEcrituresFiltered = lignesEcritures.filter(
            (ligne) =>
              ligne.ecriture_ligne_lettrage === "" || ligne.ecriture_ligne_lettrage === null
          ));
    } else {
      lignesEcrituresFiltered = lignesEcritures;
    }

    let lignesEcrituresCsv = lignesEcrituresFiltered.map((ligne) => ({ ...ligne }));
    lignesEcrituresCsv = lignesEcrituresCsv.map((ligne) => {
      ligne.ecriture_ligne_montant_debit = parseFloat(ligne.ecriture_ligne_montant_debit)
        .toFixed(2)
        .replace(".", ",");
      ligne.ecriture_ligne_montant_credit = parseFloat(ligne.ecriture_ligne_montant_credit)
        .toFixed(2)
        .replace(".", ",");
      ligne.ecriture_ligne_lettrage = `'${ligne.ecriture_ligne_lettrage}`;
      return ligne;
    });

    let totalDebitC = 0;
    let totalCreditC = 0;
    let totalSoldeDebitC = 0;
    let totalSoldeCreditC = 0;
    let totalSoldeC = 0;

    let montantReleveD = 0;
    let montantReleveC = 0;

    let totalSoldeDebitP = 0;
    let totalSoldeCreditP = 0;
    let totalSoldeP = 0;

    let totalSoldeA = 0;

    totalDebitC = round(
      lignesCalcul.reduce((a, b) => a + parseFloat(b.ecriture_ligne_montant_debit), 0),
      2
    );
    totalCreditC = round(
      lignesCalcul.reduce((a, b) => a + parseFloat(b.ecriture_ligne_montant_credit), 0),
      2
    );
    totalSoldeC = round(totalCreditC - totalDebitC, 2);

    if (totalSoldeC < 0) {
      totalSoldeDebitC = Math.abs(totalSoldeC);
    } else if (totalSoldeC > 0) {
      totalSoldeCreditC = totalSoldeC;
    }

    if (montantReleve < 0) {
      montantReleveD = Math.abs(montantReleve);
    } else if (montantReleve > 0) {
      montantReleveC = montantReleve;
    }

    totalSoldeA = round(
      lignesEcritures
        .filter(
          (ecriture) =>
            ecriture.ecriture_ligne_lettrage === "" || ecriture.ecriture_ligne_lettrage === null
        )
        .reduce(
          (a, b) => a + (b.ecriture_ligne_montant_debit - b.ecriture_ligne_montant_credit),
          0
        ),
      2
    );

    totalSoldeP = parseFloat(montantReleve) + totalSoldeA + totalSoldeC;
    totalSoldeP = Number(totalSoldeP.toFixed(2));

    if (totalSoldeP < 0) {
      totalSoldeDebitP = Math.abs(totalSoldeP);
    } else if (totalSoldeP > 0) {
      totalSoldeCreditP = totalSoldeP;
    }

    const options = [
      { label: "Pointées", value: "1" },
      { label: "Non Pointées", value: "2" },
    ];

    let columnsA = [
      {
        title: "Compte Général",
        dataIndex: "ecriture_ligne_compte",
        key: "ecriture_ligne_compte",
        render: (compte) => compte.compte_compte_general,
        align: "right",
        width: 150,
      },
      {
        title: "N°Ecriture",
        dataIndex: "ecriture_id",
        key: "ecriture_id",
        align: "right",
        width: 100,
      },
      {
        title: "Date",
        dataIndex: "ecriture_date_ecriture",
        key: "ecriture_date_ecriture",
        sorter: (a, b) =>
          dayjs(a.ecriture_date_ecriture, "DD/MM/YYYY").unix() -
          dayjs(b.ecriture_date_ecriture, "DD/MM/YYYY").unix(),
        width: 150,
      },
      {
        title: "Libellé",
        dataIndex: "ecriture_ligne_libelle",
        key: "ecriture_ligne_libelle",
        ...this.getColumnSearchProps("ecriture_ligne_libelle"),
        sorter: (a, b) => a.ecriture_ligne_libelle.localeCompare(b.ecriture_ligne_libelle),
      },
      {
        title: "Jrn",
        dataIndex: "ecriture_journal_code",
        key: "ecriture_journal_code",
        width: 80,
      },
      {
        title: "Montant Débit",
        dataIndex: "ecriture_ligne_montant_debit",
        key: "ecriture_ligne_montant_debit",
        ...this.getColumnDecimalSearchProps("ecriture_ligne_montant_debit"),
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        sorter: (a, b) => a.ecriture_ligne_montant_debit - b.ecriture_ligne_montant_debit,
        width: 150,
      },
      {
        title: "Montant Crédit",
        dataIndex: "ecriture_ligne_montant_credit",
        key: "ecriture_ligne_montant_credit",
        ...this.getColumnDecimalSearchProps("ecriture_ligne_montant_credit"),
        render: (montant) =>
          parseFloat(montant) !== 0 ? (
            parseFloat(montant).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })
          ) : (
            <></>
          ),
        className: "column-money",
        align: "right",
        sorter: (a, b) => a.ecriture_ligne_montant_credit - b.ecriture_ligne_montant_credit,
        width: 150,
      },
      {
        title: "Pointage",
        dataIndex: "ecriture_ligne_lettrage",
        key: "ecriture_ligne_lettrage",
        ...this.getColumnSearchProps("ecriture_ligne_lettrage"),
        editable: true,
        render: (ecriture_ligne_lettrage, row) => (
          <EditableRow index={row.ecriture_ligne_id}>
            <EditableCell
              title="Pointage"
              editable={true}
              children={ecriture_ligne_lettrage}
              dataIndex="ecriture_ligne_lettrage"
              record={row}
              handleSave={this.handleSave}
            />
          </EditableRow>
        ),
        filters: [
          { text: "Pointées", value: true },
          { text: "Non Pointées", value: false },
        ],
        // onFilter: (value, record) =>
        //   value ? record.ecriture_ligne_lettrage === "" : record.ecriture_ligne_lettrage !== "",
        align: "right",
        sorter: (a, b) =>
          a.ecriture_ligne_lettrage &&
          a.ecriture_ligne_lettrage.localeCompare(b.ecriture_ligne_lettrage),
        width: 150,
      },
      {
        title: "Pointage",
        dataIndex: "ecriture_ligne_id",
        key: "ecriture_ligne_id",
        render: (ecriture_ligne_id, row) => (
          <Button
            onClick={(e) =>
              row.ecriture_ligne_lettrage
                ? this.handleClickDelettrage(e, row)
                : this.handleClickLettrage(e, row)
            }
          >
            {row.ecriture_ligne_lettrage ? <StopOutlined /> : <EditOutlined />}
          </Button>
        ),
        align: "center",
        width: 150,
      },
      {
        title: "Visualisation",
        dataIndex: "ecriture_id",
        key: "ecriture_id",
        render: (ecriture_id) => (
          <Button onClick={(e) => this.handleClickVisualisation(e, ecriture_id)}>
            <EyeOutlined />
          </Button>
        ),
        align: "center",
        width: 150,
      },
    ];

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = columnsA.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <Spin spinning={loading} size="large" tip="Chargement...">
        {!pointage && (
          <Modal
            width={800}
            open={modalHistoriqueVisible}
            title="Historique des rapprochements bancaires validés"
            onCancel={this.closeModalHistorique}
          >
            <Row>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                rowKey="rapprochement_bancaire_id"
                dataSource={rapprochementsBancaires}
              >
                <Column
                  title="Date relevé"
                  dataIndex="rapprochement_bancaire_date_releve"
                  key="releve"
                  render={(date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                />
                <Column
                  title="Montant relevé"
                  dataIndex="rapprochement_bancaire_montant_releve"
                  key="montant"
                  align="right"
                  render={(montant) =>
                    parseFloat(montant).toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    })
                  }
                />
                <Column
                  title="Date validation"
                  dataIndex="rapprochement_bancaire_date_validation"
                  key="validation"
                  render={(date) => dayjs(date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                />
                <Column
                  title="Afficher"
                  dataIndex="rapprochement_bancaire_id"
                  key="x"
                  render={(id) => (
                    <Button href={`${Constants.directory}rapprochements/${id}.pdf`} target="_blank">
                      <FolderViewOutlined />
                    </Button>
                  )}
                />
                <Column
                  title="Supprimer"
                  dataIndex="rapprochement_bancaire_id"
                  key="y"
                  render={(id) => (
                    <Popconfirm
                      title="Êtes vous sûr de vouloir supprimer ce rapprochement bancaire ?"
                      onConfirm={() => this.handleDelete(id)}
                    >
                      <Button>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  )}
                />
              </Table>
            </Row>
          </Modal>
        )}

        {!pointage && (
          <Modal footer={null} title="Rapprochement" visible={modalPdf} onCancel={this.closeModal}>
            {idPdf && (
              <Button type="text" onClick={() => openPdf(idPdf)}>
                Rapprochement bancaire
              </Button>
            )}
          </Modal>
        )}

        <Modal
          footer={null}
          title="Visualisation"
          width={1000}
          open={modalEcriture}
          onCancel={this.closeModalEcriture}
        >
          {ecritureState && (
            <Operation edit={false} readOnly={true} ecritureProps={ecritureState} />
          )}
        </Modal>

        {/* <Spin spinning={loading} size="large" tip="Chargement..."> */}
        <Form ref={this.formRef} onFinish={this.onFinish} size="large" style={{ padding: 20 }}>
          <Row gutter={12}>
            <Col span={12}>
              <FloatLabel label="Société" name="societe" value={societe}>
                <Form.Item name="societe_id">
                  <Select
                    showSearch
                    optionFilterProp="label"
                    optionLabelProp="label"
                    onChange={(id) => this.setState({ societe: id })}
                  >
                    <OptGroup label="Société">
                      {societes &&
                        societes.map((item, i) => {
                          return (
                            <Option
                              key={i}
                              value={item.societe_id}
                              label={item.societe_raison_sociale}
                            >
                              <Row>
                                <Col sm={8}>{item.societe_raison_sociale}</Col>
                              </Row>
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            {!pointage && (
              <Col span={12}>
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => this.afficherHistorique()}
                >
                  Historique
                </Button>
              </Col>
            )}
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              {comptes && (
                <FloatLabel label="Compte général" name="compteGeneral" value={compteGeneral}>
                  <Form.Item name="compte_general">
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      optionLabelProp="label"
                      onChange={(value) => this.onChangeCompteGeneral(value)}
                    >
                      <OptGroup label="Compte">
                        {comptes
                          .filter((item) => item.compte_type_compte === "G")
                          .map((item, i) => (
                            <Option
                              key={i}
                              value={item.compte_id}
                              label={`${item.compte_compte_general} - ${item.compte_libelle}`}
                            >
                              <Row>
                                <Col
                                  sm={8}
                                >{`${item.compte_compte_general} - ${item.compte_libelle}`}</Col>
                              </Row>
                            </Option>
                          ))}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                </FloatLabel>
              )}
            </Col>
            {!pointage && (
              <Col span={12}>
                <Button
                  type="primary"
                  disabled={!dateAu}
                  style={{ float: "right" }}
                  onClick={() => this.createPdf()}
                >
                  Édition du rapprochement
                </Button>
              </Col>
            )}
          </Row>

          {!pointage && (
            <Row gutter={12}>
              <Col span={6}>
                <FloatLabel label="Date relevé" name="dateReleve" value={dateReleve}>
                  <Form.Item
                    name="date_releve"
                    ules={{ required: true, message: "Date relevé obligatoire" }}
                  >
                    <DatePicker
                      format={dateFormat}
                      placeholder=""
                      onChange={(e) => this.onChangeDateReleve(e)}
                    />
                  </Form.Item>
                </FloatLabel>
              </Col>
              <Col span={6}>
                <FloatLabel label="Montant relevé" name="montantReleve" value={montantReleve}>
                  <Form.Item name="montant_releve">
                    <Input
                      type="number"
                      style={{ width: "100%" }}
                      onChange={(e) => this.onChangeMontantReleve(e)}
                      onBlur={this.saveMontantReleve}
                    />
                  </Form.Item>
                </FloatLabel>
              </Col>
              <Col span={12}>
                <Popconfirm
                  title={`Attention, si vous validez le rapprochement au ${dateAu?.format(
                    "DD/MM/YYYY"
                  )}, vous ne pourrez plus saisir ou modifier une écriture dont la date comptable est antérieure ou égale à la date du ${dateAu?.format(
                    "DD/MM/YYYY"
                  )}`}
                  disabled={!dateAu && !dateReleve}
                  onConfirm={() => this.handleSubmitRapprochement(totalSoldeP)}
                  cancelText="Annuler"
                >
                  <Button
                    type="primary"
                    disabled={!dateAu && !dateReleve}
                    style={{ float: "right" }}
                  >
                    Validation
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          )}

          <Row gutter={12}>
            <Col span={6}>
              <FloatLabel label="Du" name="dateDu" value={dateDu}>
                <Form.Item name="date_du">
                  <DatePicker
                    format={dateFormat}
                    placeholder=""
                    onChange={(e) => this.onChangeDateDu(e)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={6}>
              <FloatLabel label="Au" name="dateAu" value={dateAu}>
                <Form.Item name="date_au">
                  <DatePicker
                    format={dateFormat}
                    placeholder=""
                    onChange={(e) => this.onChangeDateAu(e)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col span={2}>
              <RedoOutlined onClick={this.onRefresh} spin={refresh} style={{ fontSize: "32px" }} />
            </Col>

            <Col offset={7} span={3}>
              <Checkbox.Group
                options={options}
                defaultValue={["2"]}
                onChange={this.onChangeFilter}
              />
            </Col>
          </Row>

          <Row gutter={12} style={{ marginBottom: 10 }}>
            {!pointage && (
              <>
                <Col span={6}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Search
                      placeholder={`RECHERCHE DÉBIT ${
                        parametre.parametre_pointage_auto ? "(POINTAGE AUTO)" : ""
                      }`}
                      allowClear
                      value={strDebit}
                      onChange={(e) => this.onChangeDebit(e.target.value)}
                      onSearch={this.onSearchDebit}
                    />
                  </Space>
                </Col>
                <Col span={6}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Search
                      placeholder={`RECHERCHE CRÉDIT ${
                        parametre.parametre_pointage_auto ? "(POINTAGE AUTO)" : ""
                      }`}
                      allowClear
                      value={strCredit}
                      onChange={(e) => this.onChangeCredit(e.target.value)}
                      onSearch={this.onSearchCredit}
                    />
                  </Space>
                </Col>
                <Col offset={4} span={2}>
                  <Button>
                    <CSVLink
                      filename={"liste-ecritures.csv"}
                      data={lignesEcrituresCsv}
                      headers={headers()}
                      separator={";"}
                    >
                      Export CSV
                    </CSVLink>
                  </Button>
                </Col>
              </>
            )}
            <Col span={4}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Search
                  disabled={!admin}
                  placeholder="ANNUL. POINTAGE"
                  allowClear
                  onSearch={this.onAnnulationLettrage}
                />
              </Space>
            </Col>
            <Col span={2}>
              <Input
                placeholder="LETTRAGE"
                allowClear
                onChange={(e) => this.onChangeLettrage(e.target.value)}
              />
            </Col>
          </Row>

          <VirtualTable
            className="table-ecritures"
            components={components}
            columns={columns}
            dataSource={lignesEcrituresFiltered}
            rowKey="ecriture_ligne_id"
            rowClassName={(record) => this.setBackgroundColor(record)}
            scroll={{
              y: 600,
              x: "100vw",
            }}
            summary={() => {
              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <strong>SOLDE comptabilité</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(totalSoldeDebitC).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(totalSoldeCreditC).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <strong>SOLDE relevé</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(montantReleveD).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(montantReleveC).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>

                  <Table.Summary.Row>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <strong>ECART rapprochement</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(totalSoldeDebitP).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ float: "right" }}>
                        <strong>
                          {parseFloat(totalSoldeCreditP).toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </strong>
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </Form>
      </Spin>
    );
  }
}

export default FormRapprochementBancaire;
