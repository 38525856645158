import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row, Select, Tooltip } from "antd";
import { CheckOutlined, DeleteOutlined, PlusOutlined, ZoomInOutlined } from "@ant-design/icons";
import FormPersonneLarge from "../FormPersonneLarge";

import { getInfoLocataire, getInfoProprietaire } from "../../utils/tools";

import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

const { Option, OptGroup } = Select;

const SelectPersonne = (props) => {
  const [personne, setPersonne] = useState(null);
  const [edition, setEdition] = useState(false);
  const [code, setCode] = useState(null);
  const {
    utilisateur,
    personneProps,
    loading,
    proprietaire,
    tabCompta,
    readOnly,
    societes,
    etablissements,
    qualites,
    langues,
    provenances,
    typesPaiements,
    listData,
    ecritures,
    documents,
    tags,
    locations,
    reglements,
    quittances,
    parametre,
    templates,
    templatesLangues,
    lots,
    evenements,
    words,
  } = props;

  // Référence du formulaire
  const formRefPersonne = useRef();

  useEffect(() => {
    listData && personneProps
      ? onSelectPer(proprietaire ? personneProps.proprietaire_id : personneProps.locataire_id)
      : getCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, personneProps, evenements]);

  function onClickAddPer() {
    setPersonne(null);
    getCode();
    setEdition(true);
  }

  function disablePersonne(personne) {
    onClickAddPer();
    props.archivePersonne(
      proprietaire ? personne.proprietaire_id : personne.locataire_id,
      proprietaire
    );
  }

  function onSelectPer(id) {
    formRefPersonne.current.setFieldsValue({ personne_id: id });
    const personne = props.listData?.find((e) =>
      props.proprietaire ? e.proprietaire_id === id : e.locataire_id === id
    );
    setPersonne(personne);
    setEdition(false);
  }

  function getCode() {
    proprietaire ? setCode(generateCodeProprietaire()) : setCode(generateCodeLocataire());
  }

  /** Pour un nouveau proprietaire un code est généré sur 6 caractères
   *  avec des "0" qui complètent le proprietaire_id */
  function generateCodeProprietaire() {
    let maxCode = 0;
    let maxNum = 0;
    let code = "";
    props.listData &&
      props.listData.forEach((item) => {
        if (parseInt(item.proprietaire_code) > parseInt(maxCode)) maxCode = item.proprietaire_code;
      });
    maxNum = parseInt(maxCode) + 1;
    for (var i = 0; i < 6 - maxNum.toString().length; i++) {
      code = code + "0";
    }
    return code + maxNum;
  }

  function generateCodeLocataire() {
    let maxCode = 0;
    let maxNum = 0;
    let code = "";
    props.listData &&
      props.listData.forEach((item) => {
        if (parseInt(item.locataire_code) > parseInt(maxCode)) maxCode = item.locataire_code;
      });
    maxNum = parseInt(maxCode) + 1;
    for (var i = 0; i < 6 - maxNum.toString().length; i++) {
      code = code + "0";
    }
    return code + maxNum;
  }

  const createExtranet = () => {
    const app = initializeApp({
      projectId: "extranet-aster-51",
      apiKey: "AIzaSyDdPlXpC9MYU7-nBADYvczCpZuYXSXL8Yk",
      authDomain: "extranet-aster-51.firebaseapp.com",
    });
    const functions = getFunctions(app);
    const addClaims = httpsCallable(functions, "addClaims");

    const password = `${personne.proprietaire_personne.personne_nom
      .replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase()}${personne.proprietaire_code}`;

    const userInfo = {
      email: personne.proprietaire_personne.personne_email1,
      name: personne.proprietaire_personne.personne_nom,
      proprietaire: personne.proprietaire_id.toString(),
      groupement: utilisateur.groupement_id,
      uid: "",
      phoneNumber: "",
      password: password,
      admin: false,
    };

    const data = {
      proprietaire_password_extranet: password,
    };

    addClaims(userInfo).then(() => props.updateProprietaire(data, personne.proprietaire_id));
  };

  const addPersonneButton = (
    <Button type="primary" htmlType="reset" onClick={onClickAddPer}>
      <PlusOutlined />
    </Button>
  );

  const archiverPersonne = (
    <Button type="primary" htmlType="reset" onClick={() => disablePersonne(personne)}>
      <DeleteOutlined />
    </Button>
  );

  const rechercheDispo = (
    <Button type="primary" htmlType="reset" onClick={() => searchAvailable(personne)}>
      <ZoomInOutlined />
    </Button>
  );

  const afficherAppartement = (appartement) => {
    props.addPanesLot(appartement);
  };

  const searchAvailable = (personne) => {
    if (personne) {
      const requetes = props.requetes
        .filter((r) => r.requete_locataire.locataire_id === personne.locataire_id)
        .sort((a, b) => b.requete_id - a.requete_id);
      const req = requetes[0];

      const pane = {
        key: "26",
        title: "Contrats / Disponibilités",
        content: "",
      };
      props.addPanesRequete(pane, "26", req, false);
    }
  };

  const codeExist = (code, id) => {
    return proprietaire
      ? listData.some((data) => data.proprietaire_code === code && data.proprietaire_id !== id)
      : listData.some((data) => data.locataire_code === code && data.locataire_id !== id);
  };

  return (
    <div style={{ padding: 20 }}>
      <Form ref={formRefPersonne}>
        <Row>
          <Col span={11}>
            <Form.Item name="personne_id">
              <Select
                showSearch
                disabled={readOnly}
                optionLabelProp="label"
                placeholder={proprietaire ? "Propriétaire" : "Locataire"}
                onSelect={onSelectPer}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <OptGroup label={proprietaire ? "Proprietaire" : "Locataire"}>
                  {listData &&
                    listData.map((item, i) => {
                      return (
                        <Option
                          key={i}
                          value={proprietaire ? item.proprietaire_id : item.locataire_id}
                          label={proprietaire ? getInfoProprietaire(item) : getInfoLocataire(item)}
                        >
                          <Row>
                            <Col sm={8}>
                              {proprietaire ? getInfoProprietaire(item) : getInfoLocataire(item)}
                            </Col>
                          </Row>
                        </Option>
                      );
                    })}
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
          <Col span={1}>{!readOnly && <Form.Item>{addPersonneButton}</Form.Item>}</Col>
          <Col span={1}>{!readOnly && personne && <Form.Item>{archiverPersonne}</Form.Item>}</Col>
          <Col span={1}>
            {!readOnly && personne && !proprietaire && <Form.Item>{rechercheDispo}</Form.Item>}
          </Col>
          <Col offset={8} span={2}>
            {!readOnly && personne && proprietaire && (
              <>
                {personne.proprietaire_password_extranet ? (
                  <Tooltip placement="bottom" title={personne.proprietaire_password_extranet}>
                    <Button type="primary" style={{ width: "100%" }} icon={<CheckOutlined />}>
                      Extranet
                    </Button>
                  </Tooltip>
                ) : (
                  <Button type="error" onClick={createExtranet}>
                    Créer accès extranet
                  </Button>
                )}
              </>
            )}
          </Col>
        </Row>
      </Form>
      {(personne || code) && (
        <FormPersonneLarge
          readOnly={readOnly}
          code={code}
          edition={edition}
          utilisateur={utilisateur}
          proprietaire={proprietaire}
          personne={personne}
          loading={loading}
          tabCompta={tabCompta}
          societes={societes}
          etablissements={etablissements}
          qualites={qualites}
          langues={langues}
          provenances={provenances}
          typesPaiements={typesPaiements}
          ecritures={ecritures}
          documents={documents}
          tags={tags}
          locationsFiltered={
            !proprietaire && personne
              ? locations
                  ?.filter(
                    (item) =>
                      item.location_requete &&
                      item.location_requete.requete_locataire.locataire_id === personne.locataire_id
                  )
                  .sort((a, b) => b.location_id - a.location_id)
              : []
          }
          reglements={reglements}
          quittances={quittances}
          parametre={parametre}
          evenements={evenements}
          loadEcritures={props.loadEcritures}
          handleSubmit={props.savePersonne}
          handleEdit={props.updatePersonne}
          saveDocument={props.saveDocument}
          deleteDocument={props.deleteDocument}
          saveTag={props.saveTag}
          updateTag={props.updateTag}
          deleteTag={props.deleteTag}
          addPanesRequete={props.addPanesRequete}
          templates={templates}
          templatesLangues={templatesLangues}
          lotsFilter={
            proprietaire && personne && lots
              ? lots.filter(
                  (item) => item.lot_proprietaire.proprietaire_id === personne.proprietaire_id
                )
              : []
          }
          codeExist={codeExist}
          afficherAppartement={afficherAppartement}
          saveEmail={props.saveEmail}
          deleteEmail={props.deleteEmail}
          saveEvenement={props.saveEvenement}
          updateEvenement={props.updateEvenement}
          deleteEvenement={props.deleteEvenement}
          words={words}
          processWord={props.processWord}
        />
      )}
    </div>
  );
};

export default SelectPersonne;
