import React, { Component } from "react";
import { connect } from "react-redux";

import { saveDescription, updateDescription } from "../../store/actions/descriptionAction";
import {
  saveLot,
  updateLot,
  loadLots,
  archiverLot,
  updateLotNuitees,
  desarchiverLot,
} from "../../store/actions/lotAction";
import { savePhoto, updatePhotos, deletePhoto } from "../../store/actions/photoAction";

import { Card, Spin } from "antd";
import SelectLot from "../../components/SelectLot";

import { saveUpdateLotsChampsPersos } from "../../store/actions/lotChampPersoAction";
import { savePiece } from "../../store/actions/pieceAction";
import { updatePiece } from "../../store/actions/pieceAction";
import { deletePiece } from "../../store/actions/pieceAction";
import { savePiecesContenus } from "../../store/actions/pieceContenuAction";
import { updatePiecesContenus } from "../../store/actions/pieceContenuAction";
import { saveDossier, updateDossier } from "../../store/actions/dossierAction";
import {
  saveLocation,
  updateLocation,
  deleteLocation,
  loadLocations,
} from "../../store/actions/locationAction";
import {
  saveEquipement,
  updateEquipement,
  deleteEquipement,
} from "../../store/actions/equipementAction";
import {
  saveLotsEquipements,
  deleteLotsEquipements,
} from "../../store/actions/lotEquipementAction";
import {
  deleteLigneMatrice,
  saveLigneMatrice,
  updateLigneMatrice,
} from "../../store/actions/matriceLigneAction";
import { deletePrixBase, savePrixBase, updatePrixBase } from "../../store/actions/prixBaseAction";
import { deleteNuite, saveNuite, updateNuite } from "../../store/actions/nuiteAction";
import { saveMatrice } from "../../store/actions/matriceAction";
import { saveRequete } from "../../store/actions/requeteAction";
import { saveRequeteLot } from "../../store/actions/requeteLotAction";

import { uploadICal } from "../../store/actions/documentAction";
import { loadProprietaires } from "../../store/actions/proprietaireAction";
import { loadImmeubles } from "../../store/actions/immeubleAction";
import { loadLocataires, saveLocataire } from "../../store/actions/locataireAction";
import {
  saveChampPerso,
  updateChampPerso,
  deleteChampPerso,
} from "../../store/actions/champPersoAction";

import { saveDocument, deleteDocument } from "../../store/actions/documentAction";
import { saveTag, updateTag, deleteTag } from "../../store/actions/tagAction";

import { toast } from "react-toastify";

import { saveEmail } from "../../store/actions/emailAction";
import { loadQuittances } from "../../store/actions/quittanceAction";
import { loadReglements } from "../../store/actions/reglementAction";

import { saveRenduA } from "../../store/actions/renduAction";
import { deleteLotNote, saveLotNote, updateLotNote } from "../../store/actions/lotNoteAction";
import { TYPE_TAG } from "../../utils/constants";

class Lots extends Component {
  state = {};

  componentDidMount() {
    const groupement_id = this.props.utilisateur.groupement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadProprietaires(groupement_id);
    this.props.loadLots(societe_id);
    this.props.loadLocataires(groupement_id);
    this.props.loadLocations(groupement_id);
    this.props.loadQuittances(groupement_id);
    this.props.loadReglements(societe_id);
    this.props.loadImmeubles(groupement_id);
  }

  saveLocataire = (data) => {
    this.props
      .saveLocataire(data)
      .then((res) => toast.success("Locataire créé !", { containerId: "A" }))
      .catch((err) => toast.error("Échec lors de la création !", { containerId: "A" }));
  };

  archiverLot = (id) => {
    this.props
      .archiverLot(id)
      .then((res) => toast.success("Lot archivé !", { containerId: "A" }))
      .catch((err) => toast.error("Échec lors de l'archivage !", { containerId: "A" }));
  };

  render() {
    const {
      lotProps,
      readOnly,
      utilisateur,
      societes,
      etablissements,
      typesLots,
      proprietaires,
      immeubles,
      equipements,
      champsPersosGroupes,
      champsPersos,
      langues,
      pieces,
      locations,
      rubriques,
      lots,
      locataires,
      qualites,
      documents,
      tags,
      quittances,
      reglements,
      parametre,
      groupes,
      provenances,
      parcoursClients,
    } = this.props;

    return (
      <Spin
        size="large"
        tip="Chargement en cours..."
        spinning={
          !proprietaires ||
          !lots ||
          !locataires ||
          !locations ||
          !quittances ||
          !reglements ||
          !immeubles
        }
      >
        <Card title="" bordered={false} className="card-panel">
          <SelectLot
            lotProps={lotProps}
            readOnly={readOnly}
            utilisateur={utilisateur}
            societes={societes}
            etablissements={etablissements}
            parametre={parametre}
            groupes={groupes}
            provenances={provenances}
            typesLots={typesLots}
            proprietaires={proprietaires}
            immeubles={immeubles}
            equipements={equipements}
            champsPersosGroupes={champsPersosGroupes}
            champsPersos={champsPersos}
            langues={langues}
            pieces={pieces}
            locations={
              locations &&
              locations.filter(
                (item) => item.location_lot.lot_societe_id === utilisateur.societe_id
              )
            }
            quittances={quittances}
            reglements={reglements}
            rubriques={rubriques}
            listData={lots && lots.sort((a, b) => a.lot_reference.localeCompare(b.lot_reference))}
            locataires={locataires}
            documents={documents}
            tags={tags.filter((tag) => tag.tag_type === TYPE_TAG[1].value)}
            qualites={qualites}
            parcoursClients={parcoursClients}
            saveRenduA={this.props.saveRenduA}
            saveDocument={this.props.saveDocument}
            deleteDocument={this.props.deleteDocument}
            saveTag={this.props.saveTag}
            updateTag={this.props.updateTag}
            deleteTag={this.props.deleteTag}
            saveLocataire={this.props.saveLocataire}
            addPanesPersonne={this.props.addPanesPersonne}
            addPanesImmeuble={this.props.addPanesImmeuble}
            saveLot={this.props.saveLot}
            updateLot={this.props.updateLot}
            updateLotNuitees={this.props.updateLotNuitees}
            archiverLot={this.props.archiverLot}
            desarchiverLot={this.props.desarchiverLot}
            saveDescription={this.props.saveDescription}
            updateDescription={this.props.updateDescription}
            saveUpdateLotsChampsPersos={this.props.saveUpdateLotsChampsPersos}
            savePiece={this.props.savePiece}
            updatePiece={this.props.updatePiece}
            deletePiece={this.props.deletePiece}
            savePiecesContenus={this.props.savePiecesContenus}
            updatePiecesContenus={this.props.updatePiecesContenus}
            saveDossier={this.props.saveDossier}
            updateDossier={this.props.updateDossier}
            savePhoto={this.props.savePhoto}
            updatePhotos={this.props.updatePhotos}
            deletePhoto={this.props.deletePhoto}
            uploadICal={this.props.uploadICal}
            saveLocation={this.props.saveLocation}
            updateLocation={this.props.updateLocation}
            deleteLocation={this.props.deleteLocation}
            saveEquipement={this.props.saveEquipement}
            updateEquipement={this.props.updateEquipement}
            deleteEquipement={this.props.deleteEquipement}
            saveLotsEquipements={this.props.saveLotsEquipements}
            deleteLotsEquipements={this.props.deleteLotsEquipements}
            saveLigneMatrice={this.props.saveLigneMatrice}
            updateLigneMatrice={this.props.updateLigneMatrice}
            deleteLigneMatrice={this.props.deleteLigneMatrice}
            saveMatrice={this.props.saveMatrice}
            savePrixBase={this.props.savePrixBase}
            updatePrixBase={this.props.updatePrixBase}
            deletePrixBase={this.props.deletePrixBase}
            saveNuite={this.props.saveNuite}
            updateNuite={this.props.updateNuite}
            deleteNuite={this.props.deleteNuite}
            saveRequete={this.props.saveRequete}
            saveRequeteLot={this.props.saveRequeteLot}
            addPanesRequete={this.props.addPanesRequete}
            saveChampPerso={this.props.saveChampPerso}
            updateChampPerso={this.props.updateChampPerso}
            deleteChampPerso={this.props.deleteChampPerso}
            saveEmail={this.props.saveEmail}
            saveLotNote={this.props.saveLotNote}
            updateLotNote={this.props.updateLotNote}
            deleteLotNote={this.props.deleteLotNote}
          />
        </Card>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadProprietaires: (groupement_id) => dispatch(loadProprietaires(groupement_id)),
    loadImmeubles: (groupement_id) => dispatch(loadImmeubles(groupement_id)),
    loadLots: (societe_id) => dispatch(loadLots(societe_id)),
    loadLocataires: (groupement_id) => dispatch(loadLocataires(groupement_id)),
    loadLocations: (groupement_id) => dispatch(loadLocations(groupement_id)),
    loadQuittances: (groupement_id) => dispatch(loadQuittances(groupement_id)),
    loadReglements: (societe_id) => dispatch(loadReglements(societe_id)),
    saveLocataire: (jsonData) => dispatch(saveLocataire(jsonData)),
    saveLot: (jsonData) => dispatch(saveLot(jsonData)),
    updateLot: (jsonData, id) => dispatch(updateLot(jsonData, id)),
    updateLotNuitees: (jsonData, id) => dispatch(updateLotNuitees(jsonData, id)),
    saveDescription: (jsonData, lot_id) => dispatch(saveDescription(jsonData, lot_id)),
    updateDescription: (jsonData, idLot, idLangue) =>
      dispatch(updateDescription(jsonData, idLot, idLangue)),
    saveUpdateLotsChampsPersos: (jsonData, idL) =>
      dispatch(saveUpdateLotsChampsPersos(jsonData, idL)),
    savePiece: (jsonData) => dispatch(savePiece(jsonData)),
    updatePiece: (jsonData, id) => dispatch(updatePiece(jsonData, id)),
    deletePiece: (id) => dispatch(deletePiece(id)),
    savePiecesContenus: (jsonData) => dispatch(savePiecesContenus(jsonData)),
    updatePiecesContenus: (jsonData, idPiece, idChampPerso) =>
      dispatch(updatePiecesContenus(jsonData, idPiece, idChampPerso)),
    saveDossier: (jsonData) => dispatch(saveDossier(jsonData)),
    updateDossier: (jsonData, idLot) => dispatch(updateDossier(jsonData, idLot)),
    savePhoto: (jsonData, order, lot_id) => dispatch(savePhoto(jsonData, order, lot_id)),
    updatePhotos: (jsonData, id) => dispatch(updatePhotos(jsonData, id)),
    deletePhoto: (id) => dispatch(deletePhoto(id)),
    uploadICal: (jsonData, lot) => dispatch(uploadICal(jsonData, lot)),
    saveLocation: (jsonData) => dispatch(saveLocation(jsonData)),
    updateLocation: (jsonData, id) => dispatch(updateLocation(jsonData, id)),
    deleteLocation: (id) => dispatch(deleteLocation(id)),
    saveEquipement: (jsonData) => dispatch(saveEquipement(jsonData)),
    updateEquipement: (jsonData, id) => dispatch(updateEquipement(jsonData, id)),
    deleteEquipement: (id) => dispatch(deleteEquipement(id)),
    saveLotsEquipements: (idLot, idEquipement) =>
      dispatch(saveLotsEquipements(idLot, idEquipement)),
    deleteLotsEquipements: (idLot, idEquipement) =>
      dispatch(deleteLotsEquipements(idLot, idEquipement)),
    saveLigneMatrice: (jsonData, lot) => dispatch(saveLigneMatrice(jsonData, lot)),
    updateLigneMatrice: (jsonData, idMatrice, idRubrique, lot) =>
      dispatch(updateLigneMatrice(jsonData, idMatrice, idRubrique, lot)),
    deleteLigneMatrice: (idMatrice, idRubrique, lot) =>
      dispatch(deleteLigneMatrice(idMatrice, idRubrique, lot)),
    saveMatrice: (jsonData, lot) => dispatch(saveMatrice(jsonData, lot)),
    savePrixBase: (jsonData, lot) => dispatch(savePrixBase(jsonData, lot)),
    updatePrixBase: (jsonData, idPrixBase, idRubrique, lot) =>
      dispatch(updatePrixBase(jsonData, idPrixBase, idRubrique, lot)),
    deletePrixBase: (idPrixBase, lot) => dispatch(deletePrixBase(idPrixBase, lot)),
    saveNuite: (jsonData, lot) => dispatch(saveNuite(jsonData, lot)),
    updateNuite: (jsonData, idNuite, idRubrique, lot) =>
      dispatch(updateNuite(jsonData, idNuite, idRubrique, lot)),
    deleteNuite: (idNuite, lot) => dispatch(deleteNuite(idNuite, lot)),
    saveRequete: (jsonData) => dispatch(saveRequete(jsonData)),
    saveRequeteLot: (jsonData) => dispatch(saveRequeteLot(jsonData)),
    saveChampPerso: (jsonData) => dispatch(saveChampPerso(jsonData)),
    updateChampPerso: (jsonData, id) => dispatch(updateChampPerso(jsonData, id)),
    deleteChampPerso: (id) => dispatch(deleteChampPerso(id)),
    saveDocument: (jsonData, dossier, tag) => dispatch(saveDocument(jsonData, dossier, tag)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
    saveTag: (jsonData) => dispatch(saveTag(jsonData)),
    updateTag: (jsonData, id) => dispatch(updateTag(jsonData, id)),
    deleteTag: (id) => dispatch(deleteTag(id)),
    saveEmail: (jsonData) => dispatch(saveEmail(jsonData)),
    saveRenduA: (jsonData) => dispatch(saveRenduA(jsonData)),
    archiverLot: (id) => dispatch(archiverLot(id)),
    desarchiverLot: (id) => dispatch(desarchiverLot(id)),
    saveLotNote: (jsonData) => dispatch(saveLotNote(jsonData)),
    updateLotNote: (jsonData, id) => dispatch(updateLotNote(jsonData, id)),
    deleteLotNote: (id) => dispatch(deleteLotNote(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    societes: state.societes.data,
    etablissements: state.etablissements.data,
    parametre: state.parametre.data,
    groupes: state.groupes.data,
    provenances: state.provenances.data,
    langues: state.langues.data,
    typesLots: state.typesLots.data,
    proprietaires: state.proprietaires.data,
    immeubles: state.immeubles.data,
    lots: state.lots.data,
    locataires: state.locataires.data,
    equipements: state.equipements.data,
    champsPersosGroupes: state.champsPersosGroupes.data,
    champsPersos: state.champsPersos.data,
    pieces: state.pieces.data,
    locations: state.locations.data,
    rubriques: state.rubriques.data,
    qualites: state.qualites.data,
    documents: state.documents.data,
    tags: state.tags.data,
    quittances: state.quittances.data,
    reglements: state.reglements.data,
    parcoursClients: state.parcoursClients.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Lots);
