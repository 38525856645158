import React, { Component } from "react";
import { connect } from "react-redux";
import FormRapprochementBancaire from "../../../components/FormRapprochementBancaire";
import { loadComptes } from "../../../store/actions/compteAction";
import { loadEcritures, updateLigneEcriture } from "../../../store/actions/ecritureAction";
import { updateParametre } from "../../../store/actions/parametreAction";
import {
  saveRapprochementBancaire,
  loadRapprochementsBancaires,
  deleteRapprochementBancaire,
} from "../../../store/actions/rapprochementBancaireAction";
import dayjs from "dayjs";
import { Spin } from "antd";
import { uploadRapprochement } from "../../../store/actions/documentAction";

class RapprochementBancaire extends Component {
  componentDidMount() {
    this.props.loadComptes(this.props.utilisateur.societe_id);
    this.props.loadEcritures(this.props.utilisateur.societe_id);
    this.props.loadRapprochementsBancaires(this.props.utilisateur.societe_id);
  }

  render() {
    const {
      utilisateur,
      pointage,
      parametre,
      societes,
      comptes,
      ecritures,
      rapprochementsBancaires,
    } = this.props;

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!comptes || !ecritures}>
        <FormRapprochementBancaire
          utilisateur={utilisateur}
          pointage={pointage}
          parametre={parametre}
          societes={societes.filter((s) => s.societe_id === this.props.utilisateur.societe_id)}
          comptes={comptes}
          ecritures={
            ecritures &&
            ecritures.sort(
              (a, b) =>
                dayjs(a.ecriture_date_ecriture, "DD/MM/YYYY").unix() -
                dayjs(b.ecriture_date_ecriture, "DD/MM/YYYY").unix()
            )
          }
          updateParametre={this.props.updateParametre}
          updateLigneEcriture={this.props.updateLigneEcriture}
          saveRapprochementBancaire={this.props.saveRapprochementBancaire}
          deleteRapprochementBancaire={this.props.deleteRapprochementBancaire}
          uploadRapprochement={this.props.uploadRapprochement}
          rapprochementsBancaires={
            rapprochementsBancaires &&
            rapprochementsBancaires.sort(
              (a, b) =>
                dayjs(b.rapprochement_bancaire_date_releve, "YYYY-MM-DD").unix() -
                dayjs(a.rapprochement_bancaire_date_releve, "YYYY-MM-DD").unix()
            )
          }
        />
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
    updateParametre: (jsonData, id) => dispatch(updateParametre(jsonData, id)),
    updateLigneEcriture: (jsonData, id) => dispatch(updateLigneEcriture(jsonData, id)),
    saveRapprochementBancaire: (jsonData) => dispatch(saveRapprochementBancaire(jsonData)),
    loadRapprochementsBancaires: (societe_id) => dispatch(loadRapprochementsBancaires(societe_id)),
    deleteRapprochementBancaire: (id) => dispatch(deleteRapprochementBancaire(id)),
    uploadRapprochement: (jsonData, id) => dispatch(uploadRapprochement(jsonData, id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    parametre: state.parametre.data,
    societes: state.societes.data,
    comptes: state.comptes.data,
    ecritures: state.ecritures.data,
    rapprochementsBancaires: state.rapprochementsBancaires.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RapprochementBancaire);
